import {
  ComponentRef,
  Directive,
  ElementRef,
  HostBinding,
  Input,
  OnInit,
  TemplateRef,
} from "@angular/core";
import { PopoverComponent } from "../components/popover/popover.component";
import { connectedPoitions, ToolTipPos } from "@iris/popover";
import {
  positionData,
  defaultPositioning,
} from "@iris/popover/data/popover.data";
import {
  Overlay,
  OverlayConfig,
  OverlayPositionBuilder,
  OverlayRef,
} from "@angular/cdk/overlay";
import { ComponentPortal } from "@angular/cdk/portal";
import { Subject } from "rxjs-compat";
import { takeUntil } from "rxjs/operators";

@Directive({
  selector: "[cpPopover]",
})
export class PopoverDirective implements OnInit {
  private overlayRef: OverlayRef;
  private unsubscribe$: Subject<any> = new Subject<any>();
  private tooltiptimer: any = null;
  tooltipRef: ComponentRef<PopoverComponent> = null;
  /**
   * The string content to be displayed in the popover.
   *
   * If the content is falsy, the popover won't open.
   */
  @Input("cpPopover") content: string | TemplateRef<any> = null;

  /**  The title of the popover. */

  @Input("popoverTitle") title = "";

  /**  Delay before opening the tooltip */

  @Input("delay") delay: number = 0;

  /**
   * The preferred placement of the popover.
   *
   * Possible values are `"top-start"`, `"top-center"`, `"top-end"`, `"bottom-start"`, `"bottom-center"`,
   * `"bottom-end"`, `"left-start"`, `"left-center"`, `"left-end"`, `"right-start"`, `"right-center"`,
   * `"right-end"`
   *
   * Accepts a single string with any one of the above values.
   * Default value "top-start"
   */
  @Input() position: ToolTipPos = defaultPositioning;

  /**
   * Specifies events that should trigger the popover.
   *
   * Its value could be either "hover" or "click"
   *
   */
  @Input("popoverTrigger") popTrigger: "hover" | "click" = "hover";

  /**
   * Specifies whether popover should be closed on its own or it should be closed manually.
   *
   * Its default value is true.
   */
  @Input() popoverAutoclose: boolean = true;

  @Input() activeClass: string = "";
  private mouseEnterListener: () => void;
  private mouseLeaveListener: () => void;
  private clickListener: () => void;

  @HostBinding("class")
  get elementClass(): string {
    if (this.tooltipRef) {
      return this.activeClass;
    }
    return "";
  }

  get htmlEl(): HTMLElement {
    return this.elementRef.nativeElement;
  }

  constructor(
    private overlayPositionBuilder: OverlayPositionBuilder,
    private elementRef: ElementRef,
    private overlay: Overlay
  ) {}

  get getPositionData(): connectedPoitions {
    const defaultPositionObj = positionData[defaultPositioning],
      inputPositionObj = positionData[this.position],
      positionObj = inputPositionObj || defaultPositionObj;
    return positionObj;
  }

  ngOnInit() {
    this.toolTipEventListners();
  }

  private toolTipEventListners(): void {
    switch (this.popTrigger) {
      case "hover":
        this.mouseEnterListener = this.onMouseInteract.bind(this);
        this.mouseLeaveListener = this.onMouseLeave.bind(this);
        this.htmlEl.addEventListener("mouseenter", this.mouseEnterListener);
        this.htmlEl.addEventListener("mouseleave", this.mouseLeaveListener);
        break;
      case "click":
        this.clickListener = this.onMouseClick.bind(this);
        this.htmlEl.addEventListener("click", this.clickListener);
        break;
    }
  }

  onMouseLeave(): void {
    if (this.popoverAutoclose) this.removeToolTip();
    if (this.tooltiptimer) this.removeTimer();
  }

  removeTimer(): void {
    clearTimeout(this.tooltiptimer);
    this.tooltiptimer = null;
  }

  onMouseClick() {
    if (this.tooltipRef) {
      this.removeToolTip();
      return;
    }

    this.onMouseInteract();
  }

  onMouseInteract() {
    if (!this.content) return;
    if (this.delay) {
      this.tooltiptimer = setTimeout(this.timedToolTip.bind(this), this.delay);
      return;
    }

    this.showTooltip();
  }

  private timedToolTip(): void {
    if (!this.tooltiptimer) return;
    this.showTooltip();
  }

  showTooltip() {
    // Create tooltip portal
    if (this.tooltipRef) return;
    this.createToolTip();
    this.attachToolTipComponent();
    if (this.popoverAutoclose) this.onClickOutside();
    this.onDetachment();
  }

  onDetachment() {
    this.overlayRef
      .detachments()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => {
        this.removeToolTip();
      });
  }

  private createToolTip(): void {
    // Default position is top-center.
    const toolTipPosition: connectedPoitions = this.getPositionData;
    const positionStrategy = this.overlayPositionBuilder
      // Create position attached to the elementRef
      .flexibleConnectedTo(this.elementRef)
      // Describe how to connect overlay to the elementRef
      // Means, attach overlay's center bottom point to the
      // top center point of the elementRef.
      .withPositions([...toolTipPosition]);
    const overlayConfig = new OverlayConfig({
      //Making the tooltip to reposition on scroll
      scrollStrategy: this.overlay.scrollStrategies.close(),
      // Position the tooltip wrt the element
      positionStrategy,
    });

    this.overlayRef = this.overlay.create(overlayConfig);
  }

  private attachToolTipComponent(): void {
    const tooltipPortal = new ComponentPortal(PopoverComponent);

    // Attach tooltip portal to overlay
    this.tooltipRef = this.overlayRef.attach(tooltipPortal);
    const tooltipInstance = this.tooltipRef?.instance;
    // Pass content to tooltip component instance
    tooltipInstance.content = this.content;
    tooltipInstance.position = this.position;
    tooltipInstance.title = this.title;
    tooltipInstance.showCloseButton = !this.popoverAutoclose;

    this.tooltipRef.instance
      .onToolTipClose()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => {
        this.removeToolTip();
      });
  }

  private onClickOutside(): void {
    this.overlayRef
      ?.outsidePointerEvents()
      ?.pipe(takeUntil(this.unsubscribe$))
      ?.subscribe((event) => {
        if (this.elementRef.nativeElement.contains(event.target)) return;

        this.removeToolTip();
      });
  }

  removeToolTip() {
    if (!this.overlayRef) return;
    this.tooltipRef = null;
    this.overlayRef.detach();
  }

  ngOnDestroy() {
    this.destroyListeners();
    this.destroyUserListeners();
    this.removeToolTip();
  }

  destroyListeners() {
    this.unsubscribe$.next();
    this.unsubscribe$.unsubscribe();
  }

  destroyUserListeners() {
    this.mouseEnterListener &&
      this.htmlEl.removeEventListener("mouseenter", this.mouseEnterListener);
    this.mouseLeaveListener &&
      this.htmlEl.removeEventListener("mouseleave", this.mouseLeaveListener);
    this.clickListener &&
      this.htmlEl.removeEventListener("click", this.clickListener);
  }
}
