export const management = ["Super Administrator", "System Administrator"];

export const medAdmin = ["Medical Administrator"];

export const billingAdmin = [
  "Super Billing Administrator",
  "Billing Administrator",
];

export const patientAccess = [
  "Dietitian",
  "Critical Care Registered Nurse",
  "Nurse",
  "Critical Care Associate",
  "Pharmacologist",
  "Physician",
  "Specialist",
  "Registrar",
  "Lab Technician",
  "Documentation Specialist",
  "Physical Therapist",
  "Occupational Therapist",
  "Respiratory Therapist",
  "Data Entry Associate",
  "Physician Associate",
];

export const patientListAccess = [...patientAccess, "Operations Manager"];

export const tv = ["Tv", "Tv-Covid", "TV-view (Unit)"];

export const auditor = ["Auditor"];

export const rAlert = ["R-Alert"];

export const userRoles = [
  ...management,
  ...medAdmin,
  ...billingAdmin,
  ...patientListAccess,
  ...tv,
  ...auditor,
  ...rAlert,
];
