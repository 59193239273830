import { SEVERITY_TYPES } from "@shared-modules/features/shift-assign-dashboard/model/index";

// export interface StackedBarInterface {
//   type: SEVERITY_TYPES;
//   count: number;
//   scssClass?: string;
// }

export const STACKED_CLASS_SEVERITY_MAP = {
  [SEVERITY_TYPES.Fair]: "stack-bar--fair",
  [SEVERITY_TYPES.ComfortCare]: "stack-bar--comfort-care",
  [SEVERITY_TYPES.Watcher]: "stack-bar--watcher",
  [SEVERITY_TYPES.Unstable]: "stack-bar--unstable",
  [SEVERITY_TYPES.NA]: "stack-bar--na",
};
