import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Subject, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class UserFormService {
  public env = environment;
  public httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
    }),
  };

  constructor(private http: HttpClient) {}

  public transformUserForm(userForm: any): any {
    const { countryCode, mobileNumber: phone } = userForm.phone;
    return {
      ...userForm,
      phone,
      countryCode,
    };
  }
  private user = new Subject();
  deleteImage(id, field) {
    return this.http
      .patch<any>(
        `${this.env.apiUrl}management/user/delete_sign_stamp/${id}`,
        { field: field },
        {
          observe: "response",
          ...this.httpOptions,
        }
      )
      .pipe(catchError(this.errorHandler));
  }

  downloadImage(key) {
    return this.http
      .post(this.env.apiUrl + "file/getFile", { key: key }, this.httpOptions)
      .pipe(catchError(this.errorHandler));
  }

  errorHandler(error: HttpErrorResponse) {
    return throwError(error || "Server error");
  }

  //for passing response to user component
  setUserResult(result) {
    this.user.next(result);
  }
  getUser = this.user.asObservable();
}
