import { of } from "rxjs";
import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, map, switchMap, take, tap } from "rxjs/operators";

import { OrderPaneActions } from "../actions/order";
import { displayErrorMessage } from "../actions/root.actions";
import { OrderableService } from "../../services/orderable.service";
import {
  OrderableApiActions,
  OrderableRequestPageActions,
} from "../actions/orderable";
import { AlertService } from "@iris/alert/services/alert.service";

@Injectable()
export class OrderableRequestEffects {
  constructor(
    private orderableService: OrderableService,
    private alertService: AlertService,
    private actions: Actions
  ) {}

  getOrderableRequests$ = createEffect(() =>
    this.actions.pipe(
      ofType(OrderableRequestPageActions.getOrderableRequests),
      switchMap(() =>
        this.orderableService.getOrderableRequest().pipe(
          take(1),
          map((data) =>
            OrderableApiActions.getOrderableRequestSuccess({ data: data.data })
          ),
          catchError((error) => {
            let errorMessage = "Server Error";

            if (error && error.error && error.error.message) {
              errorMessage = error.error.message;
            }

            return of(
              OrderableApiActions.getOrderableFailure({ error: errorMessage })
            );
          })
        )
      )
    )
  );

  getOrderableRequestFailure$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(OrderableApiActions.getOrderableRequestFailure),
        tap(({ error }) => {
          this.alertService.showNotification({
            type: "Error",
            message: error,
          });
        })
      ),
    { dispatch: false }
  );

  submitOrderableRequest$ = createEffect(() =>
    this.actions.pipe(
      ofType(OrderPaneActions.submitOrderableRequest),
      switchMap(({ request }) =>
        this.orderableService.submitOrderableRequest(request).pipe(
          take(1),
          map((_) => OrderableApiActions.submitOrderableRequestSuccess()),
          catchError((error) => {
            let errorMessage = "Server Error";
            if (error && error.error && error.error.message) {
              errorMessage = error.error.message;
            }

            return of(
              OrderableApiActions.submitOrderableRequestFailure({
                error: errorMessage,
              })
            );
          })
        )
      )
    )
  );

  submitOrderableRequestSuccess$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(OrderableApiActions.submitOrderableRequestSuccess),
        tap(() => {
          this.alertService.showNotification({
            type: "Success",
            message: "Successfully Made",
          });
        })
      ),
    { dispatch: false }
  );

  submitOrderableRequestFailure$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(OrderableApiActions.submitOrderableRequestFailure),
        tap(({ error }) => {
          this.alertService.showNotification({
            type: "Error",
            message: error,
          });
        })
      ),
    { dispatch: false }
  );

  clickAcceptOrderableRequest$ = createEffect(() =>
    this.actions.pipe(
      ofType(OrderableRequestPageActions.clickAcceptOrderableRequest),
      switchMap(({ id, data, listType }) =>
        this.orderableService.acceptOrderableRequest(id, data).pipe(
          take(1),
          map((_) =>
            OrderableApiActions.acceptOrderableRequestSuccess({ id, listType })
          ),
          catchError((error) => {
            let errorMessage = "Server Error";
            if (error && error.error && error.error.message) {
              errorMessage = error.error.message;
            }

            return of(displayErrorMessage({ message: errorMessage }));
          })
        )
      )
    )
  );

  clickRejectOrderableRequest$ = createEffect(() =>
    this.actions.pipe(
      ofType(OrderableRequestPageActions.clickRejectOrderableRequest),
      switchMap(({ id }) =>
        this.orderableService.rejectOrderableRequest(id).pipe(
          take(1),
          map((_) => OrderableApiActions.rejectOrderableRequestSuccess({ id })),
          catchError((error) => {
            let errorMessage = "Server Error";
            if (error && error.error && error.error.message) {
              errorMessage = error.error.message;
            }

            return of(displayErrorMessage({ message: errorMessage }));
          })
        )
      )
    )
  );
}
