import { Action, createReducer, on } from "@ngrx/store";

import * as rootActions from "../../actions/root.actions";
import * as authActions from "../../actions/auth.actions";
import * as userActions from "../../actions/user.actions";
import * as fromUserPreference from "./user-preference.reducers";
import { setRoundarPreference } from "../../actions/user.actions";

export interface currUser {
  name?: string | null;
  role?: string | null;
  title?: string | null;
  email?: string | null;
  hospitals?: object[] | null;
  isCommandCenterUser?: boolean | null;
  allowedCommandCenters?: object[] | null;
  allowedRoles?: [string] | null;
  currentUserState: boolean;
  registration_id: string;
  signature: any;
  stamp: any;
  units?: any;
  onCallStatus?: {
    onCall?: boolean;
    lastTimeOnCall?: Date;
  };
  isFamilyUser?: boolean;
}

export interface State {
  currentUserState: boolean;
  currentUser: currUser;
  preference: fromUserPreference.State;
}

const initialState: State = {
  currentUserState: false,
  currentUser: {
    name: null,
    role: null,
    title: null,
    email: null,
    hospitals: null,
    isCommandCenterUser: null,
    allowedCommandCenters: null,
    currentUserState: false,
    signature: null,
    stamp: null,
    allowedRoles: null,
    registration_id: null,
    units: null,
    onCallStatus: {
      onCall: null,
    },
  },
  preference: fromUserPreference.initialState,
};

export const userReducer = createReducer(
  initialState,
  on(
    userActions.setFamilyUser,
    userActions.setUser,
    authActions.loginSuccess,
    (state, { user }) => {
      const currentUser = { ...user, currentUserState: true };

      // changes by sidharth
      delete currentUser.password;
      delete currentUser.wiki;

      return {
        ...state,
        currentUserState: true,
        currentUser,
      };
    }
  ),
  on(rootActions.logout, (state) => initialState),
  on(setRoundarPreference, (state, { data }) => ({
    ...state,
    preference: { roundar: { on: data } },
  })),
  on(rootActions.patientChartReset, (state) => ({
    ...state,
    preference: { roundar: { on: false } },
  })),
  on(userActions.updateUserStore, (state, { user }) => ({
    ...state,
    currentUser: { ...user },
  }))
);

/** Helper Functions */
function isClient(hospitals) {
  if (!hospitals.length) {
    return false;
  }

  for (const hospital of hospitals) {
    if (hospital.name === "Cloudphysician") {
      return false;
    }
  }

  return true;
}
