import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
  inject,
} from "@angular/core";
import { PatientService } from "src/app/services/patient.service";
import { ActivatedRoute } from "@angular/router";
import {
  getNetReport,
  calcTotalProperties,
} from "src/app/patient/intake-output/io-view/get-net-report";
import { SummaryService } from "src/app/services/summary.service";
import * as testData from "../data/test.data";
import { select, Store } from "@ngrx/store";
import * as fromPatientHeaderReducers from "src/app/store/reducers/patient-chart/patient-header/index";
import * as fromVitalReducers from "src/app/vitals/store/reducers";
import * as fromOrderReducers from "src/app/store/reducers/order/index";
import * as fromIoReducers from "src/app/store/reducers/patient-chart/io/index";
import { getFinalNoteState } from "src/app/note-view-module/store/reducers";
import * as fromDocsReducers from "src/app/labs-scans-module/store/index";
import { log } from "util";
import { FileService } from "../../services/file.service";
import { dataURLtoFile } from "src/app/support-functions/base64ToFile";
import {
  AvatarParams,
  AVATAR_SIZE,
} from "@iris/avatar/components/avatar/avatar.component";
import { MatSelect } from "@angular/material/select";
import { AlertService } from "@iris/alert/services/alert.service";
import { IcuDaysPipe } from "@shared-modules/pipes/icu-days-pipe/icu-days.pipe";
import { DatePipe } from "@angular/common";
import { socket } from "../../config/socket";
import { FormValidators } from "src/app/labs-scans-module/labs-scans/labs-scans-input/forms.validators";
import { jsPDFDocument } from "jspdf-autotable";
import { Observable, Subject } from "rxjs";
import { UtilService } from "src/app/services/util.service";
import { displayAge } from "src/app/support-functions/calculateAge";
import { header } from "@notes/utils/permission.data";
import { UserRolesMap } from "src/app/shared/accessControl/roleInterface";
import { bufferCount, filter, take, takeLast, takeUntil } from "rxjs/operators";
import { TimezonePipe } from "@shared-modules/pipes/timezone-pipe/timezone.pipe";
import { TimeZoneDetails } from "src/app/models/hospital";
import moment from "moment-timezone";
import { TimezoneService } from "src/app/services/timezone.service";
import { ProfilePicService } from "src/app/admit-form/services/profile-pic.service";
import { robo_medium } from "src/assets/fonts/Roboto-Medium-normal.js";
import { robo_mediumItalic } from "src/assets/fonts/Roboto-MediumItalic-italic.js";
import { robo_light } from "src/assets/fonts/Roboto-Light-normal.js";
import { robo_bold } from "src/assets/fonts/Roboto-Bold-normal.js";
import { UserFormService } from "src/app/management/services/user-form.service";

declare const require: any;
const jsPDF = require("jspdf");
require("jspdf-autotable");

// get labs
const LABATTRIBUTES = [
  "Renal function 2000 panel",
  "CBC W Differential panel, method unspecified (Bld)",
  "Gas panel (BldA)",
  "Electrolytes panel (Bld)",
];

@Component({
  selector: "app-summary",
  templateUrl: "./summary.component.html",
  styleUrls: ["./summary.component.scss"],
})
export class SummaryComponent
  extends UserRolesMap
  implements OnInit, OnDestroy
{
  @ViewChild("currSummSelect") currSummSelect: MatSelect;
  @ViewChild("summaryWrapper") summaryWrapper: ElementRef;
  private unsubscribe$ = new Subject<any>();
  /** Patient Variables  */
  public CPMRN;
  public encounters;
  public patInfo = {} as any;
  public patAge;
  public extendedVitalsData = [];
  public vitalsData = [];
  public linesConnected = [];
  public patNotes = {};
  public labs_scans = { RFT: {}, CBC: {}, ABG: {}, Electrolytes: {} };
  public activeMeds = [];
  public netIntake = 0;
  public netOutput = 0;
  public netIo = 0;
  public oldSummary = [];
  public pastSummary = "";
  public docProps = {};
  public ioDateToShow = {};
  public isPatientDischarged = false;
  imgData = null;
  stampImg = null;
  signatureImg = null;
  public header = header;

  /** View / UI Variables  */
  public showpdf: Boolean = false;
  public successMessage: string = "Data Updated";
  public currDate = new Date();
  public disableBtn = null;
  public restError = false;
  public printValue: number = 0;
  public patientAvatar: AvatarParams[];
  selectedCurrSummary: string = "0";
  public summaryType: any = {
    noteWidth: "half",
    docNote: {
      display: true,
    },
    nurseNote: {
      display: false,
    },
    vitals: {
      display: true,
    },
    vents: {
      display: false,
    },
    labs: {
      display: true,
    },
    io: {
      display: true,
    },
    lines: {
      display: true,
    },
    meds: {
      display: true,
    },
    extraNote: {
      display: true,
    },
    docInfo: {
      display: true,
    },
  };

  /** Extra Variables  */
  public currUser;

  /** HTML table Variables  */
  public ventValues: any = [
    { displayName: "Therapy Device", name: "daysTherapyDevice" },
    { displayName: "Mode", name: "daysVentMode" },
    { displayName: "Breathe sequence", name: "daysVentBreathSequence" },
    { displayName: "Vt", name: "daysVentVt" },
    { displayName: "PEEP/EPAP", name: "daysVentPEEP" },
    { displayName: "FiO2", name: "daysFiO2" },
    { displayName: "Pinsp/IPAP", name: "daysVentPinsp" },
    { displayName: "PS", name: "daysVentPsupport" },
    { displayName: "MV", name: "daysVentMV" },
    { displayName: "Airway", name: "daysVentAirway" },
  ];
  public vitalValues: any = [
    { displayName: "Temp", name: "daysTemperature" },
    { displayName: "HR", name: "daysHR" },
    { displayName: "BP", name: "daysBP" },
    { displayName: "RR", name: "daysRR" },
    { displayName: "SpO2(FiO2)", name: "daysSpO2" },
    { displayName: "GCS", name: "daysGCS" },
  ];

  /** Observables  */
  public patient$ = this.store.pipe(
    select(fromPatientHeaderReducers.getPatHeaderData),
    takeUntil(this.unsubscribe$)
  );
  public patTimeZoneDetail$ = this.store.pipe(
    select(fromPatientHeaderReducers.getPatientHospitalTimeZone),
    takeUntil(this.unsubscribe$)
  );
  public vitals$ = this.store.pipe(
    select(fromVitalReducers.getVitaldays),
    takeUntil(this.unsubscribe$)
  );
  public orders$ = this.store.pipe(
    select(fromOrderReducers.getOrders),
    takeUntil(this.unsubscribe$)
  );
  public io$ = this.store.pipe(
    select(fromIoReducers.getIoData),
    takeUntil(this.unsubscribe$)
  );
  // public notes$ = this.store.pipe(select(fromNotesReducers.getNotesData));
  public notes$ = this.store.pipe(
    select(getFinalNoteState),
    takeUntil(this.unsubscribe$)
  );
  public labs$ = this.store.pipe(
    select(fromDocsReducers.getAllLabDocument),
    takeUntil(this.unsubscribe$)
  );
  public getICUDischargeDate$ = this.store.pipe(
    select(fromPatientHeaderReducers.getICUDischargeDate),
    takeUntil(this.unsubscribe$)
  );

  /* Name of the uploaded summary */
  public fileNameMap = new Map([
    [0, "Progress note/Admission note with meds.pdf"],
    [1, "Progress note/Admission note without meds.pdf"],
    [2, "Active Medications.pdf"],
    [3, "Shift summary with meds.pdf"],
    [4, "Shift summary without meds.pdf"],
  ]);

  private _tz = inject(TimezoneService);
  public timeZoneDetail: TimeZoneDetails;
  profilePic: string = "";
  public hospitalList = [
    "MS Ramaiah Memorial Hospital",
    "SHRI Guntur",
    "Avenue Hospital Parklands",
  ];
  constructor(
    public _patientService: PatientService,
    private route: ActivatedRoute,
    private _summaryService: SummaryService,
    public store: Store<{}>,
    private _fileService: FileService,
    private icuDaysPipe: IcuDaysPipe,
    private datePipe: DatePipe,
    private _timezonePipe: TimezonePipe,
    private alertService: AlertService,
    private utils: UtilService,
    private _service: ProfilePicService,
    private _userFormService: UserFormService
  ) {
    super();
  }

  ngOnInit() {
    this.CPMRN = this.route.parent.snapshot.paramMap.get("patientCPMRN");
    this.encounters =
      this.route.parent.snapshot.paramMap.get("patientEncounters");

    // get summary
    this.getSummary(false);

    // this.getPrevSummary();

    // check the user
    this.currUser = JSON.parse(localStorage.getItem("currentUser"));

    // get current summary pdf from socket
    const patientId = this.CPMRN + this.encounters;
    socket?.on(patientId, (data) => {
      this.oldSummary = data.summary;
    });

    this.patTimeZoneDetail$.subscribe((data) => {
      this.timeZoneDetail = data;
    });
  }

  ngAfterViewInit() {
    this.currSummSelect.defaultTabIndex = 0;
  }

  getSummary(prm) {
    // patient basic info
    this.patient$.subscribe((res) => {
      // clone it, to avoid modifying the store indirectly
      let data = JSON.parse(JSON.stringify(res));
      if (data?.profilePicture && !this.profilePic) {
        this._service.getImage(data.CPMRN, data.encounters).subscribe((res) => {
          this.profilePic = res.data.signedUrl;
          this.patientAvatar = [
            {
              name: data.name,
              size: AVATAR_SIZE.L,
              imageUrl: this.profilePic,
            },
          ];
        });
      } else {
        this.patientAvatar = [
          {
            name: data.name,
            size: AVATAR_SIZE.L,
          },
        ];
      }
      if (data && data.CPMRN) {
        if (!data.allergies) {
        } else if (typeof data.allergies == "object") {
          // @ts-ignore
          data.allergies = data.allergies.join(", ");
        } else if (typeof data.allergies == "string") {
          // data.allergies = "abc,def";
          // @ts-ignore
          data.allergies = data.allergies.replace(/,/gi, ", ");
        }
        //arrange chief complains regarding the type of data (array or string)
        if (!data.diagnoses) {
        } else if (typeof data.diagnoses == "object") {
          // @ts-ignore
          data.diagnoses = data.diagnoses.join(", ");
        } else if (typeof data.diagnoses == "string") {
          // data.diagnoses = "abc,def";
          // @ts-ignore
          data.diagnoses = data.diagnoses.replace(/,/gi, ", ");
        }
        this.patInfo = data;

        this.isPatientDischarged = !data.isCurrentlyAdmitted;

        // set image
        this.utils.getDataUri(this.patInfo["hospitalLogo"], (info) => {
          this.imgData = info;
        });

        // set no of days
        let noOfDays = Math.ceil(
          (new Date().getTime() -
            new Date(this.patInfo["ICUAdmitDate"]).getTime()) /
            8.64e7
        );
        this.patInfo["noOfDays"] = noOfDays;

        // get old summary
        if (data.summary) {
          this.oldSummary = data.summary;
        }
      }
    });

    // get vitals
    this.vitals$.subscribe((data) => {
      if (data && data.length) {
        this.vitalsData = data.slice(0, 12);
      }
    });

    // get orders
    this.orders$.subscribe((data) => {
      // get active orders
      this.activeMeds = data["active"]["medications"];

      this.activeMeds.forEach((med) => {
        let startDate = this._tz
          .transformIntoTimezoneObj(med.startTime)
          .valueOf();
        let totalDays =
          (this._tz.getCurrentTimeObj().valueOf() - startDate) / 8.64e7;

        med.dayNum = totalDays.toFixed(2);
      });

      // calculate procedures
      this.linesConnected = data["active"]["procedures"];
      this.linesConnected.forEach((element) => {
        let tempDays = Math.ceil(
          (this._tz.getCurrentTimeObj().valueOf() -
            this._tz.transformIntoTimezoneObj(element.startTime).valueOf()) *
            1.15741e-8
        );

        element.numDays = tempDays;
      });
    });

    // get io
    this.io$.subscribe((data) => {
      if (data && data["ioObj"]) {
        let patientIo: any = [...data["ioObj"]];
        let tempBolusProps = data["intakeProps"]["bolusProps"];
        let tempInfusionProps = data["intakeProps"]["infusionProps"];
        let ioProps = data["outputProps"];

        let getIoData = false;
        if (patientIo.length) {
          if (
            this._tz.getCurrentTimeObj().valueOf() -
              this._tz
                .transformIntoTimezoneObj(patientIo[0]["dayDate"])
                .valueOf() <=
            8.64e7
          ) {
            getIoData = true;
          }
        }

        if (getIoData) {
          let tempDay1 = calcTotalProperties(patientIo[0], false, {
            bolusProps: tempBolusProps,
            infusionProps: tempInfusionProps,
            ioProps: ioProps,
          });
          patientIo[0] = tempDay1.ioDay;
          if (patientIo[1]) {
            let tempDay2 = calcTotalProperties(patientIo[1], false, {
              bolusProps: tempBolusProps,
              infusionProps: tempInfusionProps,
              ioProps: ioProps,
            });
            patientIo[1] = tempDay2.ioDay;
          }

          let nowDate = this._tz.getCurrentTimeObj();
          let strDay = nowDate.hours(nowDate.hours() - 24);
          let strDateObj = this._tz.transformIntoTimezoneObj(strDay);

          let endDateObj = this._tz.getCurrentTimeObj();

          let netReportObj = getNetReport.call(
            this._tz,
            patientIo,
            strDateObj,
            endDateObj
          );

          this.netIntake = netReportObj.inReport;
          this.netOutput = netReportObj.outReport;
          this.netIo = netReportObj.netReport;
        } else {
          this.netIntake = 0;
          this.netOutput = 0;
          this.netIo = 0;
        }
      }
    });

    this.notes$
      .pipe(
        filter((data) => data["finalNotes"].length > 0),
        take(1),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((data) => {
        if (data && data["finalNotes"] && data["finalNotes"].length) {
          this.processNotes(data);
        }
      });

    // get notes
    this.notes$.pipe(takeUntil(this.unsubscribe$)).subscribe((data) => {
      if (data && data["finalNotes"] && data["finalNotes"].length) {
        let tempNote = {};
        let nowDate = this._tz.getCurrentTimeObj();
        data["finalNotes"].forEach((note: any) => {
          let noteDate = this._tz.transformIntoTimezoneObj(
            note["createdTimestamp"]
          );

          /** Get note if its within 24hrs */
          if (nowDate.valueOf() - noteDate.valueOf() > 8.64e7) {
            return false;
          }

          let noteType = note["content"]["noteType"];
          let pendOrSigned = note["content"]["pendOrSigned"];
          let authorIsClient = note["content"]["authorIsClient"];
          let pendingAttestation =
            note.content?.cosign?.status === "pending" ||
            note.content?.cosign?.status === "rejected"; // pending & rejected

          /** Get latest Admission / Progress note */
          if (
            (!pendingAttestation &&
              noteType == "Discharge" &&
              pendOrSigned == "signed" &&
              !authorIsClient) ||
            (!pendingAttestation &&
              noteType == "Progress" &&
              pendOrSigned == "signed" &&
              !authorIsClient) ||
            (!pendingAttestation &&
              noteType == "Admission" &&
              pendOrSigned == "signed" &&
              !authorIsClient) ||
            (this.hospitalList.some(
              (element) => element === this.patInfo.hospitalInfo.name
            ) &&
              !pendingAttestation &&
              pendOrSigned == "signed" &&
              (noteType == "Discharge" ||
                noteType == "Progress" ||
                noteType == "Admission"))
          ) {
            tempNote["mainNote"] = note["content"];
          }

          /** Get latest diet note */
          if (
            noteType == "Nutrition" &&
            pendOrSigned == "signed" &&
            !authorIsClient
          ) {
            tempNote["nutritionNote"] = note["content"];
          }

          /** Get latest pharma note */
          if (
            noteType == "Pharmacology" &&
            pendOrSigned == "signed" &&
            !authorIsClient
          ) {
            tempNote["pharmNote"] = note["content"];
          }

          /** Get latest nursing note */
          if (
            noteType == "Nursing" &&
            pendOrSigned == "signed" &&
            !authorIsClient
          ) {
            tempNote["nurseNote"] = note["content"];
          }
        });
        this.patNotes = tempNote;
      }
    });

    this.labs$.subscribe((data) => {
      if (data && data.length) {
        let nowDate = new Date();
        nowDate.setTime(new Date().getTime() - 24 * 60 * 60 * 1000 * 2);
        data.forEach((labs) => {
          let docTime = new Date(labs["reportedAt"]);
          if (
            LABATTRIBUTES.includes(labs.name) &&
            docTime.getTime() > nowDate.getTime()
          ) {
            switch (labs.alias[0]) {
              case "RFT":
                return Object.keys(this.labs_scans.RFT).length === 0
                  ? (this.labs_scans.RFT = labs)
                  : this.labs_scans.RFT;
              case "CBC":
                return Object.keys(this.labs_scans.CBC).length === 0
                  ? (this.labs_scans.CBC = labs)
                  : this.labs_scans.CBC;
              case "ABG":
                return Object.keys(this.labs_scans.ABG).length === 0
                  ? (this.labs_scans.ABG = labs)
                  : this.labs_scans.ABG;
              case "Serum lytes":
                return Object.keys(this.labs_scans.Electrolytes).length === 0
                  ? (this.labs_scans.Electrolytes = labs)
                  : this.labs_scans.Electrolytes;
              default:
                return this.labs_scans;
            }
          }
        });
      }
    });
  }

  getPrevSummary() {
    let obj = {
      cpmrn: this.CPMRN,
      encounters: this.encounters,
    };

    this._patientService.getPatientSummary(obj).subscribe((data) => {
      this.oldSummary = data["summary"];
    });
  }

  printJsPdf() {
    this.disableBtn = "print";
    let dietPharmaPage = false;
    // let nurseTableYPosition = 0;

    if (
      this.patNotes &&
      (this.patNotes["pharmNote"] || this.patNotes["nutritionNote"])
    ) {
      dietPharmaPage = true;
    }
    var doc = new jsPDF();
    doc.addFileToVFS("Roboto-MediumItalic.ttf", robo_mediumItalic);
    doc.addFont("Roboto-MediumItalic.ttf", "Roboto-MediumItalic", "normal");
    doc.addFileToVFS("Roboto-Medium-normal.ttf", robo_medium);
    doc.addFont("Roboto-Medium-normal.ttf", "Roboto-Medium", "normal");
    doc.addFileToVFS("Roboto-Light-normal.ttf", robo_light);
    doc.addFont("Roboto-Light-normal.ttf", "Roboto-Light", "normal");
    doc.addFileToVFS("Roboto-Bold-normal.ttf", robo_bold);
    doc.addFont("Roboto-Bold-normal.ttf", "Roboto-Bold", "normal");

    // set properties of the pdf
    let tempMonth = new Date().getMonth() + 1;
    doc.setProperties({
      title:
        this._patientService.displayName(this.patInfo, false) +
        " " +
        this.patInfo["MRN"] +
        " " +
        new Date().getDate() +
        "-" +
        tempMonth +
        "-" +
        new Date().getFullYear(),
      author: "Cloudphysician",
      creator: "Cloudphysician",
    });

    doc = this.setPdfHeader(doc);

    let topY = doc.lastAutoTable.finalY + 8;

    doc = this.setPatientInfo(doc, topY);
    topY = doc.lastAutoTable.finalY + 6;

    // setup the pdf based on value

    switch (this.printValue) {
      case 0:
      case 1:
        if (this.patNotes && this.patNotes["mainNote"]) {
          let noteDate = this.setDateTime(this.patNotes["mainNote"].timestamp);
          noteDate += this.timeZoneDetail
            ? ` ( ${this.timeZoneDetail?.abbreviation} )`
            : "";
          const noteObj = this.patNotes["mainNote"];
          // DOCTOR / NURSE TABLE

          // this.setRegistrationInfo(doc, topY, noteObj);

          const noteType =
            noteObj.noteType == "Discharge"
              ? "Discharge Note"
              : noteObj.noteType == "Progress"
              ? "Progress Note"
              : "Admission note";

          topY = this.setNotesTable(doc, noteType, noteDate, topY, noteObj);
        } else {
        }

        // ================== LEFT TABLES
        // if Position > 200, add page and reset the position

        // LAB TABLE
        if (topY + 55 > 200) {
          topY = 60;
          doc.addPage();
        }

        // Header
        this.setAutoTableHeader(doc, topY, "Labs and scan");
        topY = doc.lastAutoTable.finalY + 3;

        // RFT Table
        this.setAutoTableForLabsAndVitals(
          doc,
          topY,
          "#labTableRFT",
          {
            right: 158,
            widthControl: 22,
            report: "labs",
          },
          this.callbackToSetNumberOfColumns
        );

        // CBC Table
        this.setAutoTableForLabsAndVitals(
          doc,
          topY,
          "#labTableCBC",
          {
            left: 55,
            right: 110,
            widthControl: 25,
            report: "labs",
          },
          this.callbackToSetNumberOfColumns
        );

        // CBC table has more columns than any other table in labs/scans.
        let labEnd = doc.lastAutoTable.finalY + 7;

        // ABG Table
        this.setAutoTableForLabsAndVitals(
          doc,
          topY,
          "#labTableABG",
          {
            left: 103,
            right: 65,
            widthControl: 25,
            report: "labs",
          },
          this.callbackToSetNumberOfColumns
        );

        // Electrolytes Table
        this.setAutoTableForLabsAndVitals(
          doc,
          topY,
          "#labTableElect",
          {
            left: 148,
            widthControl: 25,
            report: "labs",
          },
          this.callbackToSetNumberOfColumns
        );

        // MEDICAL ORDER TABLE
        if (this.printValue == 0) {
          this.setAutoTableHeader(doc, labEnd, "Medications");
          topY = doc.lastAutoTable.finalY + 4;

          doc.autoTable({
            tableLineWidth: 0.1,
            theme: "plain",

            willDrawCell: (data) => {
              if (data.section === "body") {
                // Set the border color for the bottom border
                doc.setDrawColor("#CDD7EB");
                doc.setFontSize(10);
                doc.setFont("Roboto-Light");
                doc.setFontType("normal");

                if (
                  data.cell.raw.parentNode.classList.contains("tr__primary")
                ) {
                  doc.setLineWidth(0.1);

                  doc.line(
                    data.cell.x,
                    data.cell.y,
                    data.cell.x + data.cell.width,
                    data.cell.y
                  );
                }
              }
            },

            startY: topY,
            headStyles: { fillColor: "#EEEFF4", textColor: [0, 0, 0] },

            html: "#medicTables",
            styles: { overflow: "linebreak", fontSize: 10 },
            columnStyles: { 0: { cellWidth: 40 } },
            margin: { top: 65, bottom: 50 },
          });
          let medicTableEndWithoutDisclaimer = doc.lastAutoTable.finalY + 6;

          doc.setFont("Roboto-MediumItalic");
          doc.setFontSize(6);
          // contains a key-value pair for CustomFont

          doc.text("Disclaimer:", 14, medicTableEndWithoutDisclaimer);

          doc.setFontStyle("italic");
          doc.text(
            "Medications listed herein have been duly prescribed and authorized by the attending physician, in accordance with relevant legal statutes.",
            26,
            medicTableEndWithoutDisclaimer
          );
        }

        let medicTableEnd = doc.lastAutoTable.finalY + 10;
        if (this.printValue === 0 && medicTableEnd + 25 >= 280) {
          medicTableEnd = 60;
          doc.addPage();
        } else if (this.printValue === 1 && labEnd + 25 >= 280) {
          labEnd = 60;
          doc.addPage();
        }

        // IO TABLE
        const startYVal = this.printValue === 0 ? medicTableEnd : labEnd;
        this.setAutoTableHeader(doc, startYVal, "IO", null, 108);
        this.setAutoTable(doc, startYVal + 12, "#ioTable", null, 108);

        // LINES TABLE
        this.setAutoTableHeader(doc, startYVal, "Lines, Drains & Tubes", 108);
        this.setAutoTable(doc, startYVal + 12, "#linesTables", 108);

        // VITAL TABLE
        topY = doc.lastAutoTable.finalY + 4;
        this.setAutoTableHeader(doc, topY, "Vitals");
        topY = doc.lastAutoTable.finalY + 4;
        this.setAutoTableForLabsAndVitals(
          doc,
          topY,
          "#vitalTable",
          {
            halign: "left",
            report: "vitals",
          },
          this.callbackToSetNumberOfColumns
        );

        // If either of diet and pharma note exists
        if (dietPharmaPage) {
          if (this.patNotes["dietNote"]) {
            let noteDate = this.setDateTime(
              this.patNotes["dietNote"].timestamp
            );
            let noteObj = this.patNotes["dietNote"];
            const noteType = "Dietary Notes: " + noteObj.author;

            topY = this.setNotesTable(doc, noteType, noteDate, topY, noteObj);
          }

          if (this.patNotes["pharmNote"]) {
            const noteDate = this.setDateTime(
              this.patNotes["pharmNote"].timestamp
            );
            const noteObj = this.patNotes["pharmNote"];
            const noteType = "Pharmacology Notes: " + noteObj.author;

            topY = this.setNotesTable(doc, noteType, noteDate, topY, noteObj);
          }
        }
        break;

      case 2:
        this.setAutoTableHeader(doc, topY, "Medications");
        topY = doc.lastAutoTable.finalY + 4;

        doc.autoTable({
          tableLineWidth: 0.1,
          theme: "plain",

          willDrawCell: (data) => {
            if (data.section === "body") {
              // Set the border color for the bottom border
              doc.setDrawColor("#CDD7EB");
              doc.setFontSize(10);
              doc.setFont("Roboto-Light");
              doc.setFontType("normal");

              if (data.cell.raw.parentNode.classList.contains("tr__primary")) {
                doc.setLineWidth(0.1);

                doc.line(
                  data.cell.x,
                  data.cell.y,
                  data.cell.x + data.cell.width,
                  data.cell.y
                );
              }
            }
          },

          startY: topY,
          headStyles: { fillColor: "#EEEFF4", textColor: [0, 0, 0] },

          html: "#medicTables",
          styles: { overflow: "linebreak", fontSize: 10 },
          columnStyles: { 0: { cellWidth: 40 } },
          margin: { top: 65, bottom: 50 },
        });

        doc.setFont("Roboto-MediumItalic");
        doc.setFontSize(6);
        doc.text("Disclaimer:", 14, doc.lastAutoTable.finalY + 6);
        doc.setFontStyle("italic");
        doc.text(
          "  Medications listed herein have been duly prescribed and authorized by the attending physician, in accordance with relevant legal statutes.",
          26.5,
          doc.lastAutoTable.finalY + 6
        );

        break;

      case 3:
      case 4:
        if (this.patNotes["nurseNote"]) {
          const noteObj = this.patNotes["nurseNote"];
          const date = this.setDateTime(noteObj.timestamp);
          const noteType = "Nursing Note";

          //  this.setRegistrationInfo(doc, topY, noteObj, "Nurse's name: ");

          topY = this.setNotesTable(doc, noteType, date, topY, noteObj);
        }

        // Vital table
        this.setAutoTableHeader(doc, topY, "Vitals");
        topY = doc.lastAutoTable.finalY + 4;
        this.setAutoTableForLabsAndVitals(
          doc,
          topY,
          "#vitalTable",
          {
            halign: "left",
            report: "vitals",
          },
          this.callbackToSetNumberOfColumns
        );
        topY = doc.lastAutoTable.finalY + 2;

        // Vent table
        this.setAutoTableHeader(doc, topY + 4, "Ventilation");
        topY = doc.lastAutoTable.finalY + 4;
        this.setAutoTableForLabsAndVitals(
          doc,
          topY,
          "#ventTable",
          {
            halign: "left",
            report: "vent",
          },
          this.callbackToSetNumberOfColumns
        );

        //this.setAutoTable(doc, topY + 4, "#ventTable");

        // Med table
        if (this.printValue == 3) {
          this.setAutoTableHeader(doc, null, "Medications");
          topY = doc.lastAutoTable.finalY + 4;

          doc.autoTable({
            tableLineWidth: 0.1,
            theme: "plain",

            willDrawCell: (data) => {
              if (data.section === "body") {
                // Set the border color for the bottom border
                doc.setDrawColor("#CDD7EB");
                doc.setFontSize(10);
                doc.setFont("Roboto-Light");
                doc.setFontType("normal");

                if (
                  data.cell.raw.parentNode.classList.contains("tr__primary")
                ) {
                  doc.setLineWidth(0.1);

                  doc.line(
                    data.cell.x,
                    data.cell.y,
                    data.cell.x + data.cell.width,
                    data.cell.y
                  );
                }
              }
            },

            startY: topY,
            headStyles: { fillColor: "#EEEFF4", textColor: [0, 0, 0] },

            html: "#medicTables",
            styles: { overflow: "linebreak", fontSize: 10 },
            columnStyles: { 0: { cellWidth: 40 } },
            margin: { top: 65, bottom: 50 },
          });
          doc.setFont("Roboto-MediumItalic");
          doc.setFontSize(6);

          doc.text("Disclaimer:", 14, doc.lastAutoTable.finalY + 6);
          doc.setFontStyle("italic");
          doc.text(
            "Medications listed herein have been duly prescribed and authorized by the attending physician, in accordance with relevant legal statutes.",
            26.5,
            doc.lastAutoTable.finalY + 6
          );
        }

        // IO TABLE
        topY = doc.lastAutoTable.finalY + 10;
        this.setAutoTableHeader(doc, topY, "IO");
        topY = doc.lastAutoTable.finalY + 4;
        this.setAutoTable(doc, topY, "#ioTable");
        break;
    }

    doc = this.setPdfHeaderAndFooterOnEveryPage(doc);

    // open the pdf in new window
    let pdfWindow = window.open("", "", "width=700,height=900");
    pdfWindow.document.write(
      "<iframe style='width: 100%; height: 70rem' src='" +
        doc.output("datauristring") +
        "' frameborder='0'></iframe>"
    );
    pdfWindow.document.close();

    // submit the pdf.
    this.submitUpload(doc.output("datauristring"));
  }

  setPdfHeader(doc: jsPDFDocument): jsPDFDocument {
    doc.autoTable({
      startY: 14,
      headStyles: {
        fillColor: [255, 255, 255],
        textColor: "#2B2A3A",
      },
      head: [["Critical Care Progress Note"]],
      styles: { halign: "center", fontSize: 14, fontStyle: "bold" },
    });

    // Setting image header
    this.utils.setImageHeader(doc, this.imgData);

    let topY = doc.lastAutoTable.finalY + 8;

    if (doc.getNumberOfPages() > 1) {
      //set patient info
      doc.autoTable({
        tableLineWidth: 0.1,
        startY: topY,
        headStyles: {
          fillColor: "#E2E8F4",
          textColor: [0, 0, 0],
          hLineWidth: 0.1, // Add border to left and right sides of the header
          vLineColor: "#CDD7EB", // Set vertical border color
          hLineColor: "#CDD7EB", // Set horizontal border color
        },
        head: [["Patient Information"]],
        styles: { fontSize: 12 },
      });
      doc.setDrawColor("#fff"); // Set line color to white
      doc.setLineWidth(0.1); // Set line width to match the table border width
      doc.line(14, topY, 196, topY); // Adjust the coordinates as needed
      doc.autoTable({
        styles: {
          fontSize: 10,
          textColor: "#2B2A3A",
          cellBorderStyle: "none",

          hLineWidth: 0.1,
          hLineColor: "#CDD7EB", // Set horizontal border color
          vLineColor: "#CDD7EB", // Set vertical border color },
        },
        tableLineWidth: 0.1,
        tableWidth: "auto",
        startY: topY + 8,
        columnStyles: {
          0: { cellWidth: 33, fillColor: "#fff" },
          1: { cellWidth: 58, fillColor: "#fff" },
          2: { cellWidth: 33, fillColor: "#fff" },
          3: { cellWidth: 58, fillColor: "#fff" },
        },
        body: [
          [
            { content: "Name: ", styles: { font: "Roboto-Medium" } },
            this.setPatientNameInCamelCase(
              this.patInfo["name"],
              this.patInfo["lastName"]
            ) || "-",
            { content: "MRN: ", styles: { font: "Roboto-Medium" } },
            `${this.patInfo["MRN"] || "-"}`,
          ],
          [
            { content: "Age / Gender: ", styles: { font: "Roboto-Medium" } },
            `${this.getPatientAge() + " / " + (this.patInfo["sex"] || "-")}`,

            { content: "Bed no.: ", styles: { font: "Roboto-Medium" } },
            `${this.patInfo["bedNo"] || "-"}`,
          ],
        ],
      });
    }
    // Draw a line to cover the top border of the head
    const headHeight = doc.previousAutoTable.finalY - topY;
    doc.setDrawColor("#fff"); // Set line color to white
    doc.setLineWidth(0.8); // Set line width to match the table border width
    doc.line(14.5, topY + 8.3, 195.5, topY + 8.3); // Adjust the coordinates as needed
    return doc;
  }

  setAutoTableHeader(doc, topY, headerText, left = null, right = null) {
    if (
      headerText === "Summary" ||
      headerText === "Treatment details" ||
      headerText === "Patient details" ||
      headerText === "Maternal history" ||
      headerText === "Birth details" ||
      headerText === "Other details" ||
      headerText === "Examination" ||
      headerText === "Treatment plan"
    ) {
      doc.autoTable({
        startY: topY,
        headStyles: {
          fillColor: "#fff",
          textColor: [0, 0, 0],
        },
        head: [[headerText]],
        margin: { top: 65, bottom: 25, left: left, right: right },
        styles: { fontSize: 10 },
      });
    } else if (headerText != undefined) {
      doc.autoTable({
        startY: topY,
        headStyles: {
          fillColor: "#E2E8F4",
          textColor: [0, 0, 0],
          lineColor: "#E2E8F4",
        },
        head: [[headerText]],
        margin: { top: 65, bottom: 25, left: left, right: right },
        styles: { fontSize: 12 },
      });
    }

    // doc.setDrawColor("red"); // Set line color to white
    // doc.setLineWidth(0.2); // Set line width to match the table border width
    // doc.line(14, topY + 10.6, 196, topY + 10.6); // Adjust the coordinates as needed
  }

  setAutoTable(doc, topY, htmlId, left = null, right = null) {
    doc.autoTable({
      tableLineWidth: 0.1,
      theme: "plain",
      headStyles: {
        fillColor: "#EEEFF4",
        textColor: [0, 0, 0],
      },
      willDrawCell: (data) => {
        if (data.section === "body") {
          // Set the border color for the bottom border
          doc.setDrawColor("#CDD7EB");
          doc.setFontSize(10);
          doc.setFont("Roboto-Light");
          doc.setFontType("normal");

          doc.setLineWidth(0.1);

          doc.line(
            data.cell.x,
            data.cell.y,
            data.cell.x + data.cell.width,
            data.cell.y
          );
        }
      },
      html: htmlId,

      startY: topY,
      margin: { top: 65, bottom: 25, left: left, right: right },
      styles: { overflow: "linebreak", fontSize: 10 },
    });
  }

  callbackToSetNumberOfColumns = (
    tableId: "labs" | "vitals" | "vents",
    widthControl: number
  ) => {
    let Options = {};
    if (tableId === "labs") {
      Options["columnStyles"] = {
        0: {
          cellWidth: widthControl,
          fillColor: "#EEEFF4",
        },
        1: {
          fillColor: "#FFFFFF",
        },
      };
      return Options;
    } else {
      const columnLength = !!this.vitalsData.length
        ? this.vitalsData?.length
        : 0;
      Options["columnStyles"] = {
        0: {
          cellWidth: widthControl,
          fillColor: "#EEEFF4",
          lineWidth: 0.5,
          lineColor: "#E2E8F4",
          font: "Roboto-Bold",
          textColor: [0, 0, 0],
        },
      };
      if (columnLength)
        for (let i = 1; i <= columnLength; i++) {
          let index = `${i}`;
          Options["columnStyles"][index] = {
            fillColor: "#FFF",
            lineWidth: 0.5,
            lineColor: "#E2E8F4",
            font: "Roboto-Light",
          };
        }
      return Options;
    }
  };

  setAutoTableForLabsAndVitals(
    doc: jsPDFDocument,
    topY: number,
    htmlId: string,
    {
      left = null,
      right = null,
      widthControl = null,
      halign = "center",
      report = "labs",
    },
    setNumberOfColumns
  ) {
    const Options = setNumberOfColumns(report, widthControl);
    doc.setFontSize(10);
    doc.autoTable({
      tableLineWidth: 0.1,
      theme: "plain",

      headStyles: {
        fillColor: "#EEEFF4",
        textColor: [0, 0, 0],
        halign: halign,
      },
      willDrawCell: (data) => {
        if (data.section === "body") {
          // Set the border color for the bottom border
          if (report == "labs") {
            doc.setLineWidth(0.5);
          } else {
            doc.setLineWidth(0.1);
          }

          doc.setDrawColor("#CDD7EB");

          doc.line(
            data.cell.x, // Left edge of the cell
            data.cell.y, // Bottom edge of the cell
            data.cell.x + data.cell.width, // Right edge of the cell
            data.cell.y
          );
        }
      },
      columnStyles: Options["columnStyles"],
      html: htmlId,

      startY: topY,
      margin: { top: 65, bottom: 25, left: left, right: right },
      styles: { overflow: "linebreak", fontSize: 9, halign: "left" },
    });
  }
  // doc.autoTable({

  //   startY: topY,
  //   headStyles: {
  //     fillColor: "#EEEFF4",
  //     lineWidth: 0.1,
  //     lineColor: "#EEEFF4",
  //     textColor: [0, 0, 0],
  //     halign: halign,
  //   },
  //   columnStyles: Options["columnStyles"],
  //   html: htmlId,
  //   margin: { top: 65, bottom: 25, left: left, right: right },
  //   styles: { overflow: "linebreak", fontSize: 10, halign: "left" },
  // });
  // This is a quick.. Need to refactor this code
  setNoteHeaders(noteObj) {
    return noteObj.components.map(({ displayName }) => displayName);
  }

  setNotesTable(doc, noteType, noteDate, topY, noteObj) {
    // TABLE HEAD

    doc.autoTable({
      headStyles: {
        fillColor: "#E2E8F4",
        textColor: [0, 0, 0],
      },
      head: [[noteType, { content: noteDate, styles: { halign: "right" } }]],
      styles: { fontSize: 12, fontStyle: "bold" },
      margin: { top: 65, bottom: 25 },
    });

    topY = doc.lastAutoTable.finalY + 2;

    if (noteObj.components.length) {
      this.setAutoTableHeader(doc, topY, this.setNoteHeaders(noteObj));
      topY = doc.lastAutoTable.finalY;
      for (let i = 0; i < noteObj.components.length; i++) {
        topY = this.setFromHtmlTable(doc, topY, noteObj.components[i].value);
        topY += 5;
      }
    }

    // Physician Table
    if (noteObj["cosign"] && noteObj["cosign"]["physicianMessage"]) {
      this.setAutoTableHeader(doc, topY, "Physician note");
      topY = doc.lastAutoTable.finalY;
      const time = this._timezonePipe.transform(
        noteObj["cosign"].attestedOn,
        "MMM D Y, HH:mm"
      );

      const headerText = noteObj["cosign"].requestedTo.author + " " + time;

      this.setAutoTableHeader(doc, topY + 2, headerText);
      topY = this.setFromHtmlTable(
        doc,
        topY + 10,
        noteObj["cosign"]["physicianMessage"]
      );
      topY += 5;
    }

    // ADDENDUM TABLE
    if (noteObj["addendum"] && noteObj["addendum"].length) {
      this.setAutoTableHeader(doc, topY, "Addendum");
      topY = doc.lastAutoTable.finalY;

      noteObj["addendum"].forEach((addendum: any) => {
        const time = this._timezonePipe.transform(
          addendum.timestamp,
          "MMM D Y, HH:mm"
        );

        let headerText = addendum.name + " " + time;
        headerText += this.timeZoneDetail
          ? ` ( ${this.timeZoneDetail?.abbreviation} )`
          : "";

        this.setAutoTableHeader(doc, topY + 2, headerText);
        topY = this.setFromHtmlTable(doc, topY + 10, addendum.note);
        topY = topY + 5;
      });

      topY = topY + 5;
    }

    // DOCTOR / NURSE TABLE
    // topY = topY - 5;

    this.setRegistrationInfo(doc, topY, noteObj);
    topY = doc.lastAutoTable.finalY + 10;
    //topY += 15;

    return topY;
  }

  setFromHtmlTable(doc, topY, content) {
    content = content.replace(
      content,
      '<span style="font-size: 10px">' + content + `</span>`
    );
    content = content.replace(
      content,
      '<span style="line-height: 12px">' + content + `</span>`
    );
    // Remove bold formatting from <strong> tags by overriding the CSS style
    content = content.replace(
      /<strong>/g,
      '<span style="font-weight:normal;">'
    );
    content = content.replace(/<\/strong>/g, "</span>");

    content = content.replace(
      "Summary",
      '<span style="font-weight:500;">Summary</span>'
    );
    content = content.replace(
      "Diagnosis",
      '<span style="font-weight:500;">Diagnosis</span>'
    );
    content = content.replace(
      "Assessment and Plan",
      '<span style="font-weight:500;">Assessment and Plan</span>'
    );
    content = content.replace(
      "To-Do List",
      '<span style="font-weight:500;">To-Do List</span>'
    );

    doc.fromHTML(
      content,
      19,
      topY,

      {
        width: 175,
      },
      function (callback) {
        topY = callback["y"];
      },
      {
        top: 65,
        bottom: 25,
      }
    );

    return topY;
  }

  setPdfHeaderAndFooterOnEveryPage(doc: jsPDFDocument): jsPDFDocument {
    const pageCount = doc.internal.getNumberOfPages();

    doc.setPage(1); // Start with the first page

    // Process the first page
    doc.setDrawColor("#8B94B3");
    doc.setLineWidth(0.1);
    doc.line(8, 323 - 36, 202, 323 - 36);

    doc.setFont("Roboto-Light", "normal");
    doc.setFontSize(10);
    // left side content
    doc.text(
      "Page 1 of " + String(pageCount) + ", " + "Report created on RADAR",
      10,
      323 - 30
    );

    // right side content
    let printDate = this.setDateTime();
    printDate += this.timeZoneDetail
      ? ` ( ${this.timeZoneDetail?.abbreviation} )`
      : "";

    const xPosition = 210 - 65;
    const yPosition = 323 - 30;

    doc.text("Printed on: ", xPosition, yPosition);
    doc.text(
      printDate,
      xPosition + doc.getTextWidth("Printed on: "),
      yPosition
    );

    // Loop through the remaining pages (starting from page 2)
    for (let i = 2; i <= pageCount; i++) {
      // Set header for each page after the first page
      doc.setPage(i);
      doc = this.setPdfHeader(doc);

      // Set footer on every page
      doc.setDrawColor("#8B94B3");
      doc.setLineWidth(0.1);
      doc.line(8, 323 - 36, 202, 323 - 36);

      doc.setFont("Roboto-Light", "normal");
      doc.setFontSize(10);
      // left side content
      doc.text(
        "Page " +
          String(i) +
          " of " +
          String(pageCount) +
          ", " +
          "Report created on RADAR",
        10,
        323 - 30
      );

      // right side content
      printDate = this.setDateTime();
      printDate += this.timeZoneDetail
        ? ` (${this.timeZoneDetail?.abbreviation})`
        : "";
      const xPosition = 210 - 65;
      const yPosition = 323 - 30;

      doc.text("Printed on: ", xPosition, yPosition);
      doc.text(
        printDate,
        xPosition + doc.getTextWidth("Printed on: "),
        yPosition
      );
    }

    return doc;
  }

  setPatientInfo(doc: jsPDFDocument, topY): jsPDFDocument {
    doc.autoTable({
      tableLineWidth: 0.1,
      startY: topY,
      headStyles: {
        fillColor: "#E2E8F4",
        textColor: [0, 0, 0],
        hLineWidth: 0.1, // Add border to left and right sides of the header
        vLineColor: "#CDD7EB", // Set vertical border color
        hLineColor: "#CDD7EB", // Set horizontal border color
      },
      head: [["Patient Information"]],
      styles: { fontSize: 12 },
    });
    // Draw a line to cover the top border of the head

    doc.setDrawColor("#fff"); // Set line color to white
    doc.setLineWidth(0.1); // Set line width to match the table border width
    doc.line(14, topY, 196, topY); // Adjust the coordinates as needed
    //doc.line(14.5, topY + 7.5, 195.5, topY + 7.5); // Adjust the coordinates as needed
    const noOfIcuDays = this.patInfo.ICUAdmitDate
      ? this.icuDaysPipe.transform(
          this.patInfo.ICUAdmitDate,
          !this.patInfo?.isCurrentlyAdmitted && this.patInfo?.ICUDischargeDate
        )
      : null;

    const admitDate = this.patInfo.ICUAdmitDate
      ? this._timezonePipe.transform(
          this.patInfo.ICUAdmitDate,
          "MMM D Y, HH:mm"
        )
      : "";

    doc.autoTable({
      styles: {
        fontSize: 10,
        textColor: "#2B2A3A",
        cellBorderStyle: "none",

        hLineWidth: 0.1,
        hLineColor: "#CDD7EB", // Set horizontal border color
        vLineColor: "#CDD7EB", // Set vertical border color
      },

      // Set table border width to a non-zero value (e.g., 0.5)
      tableLineWidth: 0.1,
      tableWidth: "auto",
      startY: topY + 8,
      columnStyles: {
        0: { cellWidth: 33, fillColor: "#fff" },
        1: { cellWidth: 58, fillColor: "#fff" },
        2: { cellWidth: 33, fillColor: "#fff" },
        3: { cellWidth: 58, fillColor: "#fff" },
      },
      body: [
        [
          { content: "Name: ", styles: { font: "Roboto-Medium" } },
          //  setFontWeight(doc, "bold"),
          this.setPatientNameInCamelCase(
            this.patInfo["name"],
            this.patInfo["lastName"]
          ) || "-",
          { content: "MRN: ", styles: { font: "Roboto-Medium" } },
          `${this.patInfo["MRN"] || "-"}`,
        ],
        [
          { content: "Age / Gender: ", styles: { font: "Roboto-Medium" } },
          `${this.getPatientAge() + " / " + (this.patInfo["sex"] || "-")}`,

          { content: "Unit / Bed no.: ", styles: { font: "Roboto-Medium" } },
          `${
            (this.patInfo["unitName"] || "-") +
            " / " +
            (this.patInfo["bedNo"] || "-")
          }`,
        ],

        [
          { content: "Height / Weight: ", styles: { font: "Roboto-Medium" } },
          `${
            (this.patInfo["height"]
              ? this.getRounded(this.patInfo.height, 100) + "cm"
              : "-") +
            " / " +
            (this.patInfo.weight
              ? this.getRounded(this.patInfo.weight, 100) + "kg"
              : "-")
          }`,

          { content: "Admit date: ", styles: { font: "Roboto-Medium" } },
          `${admitDate || "-"} (${this.timeZoneDetail?.abbreviation})`,
        ],
        [
          { content: "Blood Group: ", styles: { font: "Roboto-Medium" } },
          `${this.patInfo["bloodGroup"] || "-"}`,
          { content: "BMI: ", styles: { font: "Roboto-Medium" } },

          `${this.patInfo?.BMI?.toFixed(2) || "-"}`,
        ],
        [
          { content: "Allergies: ", styles: { font: "Roboto-Medium" } },
          `${this.patInfo["allergies"] || "-"}`,

          { content: "No of days: ", styles: { font: "Roboto-Medium" } },
          `${noOfIcuDays || "-"}`,
        ],
        [
          { content: "Primary MD: ", styles: { font: "Roboto-Medium" } },
          `${
            this.patInfo["PCP"]
              ? this.patInfo["PCP"] +
                (this.patInfo["PCP_speciality"]
                  ? "," + " " + this.patInfo["PCP_speciality"]
                  : "")
              : "-"
          } `,
        ],
      ],
    });

    // Draw a line to cover the top border of the head
    const headHeight = doc.previousAutoTable.finalY - topY;
    doc.setDrawColor("#fff"); // Set line color to white
    doc.setLineWidth(0.8); // Set line width to match the table border width
    doc.line(14.5, topY + 8.3, 195.5, topY + 8.3); // Adjust the coordinates as needed
    return doc;
  }

  setRegistrationInfo(
    doc: jsPDFDocument,
    topY,
    noteObj,
    name = `Doctor's name: `
  ): jsPDFDocument {
    const imageX = doc.internal.pageSize.width - noteObj["author"].length * 2.5;
    if (noteObj["stamp"])
      doc.addImage(noteObj["stamp"], "JPEG", imageX, 240, 20, 20);
    if (noteObj["signature"])
      doc.addImage(noteObj["signature"], "JPEG", imageX - 25, 240, 20, 20);

    doc.setFontSize(10);
    // const rightX = doc.internal.pageSize.width - noteObj["author"].length * 4;
    let rightX;
    if (doc.getTextWidth(noteObj["author"]) < 13) {
      rightX = doc.internal.pageSize.width - noteObj["author"].length * 9;
    } else {
      rightX = doc.internal.pageSize.width - noteObj["author"].length * 4;
    }
    doc.setFont("Roboto-Light");
    const doctorNameLabel = "Doctor's Name: ";
    doc.text(doctorNameLabel, rightX, 270, { halign: "right" });
    doc.setFont("Roboto-Bold");
    const doctorNameValue = noteObj ? noteObj["author"] || "-" : "-";
    doc.text(doctorNameValue, rightX + doc.getTextWidth(doctorNameLabel), 270, {
      halign: "right",
    });

    // Add registration id
    doc.setFont("Roboto-Light");
    const registrationIdLabel = "Registration id: ";
    doc.text(registrationIdLabel, rightX, 270 + 5, { halign: "right" });
    doc.setFont("Roboto-Bold");
    const registrationIdValue = noteObj
      ? noteObj["registration_id"] || "-"
      : "-";
    doc.text(
      registrationIdValue,
      rightX + doc.getTextWidth(registrationIdLabel),
      270 + 5,
      { halign: "right" }
    );

    // Set font to normal for the "Specialty" label
    doc.setFont("Roboto-Light");
    const specialtyLabel = "Specialty: ";
    doc.text(specialtyLabel, rightX, 270 + 10, { halign: "right" });

    // Set font style to bold for the "Specialty" value
    doc.setFont("bold");
    const specialtyValue = noteObj ? noteObj["authorSpeciality"] || "-" : "-";
    doc.text(
      specialtyValue,
      rightX + doc.getTextWidth(specialtyLabel),
      270 + 10,
      { halign: "right" }
    );

    // Return the updated doc

    // doc.autoTable({
    //   startY: topY,
    //   columnStyles: {
    //     0: { cellWidth: 33 },
    //   },
    //   headStyles: {
    //     fillColor: "#E2E8F4",
    //     textColor: [0, 0, 0],
    //   },
    //   alternateRowStyles: { fillColor: "#FFFFFF" },
    //   bodyStyles: {
    //     textColor: [0, 0, 0],
    //   },
    //   head: [
    //     [
    //       name,
    //       !!noteObj ? noteObj["author"] : "" || "-",
    //       "Nurse's name: ",
    //       this.getNurseName(),
    //     ],
    //   ],
    //   body: [
    //     [
    //       "Registration id: ",

    //       !!noteObj ? noteObj["registration_id"] : "" || "-",

    //       "Registration id: ",

    //       this.getNurseRegistrationId(),
    //     ],
    //     ["Specialty: ", !!noteObj ? noteObj["authorSpeciality"] : "" || "-"],
    //   ],

    //   didParseCell: (cell, data) => {
    //     if (
    //       cell.cell.raw == "Doctor's name: " ||
    //       cell.cell.raw == "Nurse's name: "
    //     )
    //       cell.cell.styles.fontStyle = "bold";
    //     else cell.cell.styles.fontStyle = "normal";
    //   },
    // });
    //topY = doc.lastAutoTable.finalY + 6;
  }

  getNurseName() {
    if (!this.isUserNurse()) return "";

    return this.currUser["name"];
  }

  getNurseRegistrationId() {
    if (!this.isUserNurse()) return "";

    return this.currUser["registration_id"];
  }

  isUserNurse() {
    return [this.NURSE, this.CCN].includes(this.currUser.role);
  }

  setPatientNameInCamelCase(name, lastName) {
    if (name.length && name.startsWith("b/o"))
      return `${name}` + `${lastName ?? ""}`;

    return (
      `${name ? name[0].toUpperCase() + name.substr(1) : ""}` +
      `${lastName ? " " + lastName[0].toUpperCase() + lastName.substr(1) : ""}`
    );
  }

  setDateTime(dateStr = "") {
    let noteDayObj = this._tz.getCurrentTimeObj();
    if (dateStr) {
      noteDayObj = this._tz.transformIntoTimezoneObj(dateStr);
    }
    let noteMonth = noteDayObj.month() + 1;
    let noteDate =
      (noteDayObj.date() > 9 ? "" : "0") +
      noteDayObj.date() +
      "-" +
      (noteMonth > 9 ? "" : "0") +
      noteMonth +
      "-" +
      noteDayObj.year() +
      " " +
      (noteDayObj.hour() > 9 ? "" : "0") +
      noteDayObj.hour() +
      ":" +
      (noteDayObj.minute() > 9 ? "" : "0") +
      noteDayObj.minute();

    return noteDate;
  }

  getRounded(val, digits) {
    return Math.round(val * digits) / digits;
  }

  submitUpload(val) {
    const fileName = this.fileNameMap.get(this.printValue);

    // converting the base64 to file data as the default data is omitting images
    let fileData = dataURLtoFile(val, fileName);

    this.docProps["name"] = fileName;
    this.docProps["type"] = "application/pdf";
    this.docProps["file"] = fileData;

    this._fileService
      .getSignedUrl({
        name: fileName,
        type: "application/pdf",
      })
      .subscribe((signedData) => {
        if (signedData["status"] == "success") {
          this.docProps["key"] = signedData["data"]["key"];
          const summaryType = fileName.replace(/\.pdf/i, "");
          this._fileService
            .uploadFile(
              this.docProps["file"],
              signedData["data"]["url"],
              this.docProps["type"]
            )
            .subscribe((data) => {
              if (data && data.type == 4) {
                let dataToSend = {
                  CPMRN: this.CPMRN,
                  encounters: this.encounters,
                  key: this.docProps["key"],
                  timestamp: new Date(),
                  fileType: "summary",
                  summaryType,
                };

                this._summaryService.saveFileNameToDb(dataToSend).subscribe(
                  (data) => {
                    if (data["success"] == 1) {
                      this.successMessage = "PDF saved";
                      this.showMessage();
                    } else {
                      console.log("Server Error");
                    }
                    this.restError = false;
                    this.disableBtn = null;
                  },
                  (err) => {
                    console.log(err);
                    this.restError = true;
                    this.disableBtn = null;
                  }
                );
              }
            });
        }
      });
  }

  showMessage() {
    this.alertService.showNotification({
      type: "Success",
      message: this.successMessage,
    });
  }

  openPdf() {
    this.disableBtn = "getpdf";

    this._fileService.getFile(this.pastSummary).subscribe(
      (data) => {
        let pdfWindow = window.open("", "", "width=500,height=900");
        pdfWindow.document.write(
          "" +
            `<iframe style='width: 100%; height: 70rem' src=${data.data}></iframe>`
        );
        pdfWindow.document.close();
        this.restError = false;
        this.disableBtn = null;
      },
      (err) => {
        console.log(err);
        this.restError = true;
        this.disableBtn = null;
      }
    );
  }

  getClassForTest(name: string, val: number, patient = null): string {
    const tests = testData.tests.get(name);

    if (!tests || !val) {
      return "";
    }

    for (const test of tests) {
      if (
        (test.sex && !patient.sex) ||
        (test.minAge && !patient.age) ||
        (test.maxAge && !patient.age)
      ) {
        return "";
      } else if (
        (test.sex && test.sex !== patient.sex) ||
        (test.minAge && test.minAge >= patient.age) ||
        (test.maxAge && test.maxAge <= patient.age)
      ) {
        continue;
      } else if (
        (test.min != null && +val < test.min) ||
        (test.max != null && +val > test.max)
      ) {
        return "abnormal_val";
      }
    }

    return "";
  }

  returnZero() {
    return 0;
  }

  errorRangeValues(rangeParams) {
    if (rangeParams && typeof rangeParams === "object") {
      return FormValidators.errorRange(
        rangeParams.value,
        rangeParams.errorRange
      );
    }
  }

  /**
   * 0 - Progress note/Admission note with meds
   * 1 - Progress note/Admission note without meds
   * 2 - Active Medications
   * 3 - Shift summary with meds
   * 4 - Shift summary without meds
   */
  setSummary(val) {
    val = parseInt(val);
    this.printValue = val;

    switch (val) {
      case 0:
        this.summaryType = {
          noteWidth: "half",
          docNote: {
            display: true,
          },
          nurseNote: {
            display: false,
          },
          vitals: {
            display: true,
          },
          vents: {
            display: false,
          },
          labs: {
            display: true,
          },
          io: {
            display: true,
          },
          lines: {
            display: true,
          },
          meds: {
            display: true,
          },
          extraNote: {
            display: true,
          },
          docInfo: {
            display: true,
          },
        };

        // this.vitalsData = [...this.extendedVitalsData];
        // if (this.vitalsData.length > 6) this.vitalsData.length = 6;
        break;

      case 1:
        this.summaryType = {
          noteWidth: "half",
          docNote: {
            display: true,
          },
          nurseNote: {
            display: false,
          },
          vitals: {
            display: true,
          },
          vents: {
            display: false,
          },
          labs: {
            display: true,
          },
          io: {
            display: true,
          },
          lines: {
            display: true,
          },
          meds: {
            display: false,
          },
          extraNote: {
            display: true,
          },
          docInfo: {
            display: true,
          },
        };

        // this.vitalsData = [...this.extendedVitalsData];
        // if (this.vitalsData.length > 6) this.vitalsData.length = 6;
        break;

      case 2:
        this.summaryType = {
          noteWidth: "full",
          docNote: {
            display: false,
          },
          nurseNote: {
            display: false,
          },
          vitals: {
            display: false,
          },
          vents: {
            display: false,
          },
          labs: {
            display: false,
          },
          io: {
            display: false,
          },
          lines: {
            display: false,
          },
          meds: {
            display: true,
          },
          extraNote: {
            display: false,
          },
          docInfo: {
            display: false,
          },
        };
        break;

      case 3:
        this.summaryType = {
          noteWidth: "full",
          docNote: {
            display: false,
          },
          nurseNote: {
            display: true,
          },
          vitals: {
            display: true,
          },
          vents: {
            display: true,
          },
          labs: {
            display: false,
          },
          io: {
            display: true,
          },
          lines: {
            display: false,
          },
          meds: {
            display: true,
          },
          extraNote: {
            display: false,
          },
          docInfo: {
            display: false,
          },
        };
        // this.vitalsData = [...this.extendedVitalsData];
        break;

      case 4:
        this.summaryType = {
          noteWidth: "full",
          docNote: {
            display: false,
          },
          nurseNote: {
            display: true,
          },
          vitals: {
            display: true,
          },
          vents: {
            display: true,
          },
          labs: {
            display: false,
          },
          io: {
            display: true,
          },
          lines: {
            display: false,
          },
          meds: {
            display: false,
          },
          extraNote: {
            display: false,
          },
          docInfo: {
            display: false,
          },
        };
        // this.vitalsData = [...this.extendedVitalsData];
        break;

      default:
        break;
    }
  }

  getPatientAge() {
    return displayAge(
      this.patInfo?.age,
      true,
      this.patInfo.patientType,
      this.patInfo.dob
    );
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
  async processNotes(data) {
    const finalNotes = data["finalNotes"];
    const promises = [];

    const processNote = async (i) => {
      const note = finalNotes[i];
      const signature = note["content"]["signature"];
      const stamp = note["content"]["stamp"];

      if (signature) {
        try {
          const res: any = await this._userFormService
            .downloadImage(signature)
            .toPromise();
          if (res.data) {
            this.utils.getImageUri(res.data, (dataUri) => {
              if (dataUri) {
                note["content"].signature = dataUri;
              }
            });
          }
        } catch (error) {
          note["content"].signature = "";
          console.error("Error processing signature", i, error);
        }
      }

      if (stamp) {
        try {
          const res: any = await this._userFormService
            .downloadImage(stamp)
            .toPromise();
          if (res.data) {
            this.utils.getImageUri(res.data, (dataUri) => {
              if (dataUri) {
                note["content"].stamp = dataUri;
              }
            });
          }
        } catch (error) {
          note["content"].stamp = "";
          console.error("Error processing stamp", i, error);
        }
      }

      // Process the next note recursively
      await processNote(i + 1);
    };

    // Start processing notes from index 0
    await processNote(0);
  }
}
