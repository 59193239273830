import React, { useState, useEffect } from 'react';
import ClickAwayListener from 'react-click-away-listener';
import { useAppDispatch, useAppSelector } from '../reactStore/hooks';
import { fetchHealthcheck } from '../reactStore/reactSlice/healthcheckSlice';
import { version as staticVersion } from 'src/version/version';

const RefreshModal: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isButtonHovered, setIsButtonHovered] = useState(false);
  const dispatch = useAppDispatch();
  const msg = useAppSelector((state) => state.healthcheck.msg);
  const version = useAppSelector((state) => state.healthcheck.version);
  const healthcheckError = useAppSelector((state) => state.healthcheck.error);

  useEffect(() => {
    dispatch(fetchHealthcheck());
  }, [dispatch]);

  useEffect(() => {
    if (version && version !== staticVersion.no) {
      setIsOpen(true);
    }
  }, [version]);

  const handleHardRefresh = () => {
    setIsOpen(false);
    if ('caches' in window) {
      caches.keys().then((names) => {
        names.forEach((name) => {
          caches.delete(name);
        });
      });
    }
    window.location.reload();
  };

  const modalContainer: React.CSSProperties = {
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
    width: '400px',
    height: '300px',
    backgroundColor: '#fff',
    borderRadius: '8px',
    position: 'fixed' as 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: '2000',
  };

  const modalContent: React.CSSProperties = {
    display: 'flex',
    flexDirection: 'column' as 'column',
    gap: '16px',
    position: 'relative',
    padding: '20px',
  };

  const modalHeader = {
    fontSize: '2rem',
    fontFamily: 'serif',
    fontWeight: 600,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#34A2B1',
  };

  const modalText = {
    fontSize: '1.125rem',
  };

  const buttonStyle = {
    padding: '0.5rem 1rem',
    backgroundColor: '#34A2B1',
    color: 'white',
    border: 'none',
    borderRadius: '0.25rem',
    cursor: 'pointer',
  };

  const closeButton: React.CSSProperties = {
    position: 'absolute',
    top: '-5px',
    right: '-35px',
    background: 'none',
    border: 'none',
    cursor: 'pointer',
  };


  const backdrop: React.CSSProperties = {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: '1000', 
  };

  return (
    <>
      {isOpen && (
        <>
        <div style={backdrop}></div>
        <ClickAwayListener onClickAway={() => setIsOpen(false)}>
          <div style={modalContainer}>
            <div style={modalContent}>
              <button style={closeButton} onClick={() => setIsOpen(false)}>
                <img src="assets/icons/cancel.svg" alt="Close" />
              </button>
              <h1 style={modalHeader}>Check your version</h1>
              <p style={modalText}>Health Check Message: {msg}</p>
              <p style={modalText}>Health Check Version: {version}</p>
              <button
                style={buttonStyle}
                onClick={handleHardRefresh}
                onMouseEnter={() => setIsButtonHovered(true)}
                onMouseLeave={() => setIsButtonHovered(false)}
                >
                Hard Refresh
              </button>
              {healthcheckError && <p className="error-text" style={{ color: 'red' }}>{healthcheckError}</p>}
            </div>
          </div>
        </ClickAwayListener>
        </>
      )}
      </>
  );
};

export default RefreshModal;
