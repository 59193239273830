import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, Subject, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class ProfilePicService {
  public env = environment;
  public httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
    }),
  };

  constructor(private http: HttpClient) {}
  private image = new Subject();
  notifyImageUpdate = this.image.asObservable();

  notifyImage(data, key, index?) {
    const value = {
      data: data,
      key: key,

      index: index,
    };
    this.image.next(value);
  }

  getSignedUrlPrivate(type, name, size, isUserCreation): Observable<any> {
    if (isUserCreation) {
      return this.http
        .post<any>(
          `${this.env.apiUrl}management/user/signAndStampUpload`,
          { name: name, type: type, size: size },
          this.httpOptions
        )
        .pipe(catchError(this.errorHandler));
    } else {
      return this.http
        .post<any>(
          `${this.env.apiUrl}patients/profilepicture`,
          { type: type },
          this.httpOptions
        )
        .pipe(catchError(this.errorHandler));
    }
  }

  getImage(cpmrn, encounters): Observable<any> {
    return this.http
      .get<any>(
        `${this.env.apiUrl}patients/${cpmrn}/${encounters}/profilepicture`
      )
      .pipe(catchError(this.errorHandler));
  }

  downloadImage(CPMRN, key, bucket?: string) {
    return this.http
      .post(
        this.env.apiUrl + "patients/" + CPMRN + "/download/",
        { key: key, bucket: bucket },
        {
          observe: "response",
          ...this.httpOptions,
        }
      )
      .pipe(catchError(this.errorHandler));
  }

  removeImage(url, cpmrn, encounters): Observable<any> {
    let options = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      body: { profilePicture: url },
    };
    return this.http
      .delete<any>(
        `${this.env.apiUrl}patients/${cpmrn}/${encounters}/profilepicture`,
        options
      )
      .pipe(catchError(this.errorHandler));
  }
  errorHandler(error: HttpErrorResponse) {
    return throwError(error || "Server error");
  }
}
