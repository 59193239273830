import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { catchError, count, map, tap } from "rxjs/operators";
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from "@angular/common/http";
import { BehaviorSubject, EMPTY, Subject, of, throwError } from "rxjs";
import { UniqueDay } from "@shared-modules/pipes/unique-day-pipe/model";

@Injectable({
  providedIn: "root",
})
export class VitalsService {
  env = environment;

  public netraImage = new Subject<any>();
  triggerNetraImage(day) {
    this.netraImage.next(day);
  }

  public httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
    }),
  };

  private dayNums$: BehaviorSubject<UniqueDay[] | null> = new BehaviorSubject<
    UniqueDay[] | null
  >(null);
  public _dayNumAsObservable$ = this.dayNums$.asObservable();
  public _dayNumDataLoader: Map<number, boolean> = new Map<number, boolean>();

  constructor(private _http: HttpClient) {}

  getVitals(CPMRN, encounters, queryParams: any[] = []) {
    return this._http
      .get(this.env.vitalsUrl + "v1/vitals/" + CPMRN + "/" + encounters, {
        params: {
          [queryParams[0]]: queryParams[1],
        },
      })
      .pipe(catchError(this.errorHandler));
  }

  updateVitals(CPMRN, encounters, vitals) {
    return this._http
      .patch(
        this.env.apiUrl + "v1/vitals/" + CPMRN + "/" + encounters,
        vitals,
        this.httpOptions
      )
      .pipe(catchError(this.errorHandler));
  }

  errorHandler(error: HttpErrorResponse) {
    return throwError(error || "Server error");
  }

  getVitalImage(key: string, origin: string) {
    return this._http.post(
      `${this.env.apiUrl}vitals/get_vital_image`,
      { fileName: key, origin },
      this.httpOptions
    );
  }

  getDayNums(CPMRN, encounters) {
    this._http
      .get(`${this.env.apiUrl}v1/vitals/${CPMRN}/${encounters}/dayNums`)
      .pipe(
        map((d: any) => d?.data),
        tap((value) => this.dayNums$.next(value)),
        catchError(this.errorHandler)
      )
      .subscribe();
  }
}
