import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { CTAType } from "@notes/models/notes.model";
import { Observable, of } from "rxjs";
import { catchError, switchMap, tap } from "rxjs/operators";
import * as fromNoteTypeActions from "../actions";
import * as fromNoteStore from "@notes/store";
import * as fromViewNoteStore from "@notes-view/store";
import * as fromloaderStateReducer from "@shared-modules/store";
import { FinalNotesAPIResponse } from "@notes-view/model/notes-model";
import { AlertService } from "@iris/alert";

@Injectable({
  providedIn: "root",
})
export class DeleteNoteEffectsHandler {
  constructor(
    private alertService: AlertService,
    private _noteViewStore: Store<fromViewNoteStore.NoteFormState>,
    private _noteStore: Store<fromNoteStore.NoteFormState>,
    private _loaderStore: Store<fromloaderStateReducer.ShareState>
  ) {}

  handleDeleteNoteEffect(
    serviceCall: () => Observable<string>,
    successMessage: string,
    ctaType: CTAType,
    loaderType?: string,
    refId?: string
  ): Observable<FinalNotesAPIResponse> {
    return serviceCall().pipe(
      switchMap(() =>
        this.handleSuccess(successMessage, ctaType, loaderType, refId)
      ),
      catchError((error) => this.handleError(error, loaderType))
    );
  }

  private handleSuccess(
    successMessage: string,
    ctaType: CTAType,
    loaderType: string,
    refId?: string
  ): any {
    this.alertService.showNotification({
      type: "Success",
      message: successMessage,
    });
    this._noteViewStore.dispatch(fromViewNoteStore.deleteDraftNote({ refId }));
    this._noteStore.dispatch(fromNoteStore.setCTA({ cta: ctaType }));
    this._noteStore.dispatch(fromNoteStore.resetNoteContent());
    this._loaderStore.dispatch(
      fromloaderStateReducer.setLoadingSpinner({
        loader: loaderType,
        status: false,
      })
    );
    return of(fromNoteTypeActions.noteSubmissionSuccess());
  }

  private handleError(error: any, loaderType: string): Observable<any> {
    this.alertService.showNotification({
      type: "Error",
      message: error.error.message,
    });
    this._loaderStore.dispatch(
      fromloaderStateReducer.setLoadingSpinner({
        loader: loaderType,
        status: false,
      })
    );
    return of(fromNoteTypeActions.noteSubmissionFail());
  }
}
