import { WorkSpace } from "src/app/models/workspace.model";
import { uniq } from "lodash-es";
import { PinInfo } from "src/app/models/patient";
import { UserRoles } from "src/app/shared/accessControl/roleInterface";

export const ALLOWED_CC_FOR_WORKSPACE = ["Cloudphysician"];
export const HIDDEN_WORKSPACE = "Workspace";
export const OTHER_WORKSPACE = "Other units";
export const CONSULTANT_WARD = "Consultant-Ward";
export const PHYSICIAN_ROLES: string[] = [
  UserRoles.PHYSICIAN,
  UserRoles.SPECIALIST,
  UserRoles.REGISTRAR,
];

export const OTHER_WORKSPACE_INFO: WorkSpace = {
  name: OTHER_WORKSPACE,
  unitIds: null,
};

export const isUserCCAllowedToWorkspace = (userCCs) => {
  if (!userCCs?.length) return false;

  return userCCs.some((cc) => ALLOWED_CC_FOR_WORKSPACE.includes(cc.name));
};

/**
 * @description sort the workspaceName array - if 'Other Workspace' is there it will put it in last else do sort in ascending order
 * @param workSpaceNameArray - workspace names to iterate over in hospital list
 * @returns sorted workspace-name array
 * @date Dec 19, 2022
 * @author Rajat Saini
 */
export const getSortedWorkspaceName = (workSpaceNameArray) => {
  // Clone the array to avoid modifying the original
  let sortedWorkspaceNames = [...workSpaceNameArray].sort();

  if (!sortedWorkspaceNames.includes(OTHER_WORKSPACE))
    return sortedWorkspaceNames;

  return [
    // Filter out OTHER_WORKSPACE and append it at the end
    ...sortedWorkspaceNames.filter((el) => el !== OTHER_WORKSPACE),
    OTHER_WORKSPACE,
  ];
};

/**
 * @description converts hospUnitArray to a string
 * @param hospUnitArray contains hospital, unit array in this format ['hospitalName' - 'unitName']
 * @returns string
 * @author Rajat Saini
 */
export const getHospUnitStringFromArray = (hospUnitArray) => {
  let uniqArray = uniq(hospUnitArray);
  return uniqArray.length > 1
    ? uniqArray.slice(0, uniqArray.length - 1).join(", ") +
        " and " +
        uniqArray.slice(-1)
    : uniqArray.slice(-1);
};

/**
 * @description remove field from pinCPMRNFields(local storage)
 * @param hospUnitArray unitId array fields
 * @returns void
 * @author Rakesh
 */
export const resetLocalStoragePinCPMRNFields = (hospUnitArray) => {
  let CPMRNArray: PinInfo[] = JSON.parse(
    localStorage.getItem("pinCPMRNFields")
  );
  if (!CPMRNArray?.length) return;
  CPMRNArray = CPMRNArray.filter(({ unit }) => !hospUnitArray.includes(unit));
  localStorage.setItem("pinCPMRNFields", JSON.stringify(CPMRNArray));
};

// SHIFT - ASSIGN sort

export function shiftAssignSort(tableValue, sortParam) {
  return tableValue.slice().sort((a, b) => {
    const [s1 = "", s2 = ""] = getSortValues(sortParam.type, a, b);
    const sortOrder = getSortOrder(
      sortParam.direction === "asc" ? true : false
    );
    if (s1 < s2) {
      return sortOrder.less;
    }
    if (s1 > s2) {
      return sortOrder.more;
    }
    return 0;
  });
}
// old-code
function getSortValues(col, val1, val2): [string | null, string | null] {
  switch (col) {
    case "hospital":
      return [
        val1.hospitalInfo?.name?.toLowerCase(),
        val2.hospitalInfo?.name?.toLowerCase(),
      ];
    case "physician":
      return [
        val1.docAssigned?.name?.toLowerCase(),
        val2.docAssigned?.name?.toLowerCase(),
      ];
    case "nurseNP":
      return [
        val1.npAssigned?.name?.toLowerCase(),
        val2.npAssigned?.name?.toLowerCase(),
      ];
    case "nurse":
      return [
        val1.rnAssigned?.name?.toLowerCase(),
        val2.rnAssigned?.name?.toLowerCase(),
      ];
    case "nurseCCRN":
      return [
        val1.ccrnAssigned?.name?.toLowerCase(),
        val2.ccrnAssigned?.name?.toLowerCase(),
      ];
    case "dietitian":
      return [
        val1.dietitianAssigned?.name?.toLowerCase(),
        val2.dietitianAssigned?.name?.toLowerCase(),
      ];
    case "specialist":
      return [
        val1.specialistAssigned?.name?.toLowerCase(),
        val2.specialistAssigned?.name?.toLowerCase(),
      ];
    case "pharmacologist":
      return [
        val1.pharmacologistAssigned?.name?.toLowerCase(),
        val2.pharmacologistAssigned?.name?.toLowerCase(),
      ];
    case "lab-technician":
      return [
        val1.labTechnicianAssigned?.name?.toLowerCase(),
        val2.labTechnicianAssigned?.name?.toLowerCase(),
      ];
    case "documentation-specialist":
      return [
        val1.documentationSpecialistAssigned?.name?.toLowerCase(),
        val2.documentationSpecialistAssigned?.name?.toLowerCase(),
      ];
    case "physical-therapist":
      return [
        val1.physicalTherapistAssigned?.name?.toLowerCase(),
        val2.physicalTherapistAssigned?.name?.toLowerCase(),
      ];
    case "physician-associate":
      return [
        val1.physicianAssociateAssigned?.name?.toLowerCase(),
        val2.physicianAssociateAssigned?.name?.toLowerCase(),
      ];
    case "occupational-therapist":
      return [
        val1.occupationalTherapistAssigned?.name?.toLowerCase(),
        val2.occupationalTherapistAssigned?.name?.toLowerCase(),
      ];
    case "respiratory-therapist":
      return [
        val1.respiratoryTherapistAssigned?.name?.toLowerCase(),
        val2.respiratoryTherapistAssigned?.name?.toLowerCase(),
      ];

    default:
      return ["", ""];
  }
}

function getSortOrder(sortInAsc: boolean): { less: number; more: number } {
  if (!sortInAsc) {
    return { less: 1, more: -1 };
  }

  return { less: -1, more: 1 };
}
