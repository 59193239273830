import { createReducer, on } from "@ngrx/store";
import { DashboardViewReducerState } from "../state";
import * as fromDashboardActions from "../actions";

export const initialState: DashboardViewReducerState = {
  loading: null,
};

const _reducer = createReducer(
  initialState,
  on(fromDashboardActions.updateLoaderStatus, (state, { status }) => ({
    ...state,
    loading: status,
  })),
  on(
    fromDashboardActions.getDashboardData,
    (state, { isShowLoader = true }) => ({
      ...state,
      loading: isShowLoader ? true : state?.loading,
    })
  )
);

export function reducer(state, action) {
  return _reducer(state, action);
}
