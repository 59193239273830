import { createSelector } from "@ngrx/store";
import { getDashboardState } from "../reducers";
import { adapter } from "../reducers/dashboard-data.reducer";

const getDashboardDataState = createSelector(
  getDashboardState,
  (state) => state.data
);

export const { selectAll: getAllWorkspaces } = adapter.getSelectors(
  getDashboardDataState
);

export const getPatientCountG = createSelector(
  getDashboardDataState,
  (state) => state.patientCount
);

export const getSeverityG = createSelector(
  getDashboardDataState,
  (state) => state.severity
);

export const getWorkspaceCountG = createSelector(
  getDashboardDataState,
  (state) => state.workspaceCount
);

export const getHospitalCountG = createSelector(
  getDashboardDataState,
  (state) => state.hospitalCount
);

export const getUnitCountG = createSelector(
  getDashboardDataState,
  (state) => state.unitCount
);

export const getBedCountG = createSelector(
  getDashboardDataState,
  (state) => state.bedCount
);

export const getPhysicianCountG = createSelector(
  getDashboardDataState,
  (state) => state.physicianCount
);

export const getSpecialistCount = createSelector(
  getDashboardDataState,
  (state) => state.specialistCount
);

export const getCCACountG = createSelector(
  getDashboardDataState,
  (state) => state.ccaCount
);

export const getCCRNCountG = createSelector(
  getDashboardDataState,
  (state) => state.ccrnCount
);

export const getRNCountG = createSelector(
  getDashboardDataState,
  (state) => state.rnCount
);
