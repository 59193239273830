import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { UntypedFormControl } from "@angular/forms";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { Store, select } from "@ngrx/store";
import { Subject } from "rxjs";
import { debounceTime, distinctUntilChanged, takeUntil } from "rxjs/operators";
import * as fromNoteStore from "@notes/store";
import { MatAutocompleteSelectedEvent } from "@angular/material/autocomplete";
import { Intensivist } from "@notes/models/notes.model";
import { NoteService } from "@notes/services/note.service";
import * as fromPatientHeaderReducers from "src/app/store/reducers/patient-chart/patient-header/index";
import { AVATAR_SIZE } from "@iris/avatar";

@Component({
  selector: "cp-request-attest",
  templateUrl: "./request-attest.component.html",
  styleUrls: ["./request-attest.component.scss"],
})
export class RequestAttestComponent implements OnInit, OnDestroy {
  authorCosign = new UntypedFormControl();
  cosignIntensivists: Intensivist[];
  noUserMsg: string;
  private unsubscribe$: Subject<void> = new Subject<void>();
  AVATAR_SIZE: typeof AVATAR_SIZE = AVATAR_SIZE;

  selectedIntensivist: Intensivist;
  commandCenterID: string;
  hospitalID: string;
  stopSubscriptionCheckValue: string;

  constructor(
    private _dialogRef: MatDialogRef<RequestAttestComponent>,
    private _noteStore: Store<fromNoteStore.NoteFormState>,
    private store: Store<{}>,
    private dialog: MatDialog,
    private _noteService: NoteService
  ) {}

  ngOnInit(): void {
    this.initSelectedIntensivistSubscription();
    this.initPatientHeaderSubscription();
    this.initAuthorCosignValueChangesSubscription();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  get userInfo() {
    return {
      name: this._noteService.user.name,
      email: this._noteService.user.email,
      author: this._noteService.author,
    };
  }

  private initSelectedIntensivistSubscription(): void {
    this._noteStore
      .pipe(
        select(fromNoteStore.getSelectedIntensivist),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((selectedIntensivist) => {
        this.selectedIntensivist = selectedIntensivist;
      });
  }

  private initPatientHeaderSubscription(): void {
    this.store
      .pipe(
        select(fromPatientHeaderReducers.getPatHeaderData),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((patient) => {
        if (patient && patient.CPMRN) {
          const currPatient = patient;
          this.commandCenterID = currPatient.hospitalInfo["commandCenterID"];
          this.hospitalID = currPatient["hospitalID"];
        }
      });
  }

  private initAuthorCosignValueChangesSubscription(): void {
    this.authorCosign.valueChanges
      .pipe(
        debounceTime(1000),
        distinctUntilChanged(),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((authorName: string) => {
        if (authorName && authorName != this.stopSubscriptionCheckValue) {
          this._noteService
            .getCosignIntensivist(
              this.commandCenterID,
              this.hospitalID,
              authorName
            )
            .subscribe(
              (response) => {
                if (response.status === 200) {
                  this.noUserMsg = null;
                  this.cosignIntensivists = response.body;
                } else if (response.status === 204) {
                  this.noUserMsg = "No user found";
                  this.cosignIntensivists = [];
                }
              },
              (error) => {
                this.noUserMsg = null;
                console.error("Server error!!", error);
              }
            );
        }
      });
  }

  remove(): void {
    const dialogRef = this.dialog.open(CloseRequestAttestModal, {});
    dialogRef.afterClosed().subscribe((result) => {
      if (result?.status) {
        this.selectedIntensivist = null;
        this.authorCosign.enable({ emitEvent: false });
      }
    });
  }

  getcosignIntensivistDetails(intensivist: Intensivist): void {
    this.authorCosign.disable({ emitEvent: false });
    this.selectedIntensivist = intensivist;
  }

  onSelectionChanged(data: MatAutocompleteSelectedEvent): void {
    this.stopSubscriptionCheckValue = data.option.value;
  }

  onSubmit(): void {
    this._dialogRef.close(this.selectedIntensivist);
  }
}

@Component({
  template: `<section class="iris-modal-container cc-status-wrapper">
    <div mat-dialog-title class="title">
      <mat-icon>warning</mat-icon>
      Do you want to delete the attestation request?
    </div>

    <mat-dialog-actions align="end">
      <button mat-flat-button mat-dialog-close class="button-secondary">
        Cancel
      </button>
      <button
        mat-flat-button
        mat-dialog-close
        class="button-error"
        (click)="deleteStatus()"
      >
        Delete
      </button>
    </mat-dialog-actions>
  </section> `,
  styleUrls: ["./close-modal.component.scss"],
})
export class CloseRequestAttestModal {
  constructor(private dialogRef: MatDialogRef<CloseRequestAttestModal>) {}

  deleteStatus() {
    this.dialogRef.close({ status: true });
  }
}
