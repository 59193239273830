// chart.constants.ts
export enum ChartConstants {
  DefaultWidth = 300,
  DefaultHeight = 300,
  DefaultColors = undefined, // Undefined to indicate default color scheme
}

export enum ColorScheme {
  SchemeCategory10 = "schemeCategory10",
  SchemeAccent = "schemeAccent",
  SchemeDark2 = "schemeDark2",
}
