<mat-expansion-panel class="cp-mat-expansion-panel">
  <mat-expansion-panel-header>
    <div class="hospital-unit-exp-container w-100 display-flex flex-column">
      <div class="hospital-details display-flex justify-content-between">
        <div class="hospital-details--header display-flex">
          <mat-icon svgIcon="cp-hospital-logo-1"></mat-icon>
          <div class="hospital-details--name">{{ hospitalData?.name }}</div>
        </div>
        <div class="patient-count display-flex">
          {{ hospitalData?.patientCount }}
          <div class="patient-count--label">patients</div>
        </div>
      </div>
      <div class="units-info display-flex">
        <div
          class="unit-info display-flex"
          *ngFor="let unitObj of hospitalData?.units; index as j"
        >
          <div class="unit-info--name">{{ unitObj?.name }} :</div>
          <div class="unit-info--pat-count">{{ unitObj?.patientCount }}</div>
        </div>
      </div>
    </div>
  </mat-expansion-panel-header>

  <div class="user-list display-flex flex-column">
    <ng-container
      *ngFor="let clinician of hospitalData?.clinicians; index as cliIndex"
    >
      <div class="user-box w-100 display-flex justify-content-between">
        <div class="user-details display-flex align-items-center">
          <app-avatar
            [avatarParams]="[{ name: clinician?.name, size: 'medium' }]"
          ></app-avatar>
          <div class="user-details--text display-flex flex-column">
            <div class="user-details--text--name">{{ clinician?.name }}</div>
            <div class="user-details--text--units">
              Units:
              <span class="user-details--text--units--info">{{
                clinician?.units?.join(", ")
              }}</span>
            </div>
          </div>
        </div>
        <div class="patient-count display-flex align-items-center">
          <div class="patient-count--label">{{ clinician?.assignedRole }}</div>
        </div>
      </div>
      <hr
        class="divider"
        *ngIf="cliIndex < hospitalData?.clinicians?.length - 1"
      />
    </ng-container>
  </div>
</mat-expansion-panel>
