import { Injectable } from "@angular/core";
import * as d3 from "d3";

@Injectable({
  providedIn: "root",
})
export class TooltipService {
  private tooltip: any;
  customTemplate: string;

  constructor() {}

  showTooltip(
    label: string,
    value: number,
    tooltipX: number,
    tooltipY: number,
    color: string
  ) {
    this.tooltip = d3.select(".tooltip");
    this.tooltip.transition().duration(200).style("opacity", 1);
    const htmlTemplate = `<div class="circle-container">
    <div class="circle" style="background-color: ${color}" ></div>
    <div class="label-value">
      <span class="label">${label}:</span>
      <span class="value">${value}</span>
    </div>
  </div>`;

    this.tooltip
      .html(htmlTemplate)
      .style("left", tooltipX + "px")
      .style("top", tooltipY + "px");
  }

  hideTooltip() {
    this.tooltip.transition().duration(200).style("opacity", 0);
  }
}
