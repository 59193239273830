import React from "react";
import "./attentionModalStyles.css";

interface AttentionModalProps {
  setIsAttentionOpen: (isOpen: boolean) => void;
  handleYesClose: () => void;
}

const AttentionModal: React.FC<AttentionModalProps> = ({
  setIsAttentionOpen,
  handleYesClose,
}) => {
  const handleNo = () => {
    setIsAttentionOpen(false);
  };

  const handleYes = () => {
    handleYesClose();
  };

  const handleClose = () => {
    setIsAttentionOpen(false);
  };

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <button onClick={handleClose} className="close-button">
          <img src="assets/icons/close.svg" alt="Close" />
        </button>
        <div className="header">
          <img
            src="assets/icons/warning_24px.svg"
            alt="Warning"
            className="warning-icon"
          />
          <h2 className="title">Attention</h2>
        </div>
        <p className="attention-message">
          Are you sure you want to cancel out without confirming? If yes, then
          it won’t save the ICD code.
        </p>
        <div className="button-container">
          <button onClick={handleYes} className="yes-button">
            Yes
          </button>
          <button onClick={handleNo} className="no-button">
            No
          </button>
        </div>
      </div>
    </div>
  );
};

export default AttentionModal;
