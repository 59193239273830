import React, { useState, useEffect, useRef } from "react";

interface ImageModalProps {
  CPMRN: string;
  imageSrc: string;
}

const ImageModal: React.FC<ImageModalProps> = ({ CPMRN, imageSrc }) => {
  const [isModalOpen, setIsModalOpen] = useState(true);
  const [scale, setScale] = useState(1);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [isDragging, setIsDragging] = useState(false);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const imageRef = useRef<HTMLImageElement | null>(null);
  const startPosition = useRef({ x: 0, y: 0 });

  const handleMouseDown = (event: MouseEvent) => {
    setIsDragging(true);
    startPosition.current = {
      x: event.clientX - position.x,
      y: event.clientY - position.y,
    };
  };

  const handleMouseMove = (event: MouseEvent) => {
    if (isDragging && containerRef.current && imageRef.current) {
      const newX = event.clientX - startPosition.current.x;
      const newY = event.clientY - startPosition.current.y;

      const container = containerRef.current.getBoundingClientRect();
      const image = imageRef.current.getBoundingClientRect();

      const maxX = Math.min(0, container.width - image.width * scale);
      const maxY = Math.min(0, container.height - image.height * scale);

      const constrainedX = Math.max(maxX, Math.min(newX, 0));
      const constrainedY = Math.max(maxY, Math.min(newY, 0));

      setPosition({ x: constrainedX, y: constrainedY });
    }
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  useEffect(() => {
    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("mouseup", handleMouseUp);

    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
    };
  }, [isDragging]);

  const handleZoom = (event: React.WheelEvent) => {
    event.preventDefault();
    const zoomFactor = 0.1;
    setScale((prevScale) => {
      const newScale =
        prevScale + (event.deltaY > 0 ? -zoomFactor : zoomFactor);
      return Math.max(newScale, 1); 
    });
  };

  return (
    isModalOpen && (
      <div
        style={{
          boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
          width: "55rem",
          height: "42rem",
          backgroundColor: "#fff",
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-60%, -50%)",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          zIndex: 1001,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-end",
            width: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "1rem",
              padding: "1rem",
            }}
          >
            <button
              type="button"
              style={{
                width: "1.5rem",
                height: "1.5rem",
                display: "flex",
                cursor: "pointer",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "transparent",
                border: "none",
              }}
              onClick={() => setScale((prevScale) => Math.max(prevScale - 0.1, 0.8))}
              aria-label="Zoom Out"
            >
              <img src="assets/icons/zoomOut.svg" alt="Zoom Out" />
            </button>

            <button
              type="button"
              style={{
                width: "1.5rem",
                height: "1.5rem",
                display: "flex",
                cursor: "pointer",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "transparent",
                border: "none",
              }}
              onClick={() => setScale((prevScale) => Math.min(prevScale + 0.1, 5))}
              aria-label="Zoom In"
            >
              <img src="assets/icons/zoom.svg" alt="Zoom In" />
            </button>

            <button
              type="button"
              onClick={() => setIsModalOpen(false)}
              style={{
                width: "1.5rem",
                height: "1.5rem",
                display: "flex",
                cursor: "pointer",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "transparent", 
                border: "none", 
              }}
              aria-label="Close modal"
            >
              <img src="assets/icons/close.svg" alt="Close" />
            </button>
          </div>

          <div
            style={{
              borderBottom: "1px solid #CDD7EB",
              width: "100%",   
            }}
          ></div>
        </div>

        <div
          style={{
            width: "100%",
            height: "38rem",
            backgroundColor: "#ffff",
            padding: "20px",
            position: "relative",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            zIndex: 1001,
          }}
        >
          <div
            style={{
              background: "white",
              position: "relative",
              cursor: isDragging ? "grabbing" : "grab",
              overflow: "hidden",
              width: "100%",
              height: "100%",
            }}
            ref={containerRef}
            onClick={(e) => e.stopPropagation()}
            onWheel={handleZoom}
          >
       
              <img
                ref={imageRef}
                src={imageSrc}
                alt={`Image for CPMRN ${CPMRN}`}
                style={{
                  transform: `translate(${position.x}px, ${position.y}px) scale(${scale})`,
                  transformOrigin: "center center",
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              />
            </div>
          </div>
        </div>
    )
  );
};

export default ImageModal;
