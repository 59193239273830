import { configureStore } from "@reduxjs/toolkit";
import healthcheckReducer from "./reactSlice/healthcheckSlice";

const store = configureStore({
  reducer: {
    healthcheck: healthcheckReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
