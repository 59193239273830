import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { select, Store } from "@ngrx/store";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import * as fromUserIndex from "src/app/store/reducers/user/index";
import * as fromHospitalIndex from "src/app/store/reducers/hospitals/index";
import * as fromUnitIndex from "src/app/store/reducers/units/index";
import {
  UntypedFormBuilder,
  UntypedFormControl,
  Validators,
} from "@angular/forms";
import { ECallService } from "src/app/services/e-call.service";
import { AlertService } from "@iris/alert/services/alert.service";
import { MatDialogRef } from "@angular/material/dialog";
import { HospitalService } from "src/app/services/hospital.service";
import { HospitalList } from "src/app/models/hospital-list";
import { Hospital } from "src/app/models/hospital";
import {
  BedUnitInfoInterface,
  headerColor,
  headerColors,
} from "./bed-unit-info.model";

@Component({
  selector: "cp-bed-unit-info",
  templateUrl: "./bed-unit-info.component.html",
  styleUrls: ["./bed-unit-info.component.scss"],
})
export class BedUnitInfoComponent implements OnInit {
  @Input() buttonText: string = "Submit";
  @Input() headerColor: headerColor = headerColors.DEFAULT;
  @Input() hospitalLabel: string = "Select hospital";
  @Input() showBed: boolean = true;

  @Input() isTvView: boolean = false;

  public headerColors = headerColors;

  private unsubscribe$ = new Subject();

  @Output() onSubmit = new EventEmitter<BedUnitInfoInterface>();

  public hospitals$ = this.store.pipe(
    select(fromHospitalIndex.getHospitals),
    takeUntil(this.unsubscribe$)
  );

  public units$ = this.store.pipe(
    select(fromUnitIndex.getUnits),
    takeUntil(this.unsubscribe$)
  );

  public isCommandCenterUser$ = this.store.pipe(
    select(fromUserIndex.isCommandCenterUser),
    takeUntil(this.unsubscribe$)
  );
  bedDeviceId: string;

  get isContentLoaded() {
    return !this.isLoader;
  }

  get displayHospitalSelection() {
    return !this.form.get(this.FORM_CONTROL_NAME.hospitalName)?.value;
  }

  get displayUnitSelection() {
    return !this.displayHospitalSelection;
  }

  get displayBedSelection() {
    return this.unitBeds.length;
  }

  get hospitalName() {
    return this.form.get(
      this.FORM_CONTROL_NAME.hospitalName
    ) as UntypedFormControl;
  }

  get unitName() {
    return this.form.get(this.FORM_CONTROL_NAME.unitName) as UntypedFormControl;
  }

  get bedNo() {
    return this.form.get(this.FORM_CONTROL_NAME.bedNo) as UntypedFormControl;
  }

  public FORM_CONTROL_NAME: any = {
    hospitalName: "hospitalName",
    unitName: "unitName",
    bedNo: "bedNo",
  };

  public isLoader: boolean = true;
  public form;
  public hospitals: Hospital[] = [];
  private allUnits = [];
  showSearchHosp: boolean = true;
  showSelectUnit: boolean = true;
  public hospitalUnits = [];
  public unitBeds = [];
  private deviceId: string;
  private isCCUser: boolean = true;

  constructor(
    private store: Store<{}>,
    private fb: UntypedFormBuilder,
    private _hospitalService: HospitalService
  ) {}

  ngOnInit() {
    this.initializeForm();

    // listening to bed form control value changes to update the device id
    this.form.controls[this.FORM_CONTROL_NAME.bedNo].valueChanges
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((bed) => {
        this.bedDeviceId = bed?.deviceId;
      });

    this.isCommandCenterUser$.takeUntil(this.unsubscribe$).subscribe((flag) => {
      this.isCCUser = flag;
    });

    this.units$.takeUntil(this.unsubscribe$).subscribe((units) => {
      this.allUnits = units;
    });

    this.hospitals$.takeUntil(this.unsubscribe$).subscribe((hospData) => {
      if (!hospData?.length) return this.callHospitals();

      this.hospitals = hospData;
      this.isLoader = false;
    });
  }

  callHospitals() {
    this._hospitalService
      .getAllHospitals()
      .takeUntil(this.unsubscribe$)
      .subscribe((res: HospitalList) => {
        this.isLoader = false;
        if (!res?.count) return;

        this.hospitals = [...res.hospitals].sort((a, b) =>
          a.name < b.name ? -1 : 1
        );
      });
  }

  initializeForm() {
    this.form = this.fb.group({
      [this.FORM_CONTROL_NAME.hospitalName]: [null, [Validators.required]],
      [this.FORM_CONTROL_NAME.unitName]: [null, [Validators.required]],
      [this.FORM_CONTROL_NAME.bedNo]: [null],
    });
  }

  selectHospital(inputHospital) {
    const showAllUnits = this.isTvView;

    // Filter units based on device id
    const deviceIdFilter = (unit) => {
      if (showAllUnits) return true;
      return unit?.deviceId?.length || unit?.beds?.some((bed) => bed.deviceId);
    };

    const isActiveFilter = (unit) => {
      return unit?.active;
    };

    const filterByHospitalNameAndDevice = (unit) => {
      const hospitalNameMatch = unit?.hospitalInfo.name === inputHospital.name;
      const deviceFilterResult = deviceIdFilter(unit);
      const isUnitActive = isActiveFilter(unit);
      return hospitalNameMatch && deviceFilterResult && isUnitActive;
    };

    const getAllUnits = () => {
      return this.allUnits.filter(filterByHospitalNameAndDevice);
    };

    const getInputHospitalUnits = () => {
      return inputHospital?.units.filter(
        (unit) => deviceIdFilter(unit) && isActiveFilter(unit)
      );
    };

    this.hospitalName.setValue(inputHospital.name);

    this.hospitalUnits = this.allUnits?.length
      ? getAllUnits()
      : getInputHospitalUnits();
  }

  selectUnit(unitObj) {
    this.unitName.setValue(unitObj.name);
    if (!this.showBed) return;
    this.deviceId = unitObj?.deviceId[0];
    this.unitBeds = unitObj?.beds?.length
      ? unitObj.beds.filter((bed) => !!bed.deviceId)
      : [];
  }

  submit() {
    this.onSubmit.emit({
      deviceId: this.bedDeviceId ? this.bedDeviceId : this.deviceId,
      bedNo: this.bedNo?.value?.number,
      hospitalName: this.hospitalName?.value,
      unitName: this.unitName?.value,
    });
  }

  handleBack() {
    if (this.bedNo?.value) {
      this.onbedReset();
    } else if (this.unitName?.value) {
      this.unitName.setValue(null);
      this.onUnitReset();
    } else {
      this.hospitalName.setValue(null);
      this.onHospReset();
    }
  }

  onbedReset() {
    this.bedNo.reset(undefined, { emitEvent: false });
    this.bedDeviceId = null;
  }

  onUnitReset() {
    this.unitBeds = [];
    this.deviceId = null;
  }

  onHospReset() {
    this.hospitalUnits = [];
  }

  public get loaderDiameter(): number {
    return this.isTvView ? 50 : 20;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
