import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { Subject } from "rxjs-compat";
import { takeUntil } from "rxjs/operators";
import { Patient } from "src/app/models/patient";
import { GetHandoverNotes } from "src/app/handover/shared/models/getHandoverNotes.interface";
import { HandoverNotesService } from "src/app/handover/shared/services/handover-notes.service";
import { quillConfig } from "src/app/handover/shared/utils/quill-config";
import { HandoverViewComponent } from "../handover-view/handover-view.component";
import { AlertService } from "@iris/alert/services/alert.service";
import * as fromRoot from "src/app/reducers/index";
import * as fromUserReducer from "src/app/store/reducers/user";
import { select, Store } from "@ngrx/store";
import { State as User } from "src/app/store/reducers/user/user.reducers";
import {
  maxCharectors,
  validationText,
} from "../../../shared/utils/validation";

@Component({
  selector: "cp-note-popup",
  templateUrl: "./note-popup.component.html",
  styleUrls: ["./note-popup.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotePopupComponent implements OnInit, OnDestroy {
  private unsubscribe$: Subject<any> = new Subject<any>();
  public draftData: GetHandoverNotes | undefined;
  public handOverId: string = "";
  public isNoteSaving: boolean = false;
  public validationText: string = validationText;
  public isEditView: boolean = false;
  currentPatient: Patient;
  CPMRN: string;
  encounters: number;

  public users$ = this.store.pipe(
    select(fromUserReducer.getUser),
    takeUntil(this.unsubscribe$)
  );

  handOverControl: FormGroup = new FormGroup({
    note: new FormControl({ value: null, disabled: true }, [
      Validators.maxLength(maxCharectors),
    ]),
  });
  handoverNotes: GetHandoverNotes[] = [];
  user: User;
  isErrorMessage: boolean = false;

  get note(): FormControl {
    return this.handOverControl.get("note") as FormControl;
  }

  get latestHandover(): GetHandoverNotes | undefined {
    return this.handoverNotes[0];
  }

  @Input()
  set currPatient(currPatient: any) {
    if (currPatient && currPatient.CPMRN) {
      this.currentPatient = currPatient;
      this.CPMRN = this.currentPatient.CPMRN;
      this.encounters = this.currentPatient.encounters;
    }
  }
  constructor(
    private _handoverNotes: HandoverNotesService,
    public dialog: MatDialog,
    private _cdr: ChangeDetectorRef,
    private _alert: AlertService,
    public store: Store<fromRoot.AppState>
  ) {}

  @Output() close = new EventEmitter<null>();

  quillConfig = quillConfig;

  ngOnInit(): void {
    this.getDraftNotes();
    this.getHandoverNotes();
    this.initializeUser();
  }

  initializeUser() {
    this.users$.subscribe((user: User) => {
      this.user = user;
    });
  }

  getHandoverNotes() {
    this._handoverNotes
      .getHandoverNotes({
        cpmrn: this.CPMRN,
        encounter: this.encounters,
        handoverStatus: true,
        limit: 2,
      })
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (handoverData) => {
          this.handoverNotes = handoverData;
          this.setEditText();
          this._cdr.detectChanges();
        },
        (_) => {
          this.isErrorMessage = true;
        }
      );
  }

  setEditText() {
    this.handoverNotes.forEach((handover) => {
      if (handover.user_detail.email == this.user.currentUser.email) {
        this.isEditView = true;
      }
    });
  }

  moveToHandover(): void {
    if (!this.handOverId) return;
    this._handoverNotes
      .updateHandoverNotes([
        {
          noteId: this.handOverId,
          handoverStatus: true,
          note: this.note.value,
        },
      ])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (_) => {
          this._alert.showNotification({
            type: "Success",
            message: "The handover draft is moved to handover summary",
          });
          this.getHandoverNotes();
          this.clearNotes();
          this.handOverId = null;
        },
        (_) => {
          this.errorAlert();
        }
      );
  }

  errorAlert(): void {
    this._alert.showNotification({
      type: "Error",
      message: "Something went wrong!",
    });
  }

  openHandoverView(): void {
    const openDialog = this.dialog.open(HandoverViewComponent, {
      data: { handoverNotes: this.handoverNotes, user: this.user },
      width: "690px",
      autoFocus: false,
    });
    openDialog
      .afterClosed()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res) => {
        if (res) this.getHandoverNotes();
      });
  }

  clearNotes(isEmit = false) {
    this.note.setValue("", { emitEvent: isEmit });
  }

  getDraftNotes() {
    this._handoverNotes
      .getHandoverNotes({
        cpmrn: this.CPMRN,
        encounter: this.encounters,
        handoverStatus: false,
        limit: 1,
      })
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (draftData) => {
          this.note.enable();
          this.draftData = draftData[0];
          this.setNotes();
        },
        () => {
          this.isErrorMessage = true;
          this._cdr.detectChanges();
        }
      );
  }

  setNotes() {
    const isUserDraft =
      this.user?.currentUser?.email != this.draftData?.user_detail?.email;
    if (!this.draftData || isUserDraft) {
      this.note.setValue("", { emitEvent: false });
      return;
    }
    this.handOverId = this.draftData?._id;
    this.note.setValue(this.draftData?.note, { emitEvent: false });
  }

  public closePopup() {
    this.close.emit();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
