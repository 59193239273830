const fontFamilies = [
  { label: "Roboto", value: "Roboto, Arial" },

  // Add more font families as needed
];
export const quillConfig = {
  toolbar: {
    container: [
      ["bold", "italic", "underline", "strike"],
      [{ list: "ordered" }, { list: "bullet" }],
      [{ script: "sub" }, { script: "super" }],
      [{ color: [] }, { background: [] }],
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["link", "clean"],
      [{ font: fontFamilies }],
    ],
  },
  keyboard: {
    bindings: {
      tab: {
        key: 9,
        handler: function () {
          return true;
        },
      },
    },
  },
};
