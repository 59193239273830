import { TimezoneService } from "src/app/services/timezone.service";
import {
  Attributes,
  DocumentAttributes,
  DocumentViews,
  GroupedDocument,
  LabHeadData,
} from "../models/labs-scans";
import { formatTime, monthNames, trimObjectKeys } from "./utility";
import { Moment } from "moment-timezone";

// Class responsible for grouping the documents by date

export class DocumentGrouper {
  private readonly reportedAt: string;

  constructor(reportedAt: string, private _tz: TimezoneService) {
    this.reportedAt = reportedAt;
  }

  public groupByDate(
    array: DocumentViews[],
    attrName: string[]
  ): Record<string, GroupedDocument> {
    const groupedData: Record<string, GroupedDocument> = {};
    for (const obj of array) {
      const dateObj = this._tz.transformIntoTimezoneObj(obj[this.reportedAt]);
      const monthObj = `${monthNames[dateObj.month()]} ${dateObj.date()}`;

      if (!groupedData[monthObj]) {
        groupedData[monthObj] = {
          headInfo: [],
          attributes: [],
          labIds: [],
        };
      }

      groupedData[monthObj].headInfo.push(this.createHeadInfo(obj, dateObj));
      groupedData[monthObj].attributes.push(
        this.extractAttributes(obj, attrName)
      );
      groupedData[monthObj].labIds.push(obj._id);
    }
    return groupedData;
  }

  private createHeadInfo(obj: DocumentViews, dateObj: Moment): LabHeadData {
    let isunverified = !(obj?.verified !== undefined && obj?.verified !== null);
    return {
      time: formatTime(dateObj),
      isAdmission: obj.admission_lab,
      key: obj?.key,
      secondaryKeys: obj?.secondaryKeys,
      pacsLink: obj?.pacsLink,
      isUnVerified: isunverified,
    };
  }

  private extractAttributes(
    obj: DocumentViews,
    attrName: string[]
  ): Attributes {
    obj.attributes = trimObjectKeys(obj.attributes); // trim whitespace
    const attributes: Attributes = <Attributes>{};
    for (const name of attrName) {
      attributes[name] = !!obj.attributes[name]
        ? obj.attributes[name]
        : { value: "" };
    }
    return attributes;
  }
}
