import { Documents, DocumentViews } from "../models/labs-scans";
import { labelType } from "../../labs-scans/models/labs-scans";
import { Dictionary } from "@ngrx/entity";
import { DocumentGrouper } from "./documentGrouper";
import { Moment } from "moment-timezone";
import { TimezoneService } from "src/app/services/timezone.service";

export const monthNames = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

const leadingZero = (num: number) => `0${num}`.slice(-2);

export const formatTime = (date: Moment) =>
  [date.hours(), date.minutes()].map(leadingZero).join(":");

export const groupByDate = (
  reportedAt: string,
  timezoneService: TimezoneService
) => {
  return (array: DocumentViews[]) => (attrName: string[]) => {
    const documentGrouper = new DocumentGrouper(reportedAt, timezoneService);
    return documentGrouper.groupByDate(array, attrName);
  };
};

export const checkLabIfExists = (labelType: string) => (element: labelType) =>
  element.name == labelType;

export const resetMap = (classificationLabels: Map<string, labelType[]>) => {
  for (let values of classificationLabels.values()) {
    values.forEach((elm) => (elm.checked = false));
  }
};

export function initLabClassificationArray(
  document: Dictionary<Documents>,
  id: string | number,
  labClassificationMap: Map<string, labelType[]>
): typeof labClassificationMap {
  let isVerified =
    document[id]?.verified !== undefined && document[id]?.verified !== null;
  document[id]?.classifications?.map((classElm) => {
    if (labClassificationMap.has(classElm)) {
      const existingClass = labClassificationMap.get(classElm);
      const labIndex = existingClass.findIndex(
        checkLabIfExists(document[id].label)
      );
      if (labIndex === -1) {
        existingClass.push({
          name: document[id].label,
          checked: false,
          verified: isVerified,
        });
      } else if (!isVerified) {
        existingClass[labIndex].verified = isVerified;
      }
    } else {
      labClassificationMap.set(classElm, [
        { name: document[id].label, checked: false, verified: isVerified },
      ]);
    }
  });
  return labClassificationMap;
}

export function trimObjectKeys(obj) {
  return Object.keys(obj).reduce((trimmedObj, key) => {
    const trimmedKey = key.trim();
    trimmedObj[trimmedKey] = obj[key];
    return trimmedObj;
  }, {});
}
