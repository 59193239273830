<mat-expansion-panel class="cp-mat-expansion-panel">
  <mat-expansion-panel-header>
    <div class="user-view-cont w-100 display-flex justify-content-between">
      <div class="user-details display-flex align-items-center">
        <app-avatar
          [avatarParams]="[{ name: userData?.name, size: 'medium' }]"
        ></app-avatar>
        <div class="user-details--text display-flex flex-column">
          <div class="user-details--text--name cp-text-overflow-ellipses">
            {{ userData?.name }}
          </div>
          <div class="user-details--text--role">
            {{ userData?.assignedRole }}
          </div>
        </div>
      </div>
      <div class="patient-count display-flex align-items-center">
        <div class="patient-count--count">
          {{ userData?.patientCount || 0 }}
        </div>
        <div class="patient-count--label">patients</div>
      </div>
    </div>
  </mat-expansion-panel-header>

  <cp-user-hospital-bed-view
    [hospitalData]="userData?.hospitals"
  ></cp-user-hospital-bed-view>
</mat-expansion-panel>
