import {
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { DatePipe } from "@angular/common";
import {
  MatCalendar,
  MatDatepicker,
  MatDatepickerContent,
  MatDatepickerInputEvent,
} from "@angular/material/datepicker";
import {
  UntypedFormGroup,
  UntypedFormControl,
  ControlValueAccessor,
  UntypedFormBuilder,
  NG_VALUE_ACCESSOR,
  NG_VALIDATORS,
  NgModel,
} from "@angular/forms";
import { padStart } from "lodash-es";
import { Observable, Subject } from "rxjs";
import { Store, select } from "@ngrx/store";
import { getVitalClickedDay } from "src/app/vitals/store/reducers";
import { takeUntil } from "rxjs/operators";
import { DESIGN_TEMPLATES } from "../../constants";
import moment from "moment-timezone";
import { TimezoneService } from "src/app/services/timezone.service";
import { TimeZoneDetails } from "src/app/models/hospital";
import * as fromPatientHeaderReducers from "src/app/store/reducers/patient-chart/patient-header/index";
import { DateAdapter } from "@angular/material/core";
import { CustomDateAdapterServiceService } from "src/app/custom-date-adapter-service.service";

@Component({
  selector: "app-date-time-picker",
  templateUrl: "./date-time-picker.component.html",
  styleUrls: ["./date-time-picker.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DateTimePickerComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: DateTimePickerComponent,
      multi: true,
    },
    { provide: DateAdapter, useClass: CustomDateAdapterServiceService },
  ],
})
export class DateTimePickerComponent
  implements ControlValueAccessor, OnInit, OnDestroy
{
  private unsubscribe$: Subject<any> = new Subject<any>();

  /**
   * @description To observe the clicked day
   * @type {Observable}
   */
  public vitalClickedDay$ = this.store.pipe(
    select(getVitalClickedDay),
    takeUntil(this.unsubscribe$)
  );

  private patientHospitalTimeZone$ = this.store.pipe(
    select(fromPatientHeaderReducers.getPatientHospitalTimeZone),
    takeUntil(this.unsubscribe$)
  );

  date: string | moment.Moment | any;
  hour: string;
  minute: string;
  form: UntypedFormGroup;
  range: UntypedFormGroup;

  @ViewChild("datePickerRef") dateTimePickerRef: ElementRef;
  @ViewChild("dateField") dateField: NgModel;

  disabled: boolean = false;

  onChange: any = () => {};
  onTouched: any = () => {};

  /**TODO : To display single digit hour or minute padded with '0'. */
  displayHour;
  displayMinute;

  designTemplates = DESIGN_TEMPLATES;

  @Input() designTemplate: DESIGN_TEMPLATES = null;

  /**
   * @description input for patient timezone
   * this componet is called from patient-list ( vitals-batch ) for different patients
   * we can't rely on single patient store
   * If you are passing timezon in input else it will take from store for patient-file
   * @author Rajat Saini
   * @date Oct 05, 23
   */
  _timeZoneDetail: TimeZoneDetails;
  isCalendarDirty: boolean = false;

  @Input()
  set timeZoneDetail(timeZoneDetail) {
    this._timeZoneDetail = timeZoneDetail;
    if (this._timeZoneDetail?.name) this._tz.setTimezone(this._timeZoneDetail);
  }
  get timeZoneDetail() {
    return this._timeZoneDetail;
  }

  get timeZoneName() {
    return this.timeZoneDetail?.name;
  }

  @Input() isPadding: boolean = false;
  /** To set the default value of data / time */
  @Input() defaultValue: Date;

  /** To enable the date range option */
  @Input() dateRange: boolean;

  /** To show time alongside date */
  @Input() showTime: boolean;

  /** To show Now button which sets current Date and Time */
  @Input() showNowButton: boolean;

  /** The minimum valid date */
  @Input() minValidDate: string;

  /** The minimum valid date */
  @Input() enableValidation: boolean = false;

  /** The maximum valid date */
  @Input() maxValidDate: string;

  /** Date Label */
  @Input() dateLabel: string;

  /** To reset the date and time. */
  @Input() reset: Observable<any>;

  /** Time Label */
  @Input() timeLabel: string;

  // disabling date for ncp
  @Input() isNcp: boolean = false;

  /** Whenever date changes this event is emitted */
  @Output() dateChange: EventEmitter<any> = new EventEmitter<any>();

  /** Whenever start or end date is changed this gets emitted */
  @Output() dateRangeChange: EventEmitter<string> = new EventEmitter<string>();

  /** In case of change in hour or minute this event is emitted */
  @Output() timeChange: EventEmitter<string> = new EventEmitter<string>();

  private _tz = inject(TimezoneService);

  constructor(
    public datepipe: DatePipe,
    private fb: UntypedFormBuilder,
    private store: Store<any>
  ) {
    this.range = new UntypedFormGroup({
      start: new UntypedFormControl(),
      end: new UntypedFormControl(),
    });
  }

  validate() {
    if (this.dateRange || !this.dateField || !this.enableValidation)
      return null;
    if (this.date < this.minValidDate) return { minDateError: true };
    if (this.date > this.maxValidDate) return { furtureDate: true };

    if (moment(this.date) > moment(this.maxValidDate))
      return { furtureDate: true };
    if (moment(this.date) < moment(this.minValidDate))
      return { pastDate: true };
    return this.dateField.errors;
  }

  ngOnChanges(simpleChanges: SimpleChanges) {
    if (simpleChanges?.defaultValue?.currentValue) {
      this.setDateValue(new Date(simpleChanges.defaultValue.currentValue));
    }
    this.reset?.subscribe(() => {
      this.date = null;
      this.hour = null;
      this.minute = null;
      this.range.controls.start.setValue(null);
      this.range.controls.end.setValue(null);

      this.dateChange.emit(null);
      this.timeChange.emit(null);
      if (this.dateRange) this.dateRangeChange.emit();
    });
  }

  ngOnInit(): void {
    this.patientHospitalTimeZone$.subscribe((data) => {
      if (!data) return;
      this._timeZoneDetail = data;
    });
    if (this.isNcp) {
      this.disabled = true;
    } else {
      this.vitalClickedDay$.subscribe((day: any) => {
        const disableState = day?.vitalType === "Initial" ? true : false;
        this.setDisabledState(disableState);
      });
    }
  }

  onDateChange(event: MatDatepickerInputEvent<string>) {
    const date = this._tz
      .transformIntoTimezoneObj(event.value, this.timeZoneName)
      .startOf("day")
      .toISOString();
    this.dateChange.emit(date);
    this.setDateValue(date);
  }

  onDateRangeChange() {
    const dateRangeValue =
      this.range.value.start?.toLocaleString().substr(0, 10) +
      "-" +
      this.range.value.end?.toLocaleString().substr(0, 10);

    this.dateRangeChange.emit(dateRangeValue);

    this.onChange(dateRangeValue);
  }

  onHourChange() {
    this.hour = padStart(+this.hour % 24, 2, 0);
    this.minute = padStart(this.minute || 0, 2, 0);

    this.date = this.date || this._tz.getCurrentTimestampStr(this.timeZoneName);
    this.date = moment(this.date).hours(+this.hour).seconds(0).milliseconds(0);

    this.onChange(this.date);
    this.timeChange.emit(`${this.hour}:${this.minute}`);
  }

  onMinuteChange() {
    if (this.hour) {
      let hr = +this.hour;
      hr += Math.floor(+this.minute / 60);
      this.hour = padStart(hr % 24, 2, 0);
    }

    this.minute = padStart(+this.minute % 60, 2, 0);

    this.date = this.date || this._tz.getCurrentTimestampStr(this.timeZoneName);
    this.date = this._tz
      .transformIntoTimezoneObj(this.date, this.timeZoneName)
      .hours(+this.hour)
      .minutes(+this.minute)
      .seconds(0)
      .milliseconds(0);

    this.onChange(this.date);
    this.timeChange.emit(`${this.hour || 0}:${this.minute}`);
  }

  onNowClick() {
    const today = this._tz.getCurrentTimestampStr(this.timeZoneName);
    this.setDateValue(today);

    this.dateChange.emit(this.date);
    this.timeChange.emit(`${this.hour}:${this.minute}`);
  }

  checkValidDate(d: Date): boolean {
    if (Object.prototype.toString.call(d) === "[object Date]") {
      if (isNaN(d.getTime())) {
        // d.valueOf() could also work
        // date is not valid
        return false;
      } else {
        // date is valid
        return true;
      }
    } else {
      // not a date
      return false;
    }
  }

  // every time the form control is being updated from the parent
  writeValue(value: any): void {
    if (value) {
      this.setDateValue(value);
    } else {
      this.date = null;
      this.hour = null;
      this.minute = null;
    }
  }

  // when we want to let the parent know that the value of the form control should be updated
  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  // when we want to let the parent know that the form control has been touched
  registerOnTouched(onTouched: Function) {
    this.onTouched = onTouched;
  }

  // when the parent updates the state of the form control
  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

  // to set the value of date, hour and minute

  setDateValue(value) {
    if (!value) return this.resetForm();

    const transformedDateObj = this._tz.transformIntoTimezoneObj(
      value,
      this.timeZoneName
    );

    this.date = transformedDateObj.seconds(0).milliseconds(0);

    this.hour = padStart(transformedDateObj.hours(), 2, 0);
    this.minute = padStart(transformedDateObj.minutes(), 2, 0);

    this.onChange(this.date);
  }

  closedStream() {
    this.isCalendarDirty = false;
  }

  openedStrem() {
    this.isCalendarDirty = true;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  resetForm() {
    this.date = null;
    this.hour = null;
    this.minute = null;
    this.onChange(null);
  }
}
