import { EntityAdapter, createEntityAdapter } from "@ngrx/entity";
import { DashboardDataReducerState } from "../state";
import { WorkspaceInterface } from "../../model";
import { createReducer, on } from "@ngrx/store";
import * as fromDashboardDataActions from "../actions/dashboard-data.actions";

export const adapter: EntityAdapter<WorkspaceInterface> =
  createEntityAdapter<WorkspaceInterface>({
    selectId: selectDashboard,
    sortComparer: sortWorkspace,
  });

function selectDashboard(a: WorkspaceInterface) {
  return a.workspaceId;
}

function sortWorkspace(a: WorkspaceInterface, b: WorkspaceInterface) {
  return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
}

export const initialState: DashboardDataReducerState = adapter.getInitialState({
  patientCount: null,
  severity: null,
  workspaceCount: null,
  hospitalCount: null,
  unitCount: null,
  bedCount: null,
  physicianCount: null,
  specialistCount: null,
  ccaCount: null,
  ccrnCount: null,
  rnCount: null,
});

const _reducer = createReducer(
  initialState,
  on(
    fromDashboardDataActions.getDashboardDataSucc,
    (state, { dashboardInfo }) =>
      adapter.upsertMany(dashboardInfo?.workspaces, {
        ...state,
        ...dashboardInfo,
      })
  ),
  on(fromDashboardDataActions.updateWorkspaces, (state, { workspaces }) =>
    adapter.upsertMany(workspaces, state)
  ),
  on(
    fromDashboardDataActions.updateDashboardCounts,
    (state, { countsObj }) => ({
      ...state,
      ...countsObj,
    })
  )
);

export function reducer(state, action) {
  return _reducer(state, action);
}
