import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from "@angular/core";
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";
import { WorkspaceClinicianHospital } from "@shared-modules/features/shift-assign-dashboard/model";

@Component({
  selector: "cp-user-hospital-bed-view",
  templateUrl: "./user-hospital-bed-view.component.html",
  styleUrls: ["./user-hospital-bed-view.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserHospitalBedViewComponent implements OnInit {
  @Input() hospitalData: WorkspaceClinicianHospital[];

  constructor(
    private _matIconRegistry: MatIconRegistry,
    private _domSantizer: DomSanitizer
  ) {
    this._matIconRegistry.addSvgIcon(
      "cp-hospital-logo-1",
      this._domSantizer.bypassSecurityTrustResourceUrl(
        "assets/icons/hospital_logo_1.svg"
      )
    );
  }

  ngOnInit(): void {}
}
