import { Subject } from "rxjs";
import { UntypedFormGroup } from "@angular/forms";
import { filter, take, takeUntil } from "rxjs/operators";
import { select, Store } from "@ngrx/store";
import {
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  ViewChild,
} from "@angular/core";

import { LabOrderBE } from "../../../models/Lab.model";
import { MedOrderBE } from "../../../models/Med.model";
import { DietOrderBE } from "../../../models/Diet.model";
import * as fromOrder from "../../../store/reducers/order";
import { BloodOrderBE } from "../../../models/Blood.model";
import * as fromProtocol from "../../../protocol/reducers";
import { Orderable } from "../../../models/Orderable.model";
import { SiblingService } from "src/app/services/sibling.service";
import { Protocol } from "../../../models/protocol/Protocol.model";
import * as fromOrderable from "../../../store/reducers/orderable";
import { ProcedureOrderBE } from "../../../models/Procedure.model";
import { CommunicationOrderBE } from "../../../models/Communication.model";
import * as OrderActions from "src/app/store/actions/order/order-main.actions";
import { SearchOrder } from "../../../orders/components/search/search.component";
import {
  OrderPaneActions,
  OrderViewActions,
} from "../../../store/actions/order";
import { PlaceProtocolForm } from "../../../models/protocol/PlaceProtocolForm.model";
import { VentOrderService } from "../../../vital/services/vent-order.service";
import { MatDialog } from "@angular/material/dialog";
import { MatSelect } from "@angular/material/select";
import { SearchService } from "src/app/services/order-search.service";
import { DialogService } from "@iris/confim-dialog";
import { OrderFormService } from "src/app/services/order-form.service";
import { UserRolesMap } from "src/app/shared/accessControl/roleInterface";

const oldTypeToNewType = new Map([
  ["bloods", "blood"],
  ["communications", "comm"],
  ["diets", "diet"],
  ["labs", "lab"],
  ["medications", "med"],
  ["procedures", "procedure"],
  ["vents", "vents"],
]);

@Component({
  selector: "app-orders-input",
  templateUrl: "./orders-input.component.html",
  styleUrls: ["./orders-input.component.scss"],
})
export class OrdersInputComponent extends UserRolesMap implements OnInit {
  @Input() user;
  @Input() currentPatient?: any;
  private unsubscribe$: Subject<any> = new Subject<any>();
  public orderFormLoading$ = this.store.pipe(
    select(fromOrder.getOrderFormLoading)
  );
  public protocolFormLoading$ = this.protocolStore.pipe(
    select(fromProtocol.getProtocolFormLoading)
  );

  public getOrderablesLoading$ = this.orderableStore.pipe(
    select(fromOrderable.getOrderablesLoading)
  );

  public orderable: Orderable | null | any;
  public orderableType: string;
  public orderables: Orderable[] | null;
  public orderId: string | null;
  public orderCategory: string | null;
  public state = {};
  public form: UntypedFormGroup;
  public formType = "new";
  public orderInHospital = false;
  public presetName: string;
  public validationLoader: boolean = false;

  public protocol: Protocol | null;
  public inputSelected = null;
  public presetShortcut: string | null;
  public selectedBrand: string | null;
  public atcClass: string | null;
  public originalPresetName: string | null;
  public originalPresetShortcut: string | null;
  public originalBrandName: string | null;
  public prescribedOrders: any = null;

  /**
   * preset that is selected or order that is being edited.
   */
  public value: any;

  constructor(
    private _siblingService: SiblingService,
    private store: Store<fromOrder.State>,
    private orderableStore: Store<fromOrderable.State>,
    private protocolStore: Store<fromProtocol.State>,
    public dialogRef: MatDialog,
    private ventOrderService: VentOrderService,
    private _searchService: SearchService,
    private orderFormService: OrderFormService,
    private cdr: ChangeDetectorRef,
    private _dialogService: DialogService
  ) {
    super();
  }

  ngOnInit() {
    this._siblingService.notifyModifyOrder.subscribe((order) => {
      if (order) {
        this.modifyOrder(order);
      }
    });

    this.store
      .select(fromOrder.getInputTabActive)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((active) => {
        if (!active) {
          this.resetOrder();
          this.formType = "new";
        }
      });

    if (this.user && this.user.role) {
      const roleName = this.user.role.toLowerCase() || "nurse";
      if (
        [
          this.NURSE.toLocaleLowerCase(),
          this.PHARMACOLOGIST.toLocaleLowerCase(),
          this.DIETITIAN.toLocaleLowerCase(),
          this.CCN.toLocaleLowerCase(),
          this.CCA.toLocaleLowerCase(),
          this.PA.toLocaleLowerCase(),
        ].includes(roleName)
      ) {
        this.state[roleName] = true;
        this.state["submit"] = "Pend";
      } else {
        this.state["doctor"] = true;
        this.state["submit"] = "Sign";
      }

      this.state["role"] = this.user.role;
      this.state["name"] = this.user.name;
      this.state["title"] = this.user.title;
    }

    this.orderableStore
      .pipe(select(fromOrderable.getOrderableFormSuccess))
      .subscribe((bool) => {
        if (bool) {
          this.orderable = null;
          this.orderableType = null;
          this.value = null;
          this.store.dispatch(OrderPaneActions.clearSelectedOrderable());
        }
      });

    this.orderableStore
      .pipe(
        select(fromOrderable.getOrderableByType, {
          type: this.orderableType ? this.orderableType : null,
        })
      )
      .subscribe((orderables) => (this.orderables = orderables));

    this.orderableStore
      .pipe(select(fromOrderable.getSelectedOrderable))
      .subscribe((orderable) => {
        this.orderable = orderable;

        if (this.formType === "new") {
          if (this.orderable != undefined || this.orderable != null) {
            this.value = this.selectDefaultPreset(
              this.orderable,
              this.formType
            );
          }

          this.presetShortcut = this.value ? this.value._id : "-1";
          this.presetName = this.value?.presetName
            ? this.value.presetName
            : null;

          this.originalPresetName = this.presetName;
          this.originalPresetShortcut = this.presetShortcut;
        }
      });

    this.protocolStore
      .pipe(
        select(fromProtocol.getSelectedProtocol),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((protocol) => {
        this.protocol = protocol;
      });
  }

  modifyOrder(order: any) {
    const type = oldTypeToNewType.get(order.type);
    this.orderableType = type;
    this.orderInHospital = order.orderInHospital || false;
    this.orderId = order._id;
    this.orderCategory = order.category;
    this.formType = "edit";
    this.inputSelected = "orderable";
    this.presetName = order.preset;
    this.originalPresetName = this.presetName;
    this.selectedBrand = order.brandName;
    this.atcClass = order.atcClass;

    this.orderable = null;

    if (type === "vents") {
      this.value = this.ventOrderService.convertVentOrderToVentForm(order);
    } else {
      this.value = order;
    }

    this.store.dispatch(new OrderActions.OpenInputTab());
  }

  resetOrder() {
    this.orderable = null;
    this.orderableType = null;
    this.protocol = null;
    this.inputSelected = null;
    this.store.dispatch(OrderPaneActions.clearSelectedOrderable());
    this.store.dispatch(OrderPaneActions.clearSelectedProtocol());
    this.store.dispatch(new OrderActions.CloseInputTab());
  }

  open($event, cancel) {
    if ($event.type === "cancel" || $event === "cancel") {
      this.dialogRef.open(cancel, {
        width: "450px",
        autoFocus: false,
        disableClose: true,
      });
    } else {
      this.resetOrder();
    }
  }

  /**
   * Decides whether to show orderable name or orderable select.
   *
   * @returns {boolean}
   */
  showOrderableSelect(): boolean {
    return (
      this.orderables &&
      this.orderables.length > 0 &&
      this.orderableType &&
      this.orderableType !== "med" &&
      this.orderableType !== "lab" &&
      this.orderableType !== "comm" &&
      this.formType === "new"
    );
  }

  onSearch(value: SearchOrder): void {
    this.protocolStore.dispatch(OrderPaneActions.clearSelectedProtocol()); //clearing the protocol on order search
    if (value.type === "protocol") {
      this.handleProtocolSearch(value);
      return;
    }
    if (value.type != "med") {
      this.getOrderable(value);
      return;
    }
    this.handleMedSearch(value);
  }

  handleProtocolSearch(value: SearchOrder): void {
    this.inputSelected = "protocol";

    const protocolName = value.value.name;
    this.validationLoader = true;
    this._searchService
      .protocolExist(protocolName, {
        CPMRN: this.currentPatient.CPMRN,
        encounters: this.currentPatient.encounters,
      })
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (validationData) => {
          this.validateProtocol(validationData, value);
        },
        () => {
          this.validationLoader = false;
          this.getProtocol(value);
        }
      );
  }

  public validateProtocol(validationData, value: SearchOrder) {
    this.validationLoader = false;
    const isProtocol =
      validationData.data && Object.keys(validationData.data).length !== 0;
    this.prescribedOrders = isProtocol ? validationData.data : null;
    const message = validationData.message;
    if (!isProtocol) {
      this.getProtocol(value);
      return;
    }
    this.checkUserResponse(message)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((val) => {
        if (val) {
          this.getProtocol(value);
        }
      });
  }

  handleMedSearch(value: SearchOrder): void {
    const ordername = value.value.name;
    this.validationLoader = true;
    this._searchService
      .orderExist([ordername], {
        CPMRN: this.currentPatient.CPMRN,
        encounters: this.currentPatient.encounters,
      })
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (orderExists) => {
          this.validateOrder(orderExists, value);
        },
        () => {
          this.validationLoader = false;
          this.getOrderable(value);
        }
      );
  }

  public validateOrder(orderExists, value: SearchOrder) {
    const ordername = value?.value?.name;
    this.validationLoader = false;
    const message = orderExists[ordername]?.message;
    if (!orderExists[ordername]) {
      this.getOrderable(value);
      return;
    }
    this.checkUserResponse(message).subscribe((val) => {
      if (val) {
        this.getOrderable(value);
      }
    });
  }

  getProtocol(value: SearchOrder) {
    this.store.dispatch(
      OrderPaneActions.selectProtocol({ id: value.value._id })
    );
    this.store.dispatch(OrderPaneActions.getProtocol({ id: value.value._id }));
  }

  public getOrderable(value: SearchOrder): void {
    // Clear protocol if selection of order
    this.protocolStore.dispatch(OrderPaneActions.clearSelectedProtocol());
    this.inputSelected = "orderable";
    if (
      value.type === "diet" ||
      value.type === "blood" ||
      value.type === "procedure"
    ) {
      this.store.dispatch(OrderPaneActions.removeOrderables());
      this.store.dispatch(
        OrderPaneActions.updateOrderables({ orderableType: value.type })
      );
    }
    this.selectOrderable(value);
  }

  public checkUserResponse(message: string) {
    const alertMessage = `${message} Do you want to continue?`;
    return this._dialogService
      .openConfirmDialogue({
        message: alertMessage,
        alertType: "accept",
        headerText: "Attention",
        buttonText: "Continue",
      })
      .afterClosed();
  }

  onOrderableSelect(id: string): void {
    const orderableSelected = this.orderables.filter(
      (orderable) => orderable._id === id
    );
    this.value = null;
    this.selectedBrand = null;
    this.selectOrderable({
      type: orderableSelected[0].type,
      value: orderableSelected[0],
    });
  }

  selectOrderable(value: SearchOrder) {
    this.orderInHospital = false;
    this.formType = "new";
    this.orderableType = value.type;
    if (value.value?.selectedBrand != null) {
      this.selectedBrand = value.value.selectedBrand;
    } else {
      this.selectedBrand = null;
    }
    this.store.dispatch(
      OrderPaneActions.selectOrderable({ id: value.value._id })
    );

    this.store.dispatch(
      OrderPaneActions.getOrderable({
        id: value.value._id,
        brand: value?.value?.selectedBrand,
      })
    );
  }

  /**
   * Filters the presets by id and sets the selectedPreset.
   * And reinitialize the form.
   * @param {string} id
   */
  onPresetSelect(id: string): void {
    if (this.value?._id === id) return;

    if (id == "-1") {
      this.value = null;
      this.presetShortcut = null;
      this.presetName = null;
    } else {
      this.value = this.orderable.presets?.reduce((selectedPreset, preset) => {
        if (preset?._id === id) {
          selectedPreset = preset;
        }

        return selectedPreset;
      });

      this.value = { ...this.value };
      this.presetShortcut = this.value._id;
      this.presetName = this.value?.presetName ? this.value.presetName : null;
    }

    this.originalPresetName = this.presetName;
    this.originalPresetShortcut = this.presetShortcut;
  }

  selectDefaultPreset(orderable: Orderable | any, formType: string): void {
    if (orderable && orderable.presets && orderable.presets.length > 0) {
      return orderable.presets.reduce((selectedPreset, preset) => {
        if (preset.default) {
          selectedPreset = preset;
        }

        return selectedPreset;
      });
    }
  }

  onOrderTypeSelect(type: string): void {
    this.formType = "new";
    this.orderInHospital = false;
    this.inputSelected = "orderable";

    if (type === "vents") {
      this.value = null;
    }

    if (!this.orderableType || this.orderableType !== type) {
      this.orderable = null;

      this.store.dispatch(OrderPaneActions.clearSelectedOrderable());
      this.store.dispatch(
        OrderPaneActions.getOrderables({ orderableType: type })
      );
      this.orderableType = type;
    }
  }

  onSubmit(
    order:
      | BloodOrderBE
      | CommunicationOrderBE
      | DietOrderBE
      | LabOrderBE
      | MedOrderBE
      | ProcedureOrderBE
      | any
  ): void {
    if (this.formType === "new") {
      if (this.presetName == null) {
        order.brandName = null;
      }

      this.store.dispatch(
        OrderPaneActions.addOrder({
          order: {
            ...order,
            preset: this.presetName,
            brandName: this.selectedBrand,
            atcClass: this.orderable.atcClass,
          },
        })
      );
    } else if (this.formType === "edit" && !this.orderInHospital) {
      this.store.dispatch(
        OrderPaneActions.updateOrder({
          order: {
            ...order,
            _id: this.orderId,
            oldCategory: this.orderCategory,
            preset: this.presetName,
            brandName: this.selectedBrand,
            atcClass: this.atcClass,
          },
        })
      );
    } else if (this.formType === "edit" && this.orderInHospital) {
      this.store.dispatch(
        OrderPaneActions.addOrder({
          order: {
            ...order,
            _id: undefined,
            preset: this.presetName,
            brandName: this.selectedBrand,
            atcClass: this.atcClass,
          },
        })
      );
    }
  }

  onProtocolSubmit(value: PlaceProtocolForm) {
    this.store.dispatch(OrderPaneActions.placeProtocol({ value }));
    this.store.dispatch(
      OrderViewActions.getProtocolFile({ protocol: this.protocol.name })
    );
  }

  /*
   * NAME: checkIfPresetsContainShortcutKey
   * PURPOSE: check if presets contains displayAsShortcut key
   * DESCRIPTION:
   * PARAMS: presets - preset (med, lab, etc.)
   * RETURNS: boolean - true if it contains the key
   * USED BY: orders-input.component.html
   * CREATED DATE: 14 November 2019
   * AUTHOR: Gunjit Agrawal
   */
  checkIfPresetsContainShortcutKey(presets): boolean {
    for (const preset of presets) {
      if (preset.displayAsShortcut) {
        return true;
      }
    }

    return false;
  }

  /*
   * NAME: onFormChange
   * PURPOSE: checks whether any form input is changed
   * DESCRIPTION:  does not check for startTime and endTime fields
   * PARAMS: changed:boolean - if true then it is changed
   * RETURNS: void
   * USED BY: orders-input.component.html
   * CREATED DATE: 7 January
   * AUTHOR: Gunjit Agrawal
   */
  onFormChange(changed: boolean): void {
    if (changed) {
      this.presetShortcut = null;
      this.presetName = null;
    } else {
      this.presetShortcut = this.originalPresetShortcut;
      this.presetName = this.originalPresetName;
    }
  }

  isDisabled(e) {
    return e.scrollWidth <= e.clientWidth;
  }

  ngAfterContentChecked(): void {
    this.cdr.detectChanges();
  }
}
