// import { Socket } from 'ngx-socket-io';
import { Component, inject, OnInit } from "@angular/core";
import { AuthService } from "../services/auth.service";
import { Store, select } from "@ngrx/store";
import { ChangePasswordComponent } from "../change-password/change-password.component";
import { ChangeRoleComponent } from "../change-role/change-role.component";
import * as fromRoot from "src/app/reducers/index";
import { Router } from "@angular/router";
import Cookies from "js-cookie";
import * as userActions from "src/app/store/actions/user.actions";
import * as rootActions from "src/app/store/actions/root.actions";
import { getCurrUser, getCurrUserStatus } from "src/app/store/reducers/user";

import * as fromRAlert from "../ralert/reducers";
import * as fromTvUnit from "src/app/store/reducers/tv-unit-view";
import { environment } from "src/environments/environment";
import {
  faBriefcaseMedical,
  faQuestionCircle,
} from "@fortawesome/free-solid-svg-icons";
import { socket } from "../config/socket";
import { AuthLocalStorageService } from "../get-started/services/auth-localStorage.service";
import {
  AvatarParams,
  AVATAR_SIZE,
} from "@iris/avatar/components/avatar/avatar.component";
import { MatDialog } from "@angular/material/dialog";
import { AlertService } from "@iris/alert/services/alert.service";
import { Alert } from "@iris/alert/models/alert.model";
import { take } from "rxjs/operators";
import { WikiService } from "../services/wiki.service";
import { PinInfo } from "../models/patient";
import { PatientService } from "../services/patient.service";
import { UserRolesMap } from "../shared/accessControl/roleInterface";
import { ECallPopupComponent } from "@shared-modules/components/e-call-popup";

@Component({
  selector: "app-cp-header",
  templateUrl: "./cp-header.component.html",
  styleUrls: ["./cp-header.component.scss"],
})
export class CpHeaderComponent extends UserRolesMap implements OnInit {
  public currUser$ = this.store.pipe(select(getCurrUser));
  public currUserStatus$ = this.store.pipe(select(getCurrUserStatus));
  public user;
  public avatarParams: AvatarParams[];

  public ralertMessagesCount$ = this.rAlertStore.pipe(
    select(fromRAlert.getRAlertMessagesTotal)
  );

  /* ICONS */
  faBriefcaseMedical = faBriefcaseMedical;
  faQuestionCircle = faQuestionCircle;
  env = environment;
  showLoader = false;

  public ECallPopupContext: any = {
    ECall: "eCall",
    CodeBlue: "CodeBlue",
  };

  tvUnitName$ = this._tvStore.select(fromTvUnit.getTVUnitName);

  constructor(
    public _auth: AuthService,
    private store: Store<fromRoot.AppState>,
    private rAlertStore: Store<fromRAlert.State>,
    private _router: Router,
    private _alertService: AlertService,
    private _authLocalStorageService: AuthLocalStorageService,
    private dialog: MatDialog,
    private _tvStore: Store<fromTvUnit.State>,
    private _wikiService$: WikiService,
    public _patientService: PatientService
  ) {
    super();
  }

  ngOnInit() {
    // setup the store from local storage
    this.setUpUser();

    // Listen to the user changes
    this.currUser$.subscribe((data) => {
      if (data?.email) {
        this.user = data;
        localStorage.setItem("currentUser", JSON.stringify(data));
      }
    });

    socket?.on("tv:usersOnCall", (user) => {
      if (user.email === this.user?.email) {
        this.user.onCallStatus = user.onCallStatus;
        this.store.dispatch(
          userActions.updateUserStore({
            user: this.user,
          })
        );
      }
    });
  }

  resetTvUnit(): void {
    this._router.navigate(["tv", "unit"]);
  }

  setUpUser() {
    let user = JSON.parse(localStorage.getItem("currentUser"));

    if (user?.email) this.store.dispatch(userActions.setUser({ user }));

    this.avatarParams = [
      {
        name: user?.name,
        size: AVATAR_SIZE.M,
      },
    ];
  }

  openChangePwModal() {
    const dialogRef = this.dialog.open(ChangePasswordComponent, {
      autoFocus: false,
      restoreFocus: false,
    });
  }

  openChangeRoleModal() {
    const dialogRef = this.dialog.open(ChangeRoleComponent, {
      autoFocus: false,
      restoreFocus: false,
    });
  }

  logoutUser() {
    const logoutFn = () => {
      this.store.dispatch(rootActions.logout());
      this._authLocalStorageService.clearLocalStorage();
    };
    if (this.user.isFamilyUser) {
      logoutFn();
      return;
    }
    const socketID = socket?.id;
    // update pinned fields
    const CPMRNArray: PinInfo[] = JSON.parse(
      localStorage.getItem("pinCPMRNFields")
    );

    const CPMRNPayload = CPMRNArray?.map(({ unit, ...CPMRN }) => CPMRN);

    if (CPMRNPayload?.length)
      this._patientService.submitUserPinnedPatientList(CPMRNPayload).subscribe(
        (_) => {
          localStorage.removeItem("pinCPMRNFields");
        },
        (err) => {
          console.log(err);
        }
      );
    this._auth.logoutUser(socketID).subscribe(
      (_) => {
        logoutFn();
      },
      (err) => {
        console.log(err);
      }
    );
  }

  async openRadarHelp() {
    this.showLoader = true;

    const getJwt = Cookies.get("jwt", {
      domain: "." + this.env.wikiDomain.split(".").slice(1).join("."),
    });

    if (getJwt) {
      const response = await this._wikiService$
        .getLandingPages()
        .pipe(take(1))
        .toPromise();

      this.showLoader = false;

      if (response.data) {
        window.open(response.data);
      } else {
        // window.open("http://" + this.env?.wikiDomain + "/");

        const displayMessage: Alert = {
          type: "Info",
          message:
            "Wiki content not present for the current role, Please contact admin",
        };

        this._alertService.showNotification(displayMessage);
      }
    } else {
      this.showLoader = false;

      const displayMessage: Alert = {
        type: "Info",
        message:
          "Wiki not enabled for the current environment, Please contact admin",
      };

      this._alertService.showNotification(displayMessage);
    }
  }

  /*
   * NAME: onMainLogoClick
   * PURPOSE: routes the user basis on user role
   * DESCRIPTION:
   * PARAMS:
   * RETURNS:
   * USED BY: template
   * CREATED DATE: 31/08/20
   * AUTHOR: Gunjit Agrawal
   */
  onMainLogoClick(): void {
    // was throwing some error - TypeError: Cannot read property 'role' of undefined

    const roles = [
      "System Administrator",
      "Medical Administrator",
      "Billing Administrator",
      "Tv",
      "Auditor",
      "Tv-Covid",
      "R-Alert",
    ];

    if (!this.user) {
      // redirecting to login page
      this.store.dispatch(rootActions.logout());
      this._authLocalStorageService.clearLocalStorage();
    }
    if (this.user.isFamilyUser) return;
    if (this.user.role === "R-Alert") {
      this._router.navigate(["/r-alert"]);
    } else if (!roles.includes(this.user.role)) {
      this._router.navigate(["/patientlist"]);
    }
  }

  onStatusChange(onCall: boolean) {
    if (this.user?.onCallStatus?.onCall === onCall) return;

    const currentDate = new Date();
    currentDate.setHours(currentDate.getHours() + 5);
    currentDate.setMinutes(currentDate.getMinutes() + 30);

    this.user.onCallStatus = {
      onCall: onCall,
      lastTimeOnCall: onCall
        ? currentDate
        : this.user?.onCallStatus?.lastTimeOnCall,
    };

    this.store.dispatch(
      userActions.updateOnCallStatus({
        user: this.user,
      })
    );
  }

  openEcallPopup(popupContext: string) {
    const contextName = popupContext;
    const popupDialog = this.dialog.open(ECallPopupComponent, {
      width: "660px",
      autoFocus: false,
      disableClose: true,
    });

    const componentInstance = popupDialog.componentInstance;
    componentInstance.contextName = contextName;
  }
}
