// import { Socket } from 'ngx-socket-io';
import { select, Store } from "@ngrx/store";
import { ActivatedRoute } from "@angular/router";
import { Component, OnDestroy, OnInit } from "@angular/core";
import { filter, map, take } from "rxjs/operators";
import { takeUntil } from "rxjs/operators";

import * as fromPatientMonitor from "../../reducers";
import {
  PatientMonitor,
  PatientMonitorForm,
  PatientMonitorHospital,
  PatientMonitorStatus,
} from "../../../models/patientMonitor/patientMonitor.model";
import { PatientMonitorListPageActions } from "../../actions";
import { socket } from "../../../config/socket";
import { MatDialog } from "@angular/material/dialog";
import { DialogService } from "@iris/confim-dialog/services/dialog.service";
import { OrderableRequestAcceptRequest } from "src/app/models/orderable/OrderableRequest.model";
import { Subject } from "rxjs";

@Component({
  selector: "app-patient-monitor-list-page",
  templateUrl: "./patient-monitor-list-page.component.html",
  styleUrls: ["./patient-monitor-list-page.component.scss"],
})
export class PatientMonitorListPageComponent implements OnInit, OnDestroy {
  public monitors$ = this._store.pipe(
    select(fromPatientMonitor.getAllPatientMonitors)
  );
  public editedPatientMonitor: PatientMonitor;
  public hospitals: PatientMonitorHospital[] = [];

  public ngDestroyed$ = new Subject();

  constructor(
    private _store: Store<fromPatientMonitor.State>,
    private _modalService: MatDialog,
    private _route: ActivatedRoute,
    private _dialog: DialogService
  ) {}

  ngOnInit() {
    this._store.dispatch(PatientMonitorListPageActions.getPatientMonitors());

    this._route.data
      .pipe(
        map((data: { hospitals: PatientMonitorHospital[] }) => data.hospitals)
      )
      .subscribe((hospitals: PatientMonitorHospital[]) => {
        this.hospitals = hospitals
          ? hospitals.filter(
              (hospital) => hospital.units && hospital.units.length > 0
            )
          : [];
      });

    this.monitors$
      .pipe(
        filter((monitors) => monitors.length > 0),
        take(1)
      )
      .subscribe((monitors) => {
        if (monitors && monitors.length > 0) {
          this._store.dispatch(
            PatientMonitorListPageActions.getAllPatientMonitorStatus({
              monitors: monitors.map((monitor) => ({
                deviceId: monitor.deviceId,
              })),
            })
          );
        }
      });

    socket.on("patientMonitor:status", (data: PatientMonitorStatus) => {
      this._store
        .pipe(
          select(fromPatientMonitor.getPatientMonitorByDeviceId, data.deviceId),
          take(1)
        )
        .subscribe((monitor) => {
          this._store.dispatch(
            PatientMonitorListPageActions.setPatientMonitorStatus({
              monitor,
              online: data.online,
            })
          );
        });
    });
  }

  /*
   * NAME: openModal
   * PURPOSE: opens a modal
   * DESCRIPTION:
   * PARAMS: modal - modal that will open
   * RETURNS: void
   * USED BY: onEdit(), template
   * CREATED DATE: 22/07/20
   * AUTHOR: Gunjit Agrawal
   */
  openModal(modal): void {
    this._modalService.open(modal, {
      panelClass: "dark-modal user-edit-modal",
    });
  }

  /*
   * NAME: onSubmit
   * PURPOSE: submits the patient monitor form
   * DESCRIPTION:
   *   if form is of new type then 'submitNewPatientMonitorForm' action is called with data.
   *   if form is of edit type then 'submitEditPatientMonitorForm' action is called with data and monitor id.
   * PARAMS: data:PatientMonitorForm
   * RETURNS: void
   * USED BY: template
   * CREATED DATE: 22/07/20
   * AUTHOR: Gunjit Agrawal
   */
  onSubmit(data: PatientMonitorForm): void {
    if (!this.editedPatientMonitor) {
      this._store.dispatch(
        PatientMonitorListPageActions.submitNewPatientMonitorForm({ data })
      );
    } else {
      this._store.dispatch(
        PatientMonitorListPageActions.submitEditPatientMonitorForm({
          data,
          id: this.editedPatientMonitor._id,
        })
      );
    }
  }

  /*
   * NAME: onEdit
   * PURPOSE: opens edit form with data.
   * DESCRIPTION:
   *   assigns monitor to 'editedPatientMonitor' variable
   *   opens a modal which is passed to view the form.
   * PARAMS:
   *   monitor:PatientMonitor
   *   modal   -   ng modal which will be used to view the data.
   * RETURNS: void
   * USED BY: template
   * CREATED DATE: 22/07/20
   * AUTHOR: Gunjit Agrawal
   */
  onEdit(monitor: PatientMonitor, modal): void {
    this.editedPatientMonitor = monitor;

    this.openModal(modal);
  }

  /*
   * NAME: onDelete
   * PURPOSE: deletes a monitor by the mongo id
   * DESCRIPTION:
   *   opens up a confirm delete modal.
   *   on confirming 'clickDeletePatientMonitor' action is fired which
   * PARAMS: id:string - mongo id of the monitor to be deleted.
   * RETURNS: void
   * USED BY: template
   * CREATED DATE: 22/07/20
   * AUTHOR: Gunjit Agrawal
   */
  onDelete(id: string): void {
    this._dialog
      .openConfirmDialogue({
        message: "Monitor will still be attached to patient after deleting.",
        alertType: "reject",
        headerText: "Delete Monitor",
      })
      .afterClosed()
      .pipe(takeUntil(this.ngDestroyed$))
      .subscribe((res) => {
        if (res) {
          this._store.dispatch(
            PatientMonitorListPageActions.clickDeletePatientMonitor({ id })
          );
        }
      });
  }

  ngOnDestroy(): void {
    this.ngDestroyed$.next();
    this.ngDestroyed$.complete();
  }
}
