import { Injectable } from "@angular/core";
import { Actions, ofType, Effect } from "@ngrx/effects";
import { of } from "rxjs";
import { catchError, map, switchMap } from "rxjs/operators";
import * as fromNoteTypeActions from "../actions";
import { NoteService } from "@notes/services/note.service";
import * as fromloaderStateReducer from "@shared-modules/store";
import { Store } from "@ngrx/store";
import { AlertService } from "@iris/alert";

@Injectable()
export class NoteTypesEffects {
  constructor(
    private actions$: Actions,
    private _alertService: AlertService,
    private _noteService: NoteService,
    private _loaderStore: Store<fromloaderStateReducer.ShareState>
  ) {}

  @Effect()
  noteTypesData$ = this.actions$.pipe(
    ofType(fromNoteTypeActions.loadNoteTypes),
    switchMap(({ notetypeCriteria }) => {
      return this._noteService.getNoteTypes(notetypeCriteria).pipe(
        map((data) => {
          this._loaderStore.dispatch(
            fromloaderStateReducer.setLoadingSpinner({ status: false })
          );
          return fromNoteTypeActions.loadNoteTypeSuccess({
            noteContents: data,
          });
        }),
        catchError((error) => {
          this._loaderStore.dispatch(
            fromloaderStateReducer.setLoadingSpinner({ status: false })
          );
          this._alertService.showNotification({
            type: "Error",
            message: error.error.message,
          });
          return of([]);
        })
      );
    })
  );
}
