import { Injectable } from "@angular/core";
import { ChartData } from "../donut-chart.modal";

@Injectable({
  providedIn: "root",
})
export class DataTransformService {
  transformData(data: { [key: string]: number }): ChartData[] {
    return Object.keys(data).map((label) => ({
      label,
      value: data[label],
    }));
  }
}
