<div class="user-hospital-bed-view-container display-flex flex-column">
  <div
    class="info-container display-flex flex-column"
    *ngFor="let hospInfo of hospitalData; index as i"
  >
    <div class="hospital-header display-flex">
      <mat-icon svgIcon="cp-hospital-logo-1"></mat-icon>
      <div class="hospital-header--name">{{ hospInfo?.name }}</div>
    </div>
    <div class="unit-body display-flex flex-column">
      <div
        class="unit-info display-flex"
        *ngFor="let unitObj of hospInfo?.units; index as j"
      >
        <div class="unit-info--header display-flex">
          <span class="unit-info--header--name">{{ unitObj?.name }}</span>
          :
          <span class="unit-info--header--count">{{
            unitObj?.patientCount
          }}</span>
        </div>
        <div class="unit-info--beds display-flex" *ngIf="unitObj?.beds?.length">
          (
          {{ unitObj?.beds?.join(", ") }}
          )
        </div>
      </div>
    </div>
  </div>
</div>
