import { Component, Inject, Input, OnInit } from "@angular/core";
import {
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { select, Store } from "@ngrx/store";
import { combineLatest, Observable } from "rxjs";
import { of } from "rxjs";
import { Subject } from "rxjs-compat";
import { debounceTime, switchMap, take, takeUntil } from "rxjs/operators";
import { AiraNote } from "src/app/aira/model/AiraNote.model";
import { AiraNoteService } from "src/app/aira/services/aira-note.serivce";
import {
  Notes,
  Content,
  NotesDraft,
  NoteForm,
  NoteType,
  Intensivist,
  Cosign,
  Addendum,
} from "../model/notes.model";
import { NotesService } from "../services/notes.service";
import * as notesTemplate from "./note-templates/template";
import { flatten } from "lodash-es";
import {
  attestRequired,
  signPermission,
  header,
  ImpactNotes,
} from "../../notes-input-modules/utils/permission.data";

//store
import * as fromStore from "../store";
import * as template from "./note-templates/note.forms";
import { RequestAttestComponent } from "./request-attest/request-attest.component";
import {
  noteSubTypeMap,
  noteTypeList,
  updateNoteSubTypeMapNeonatal,
} from "./note-templates/note-type-access";
import { User } from "src/app/models/user";
import { AsyncPipe } from "@angular/common";

import * as fromPatientHeaderReducers from "src/app/store/reducers/patient-chart/patient-header/index";
import { TimeZoneDetails } from "src/app/models/hospital";
import { NoteServices, Services } from "src/app/models/patient";
import { allowedHospitals } from "../utils/notes.util";

// Add more font families as needed
@Component({
  selector: "app-note-input",
  templateUrl: "./note-input.component.html",
  styleUrls: ["./note-input.component.scss"],
  providers: [AsyncPipe],
})
export class NoteInputComponent implements OnInit {
  patientNotesForm: UntypedFormGroup;
  formNoteAddendum: UntypedFormGroup;
  public signPermission = signPermission;
  public attestRequired = attestRequired;
  public header = header;
  private unsubscribe$: Subject<any> = new Subject<any>();
  showAddenum$: Observable<boolean> = this._noteService.showAddednumNote$.pipe(
    takeUntil(this.unsubscribe$)
  );

  loading$: Observable<boolean> = this.store
    .select(fromStore.loadingStatus)
    .pipe(takeUntil(this.unsubscribe$));
  public isAddendum = false;

  public patientData$ = this.patientStore.pipe(
    select(fromPatientHeaderReducers.getPatHeaderData)
  );

  public templateData: {
    summaryTemplate: string;
    diagnosisTemplate: string;
    secondaryTemplate: string;
    tertiaryTemplate: string;
  };

  public currentUser: User = this._noteService.user;

  /**
   * Form declaration
   */

  private noteSubTypeMap = new Map(noteSubTypeMap);
  private noteTypeList = noteTypeList;
  private updateNoteSubTypeMapNeonatal = updateNoteSubTypeMapNeonatal;
  public CPMRN: string;
  public encounters: number;
  public hospitalID: string;
  public commandCenterID: string;
  submitNote: Notes;
  public newDraftData: Notes;
  lastUpdatedAt: Date;

  attestationStatusMsg = new UntypedFormControl(null);

  attestionStatus: "pending" | "optional" | "rejected" | "accepted";
  patientType: "adult" | "neonatal" | "pediatric";
  hospitatalTimzoneDetails: TimeZoneDetails;
  isHospitalNoteChargeable: boolean;

  @Input()
  set currPatient(currPatient: any) {
    if (currPatient && currPatient["CPMRN"]) {
      this.CPMRN = currPatient.CPMRN;
      this.encounters = currPatient.encounters;
      this.hospitalID = currPatient.hospitalID;
      this.commandCenterID = currPatient.hospitalInfo.commandCenterID;
      this.hospitatalTimzoneDetails = currPatient.hospitalInfo.timeZoneDetail;
      this.isHospitalNoteChargeable =
        currPatient.hospitalInfo?.noteFeatures?.includes(
          NoteServices.CHARGEABLE
        );
    }
  }

  get ERROR_MSG() {
    if (
      this.patientNotesForm.controls["formNoteSummary"].touched ||
      this.patientNotesForm.controls["formNoteDiagnosis"].touched ||
      this.patientNotesForm.controls["formNoteToDoList"].touched ||
      this.patientNotesForm.controls["formNoteActionList"].touched
    ) {
      switch (
        this.patientNotesForm.controls.noteTypeForm.get("formNoteType").value
      ) {
        case "Nursing": {
          return "Nursing note is blank";
        }
        case "Procedure": {
          return "Procedure note is blank";
        }
        case "Consult note": {
          return "Consult note is blank";
        }
        default: {
          return "Summary note is blank";
        }
      }
    }
  }

  get showImpactCases() {
    return (
      Object.values(ImpactNotes).includes(
        this.patientNotesForm.controls.noteTypeForm.get("formNoteType").value
      ) && allowedHospitals.includes(this.currentUser?.hospitals[0]?.name)
    );
  }

  constructor(
    _fb: UntypedFormBuilder,
    private patientStore: Store<{}>,
    private store: Store<fromStore.NoteState>,
    private _airaNoteService: AiraNoteService,
    public dialog: MatDialog,
    private _noteService: NotesService,
    private asyncPipe: AsyncPipe
  ) {
    this.patientNotesForm = _fb.group({
      noteTypeForm: _fb.group({
        formNoteType: [
          "Select Note Type",
          [
            Validators.pattern(
              /Admission|Nutrition|Event|Rounding note|Nursing|Pharmacology|Tele critical care review|Progress|Procedure|ICU to ward transfer|Consult note|Intensivist review|Death summary|Physiotherapy note|Shift handover note|Respiratory therapy note|Discharge/
            ),
            Validators.required,
          ],
        ],
        formNoteSubType: [
          "MICU Template",
          Validators.pattern(
            /MICU Template|NICU Template|NICU progress note L1|NICU progress note L2|NICU progress note L3|MICU 2 Template|MICU with meds|HDU Medical|HDU Post-Op|HDU Cardiac|COVID-19|Nursing Short Note|Nursing Assessment Note/
          ),
        ],
      }),
      lock: [null],
      impactCase: [null],
      chargeable: [null],
      noteReferenceId: ["new"],
      isDeleteEnabled: [null],
      formNoteSummary: [null],
      formNoteActionList: [null],
      formNoteToDoList: [null],
      formNoteDiagnosis: [null],
      formNoteMedication: [null],
      formNoteReports: [null],
      formNotePendingReports: [null],
      formNoteAddendumArray: _fb.array([]),
      formNoteAddendum: _fb.group({
        name: [null],
        note: [null],
      }),
    });

    // Updating the note subtype field based on the patient type
    this.patientData$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((patient) => {
        this.patientType = patient.patientType;
        if (this.patientType == "neonatal")
          this.noteSubTypeMap = new Map(
            this.updateNoteSubTypeMapNeonatal(noteSubTypeMap)
          );
      });
  }

  get noteType(): string[] {
    return this.noteTypeList?.get(this.currentUser.role);
  }

  get noteSubType(): string[] {
    return this.noteSubTypeMap.get(
      this.patientNotesForm?.controls["noteTypeForm"]?.get("formNoteType").value
    );
  }

  get editor() {
    return (
      this.patientNotesForm.controls["noteTypeForm"].get("formNoteType")
        .value !== "Select Note Type"
    );
  }

  get checkProgressOrEventNote() {
    const formNoteType =
      this.patientNotesForm.controls["noteTypeForm"].get("formNoteType").value;
    return (
      formNoteType === "Progress" ||
      formNoteType === "Event" ||
      formNoteType == "Consult note"
    );
  }

  get editorClass() {
    return (
      ![
        "Admission",
        "Progress",
        "Procedure",
        "Death summary",
        "Nutrition",
        "ICU to ward transfer",
        "Consult note",
        "Intensivist review",
        "Tele critical care review",
      ].includes(
        this.patientNotesForm.controls["noteTypeForm"].get("formNoteType").value
      ) &&
      !(
        this.patientNotesForm.controls["noteTypeForm"].get("formNoteType")
          .value == "Discharge" &&
        this.patientNotesForm.controls["noteTypeForm"].get("formNoteSubType")
          .value == "NICU Template"
      )
    );
  }

  ngOnInit(): void {
    this.patientNotesForm.controls.noteTypeForm.valueChanges
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((data) => {
        let noteType = data.formNoteType;
        let noteSubType = data.formNoteSubType;
        let templateData = (this.templateData = template.loadTemplate(
          noteType,
          noteSubType
        ));
        this.patientNotesForm.controls["formNoteSummary"].setValue(
          templateData.summaryTemplate
        );
        this.patientNotesForm.controls["formNoteDiagnosis"].setValue(
          templateData.diagnosisTemplate
        );
        this.patientNotesForm.controls["formNoteActionList"].setValue(
          templateData.secondaryTemplate
        );
        this.patientNotesForm.controls["formNoteMedication"].setValue(
          templateData.medicationTemplate
        );
        this.patientNotesForm.controls["formNoteReports"].setValue(
          templateData.reportTemplate
        );
        this.patientNotesForm.controls["formNoteToDoList"].setValue(
          templateData.tertiaryTemplate
        );
      });

    // Autosave to draft notes
    this.patientNotesForm.valueChanges
      .pipe(
        debounceTime(4000),
        switchMap((formValues) => this.autoSave(formValues)),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((saveDraftData) => {
        const STATUS = this.patientNotesForm.valid;
        if (STATUS) {
          this.store.dispatch(
            new fromStore.NoteAutoSave({
              CPMRN: this.CPMRN,
              encounters: this.encounters,
              draftNotes: saveDraftData,
            })
          );
          this.lastUpdatedAt = new Date();
        }
      });

    this._noteService.popUpCloseTrigger$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((autosave) => {
        if (autosave) {
          this.autoSave(this.patientNotesForm.value)
            .toPromise()
            .then((saveDraftData) => {
              const STATUS = this.patientNotesForm.valid;
              if (STATUS) {
                this.store.dispatch(
                  new fromStore.NoteAutoSave({
                    CPMRN: this.CPMRN,
                    encounters: this.encounters,
                    draftNotes: saveDraftData,
                  })
                );
                this.lastUpdatedAt = new Date();
              }
            });
        }
        // this._noteService.cosignNote(false);
      });
    this._airaNoteService.airaNote$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((data) => {
        if (data) {
          this.setAiraData(data);
        }
      });

    //Augnito
    let latestIndex; // set the cursor index variable
    this.augnitoText$.subscribe((data) => {
      if (data) {
        const selection = this.cursorIndex.event.editor.getSelection(true);
        this.cursorIndex.event.editor.insertText(selection.index, data);
        setTimeout(() => {
          latestIndex = this.cursorIndex.event.editor.getSelection(true);
          let form = this.cursorIndex.form;
          this.updateNoteInputFormsWithSwaraData(form);
          this.cursorIndex.event.editor.setSelection(latestIndex.index, 0);
        }, 0);
      }
    });

    // Draft note for new note
    this.store
      .select(fromStore.getAllDraftNotes)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((data): void => {
        if (data.length) {
          this.newDraftData = data.filter(
            (note) => note.content["refId"] === "new"
          )[0];
        }
      });
  }

  updateNoteInputFormsWithSwaraData(form: string) {
    if (typeof form === "number") {
      const addendumArray = this.patientNotesForm.controls[
        "formNoteAddendumArray"
      ] as UntypedFormArray;
      addendumArray.controls[form]
        .get("note")
        .setValue(this.contentChangeNotes);
    } else if (form === "formNoteAddendum") {
      this.patientNotesForm.controls["formNoteAddendum"]
        .get("note")
        .setValue(this.contentChangeNotes);
    } else if (form === "attestationStatusMsg") {
      this.attestationStatusMsg.setValue(this.contentChangeNotes);
    } else {
      this.patientNotesForm.controls[form].setValue(this.contentChangeNotes);
    }
  }

  /**
   * Quill configuration
   */
  quillConfig = {
    toolbar: {
      container: [
        ["bold", "italic", "underline", "strike"],
        [{ list: "ordered" }, { list: "bullet" }],
        [{ script: "sub" }, { script: "super" }],
        [{ color: [] }, { background: [] }],
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        ["link", "clean"],
      ],
    },
    keyboard: {
      bindings: {
        tab: {
          key: 9,
          handler: function () {
            return true;
          },
        },
      },
    },
  };

  setAiraData({
    summary: formNoteSummary,
    diagnosis: formNoteDiagnosis,
    plan: formNoteActionList,
    todo: formNoteToDoList,
    noteType: formNoteType,
    noteSubTemplate: formNoteSubType,
    medication: formNoteMedication,
    reports: formNoteReports,
  }: AiraNote) {
    let formAiraData = {
      noteTypeForm: {
        formNoteType,
        formNoteSubType,
      },
      formNoteSummary,
      formNoteDiagnosis,
      formNoteActionList,
      formNoteMedication,
      formNoteReports,
      formNoteToDoList,
    };

    //Filtering out form template objects with null values
    Object.keys(formAiraData).forEach(
      (formType) =>
        formAiraData[formType] == null && delete formAiraData[formType]
    );
    this.patientNotesForm.patchValue(formAiraData);
  }

  get addendumArrays() {
    return (
      this.patientNotesForm.get("formNoteAddendumArray") as UntypedFormArray
    ).controls;
  }

  onSelect(type: "subType" | "type"): void {
    if (type === "type")
      this.patientNotesForm.controls.noteTypeForm
        .get("formNoteSubType")
        .setValue(this.noteSubType[0]);
    this.patientNotesForm.get("noteReferenceId").setValue("new");
  }

  onDelete() {
    let note = {
      CPMRN: this.CPMRN,
      refId: this.patientNotesForm.get("noteReferenceId").value,
      encounters: this.encounters,
    };
    this.dialog.open(CloseModal, {
      data: note,
    });
  }

  newNote() {
    if (this.newDraftData) {
      const noteTypeForm: NoteType = {
        formNoteType: this.newDraftData.content["noteType"],
        formNoteSubType: this.newDraftData.content["noteSubType"],
      };
      const formData: NoteForm = {
        noteTypeForm,
        formNoteActionList: this.newDraftData.content["secondaryText"],
        formNoteSummary: this.newDraftData.content["primaryText"],
        formNoteToDoList: this.newDraftData.content["tertiaryText"],
        formNoteDiagnosis: this.newDraftData.content["diagnosisText"],
        formNoteMedication: this.newDraftData.content["medicationText"],
        formNoteReports: this.newDraftData.content["reportText"],
        noteReferenceId: "new",
        formNoteAddendumArray: this.newDraftData.content["addendum"],
        impactCase: this.newDraftData.content.impactCase,
        chargeable: this.newDraftData.content.chargeable,
      };
      this.patientNotesForm.patchValue(formData);
    }
  }
  public loaderActive: string;
  onSubmit(type: "pend" | "sign", cosign = null) {
    // inserting if addendum form to addendum array
    this.loaderActive = type;
    let addendum: Addendum[] = this.patientNotesForm.get(
      "formNoteAddendumArray"
    ).value;
    if (this.patientNotesForm.get("formNoteAddendum").get("note").value) {
      const name = this._noteService.author;
      const note = this.patientNotesForm
        .get("formNoteAddendum")
        .get("note").value;
      addendum = [...addendum, { name, note }];
    }
    const content: Content = {
      primaryText: this.patientNotesForm.get("formNoteSummary").value,
      secondaryText: this.patientNotesForm.get("formNoteActionList").value,
      tertiaryText: this.patientNotesForm.get("formNoteToDoList").value,
      diagnosisText: this.patientNotesForm.get("formNoteDiagnosis").value,
      medicationText: this.patientNotesForm.get("formNoteMedication").value,
      reportText: this.patientNotesForm.get("formNoteReports").value,
      addendum,
      noteType:
        this.patientNotesForm.controls.noteTypeForm.get("formNoteType").value,
      noteSubType:
        this.patientNotesForm.controls.noteTypeForm.get("formNoteSubType")
          .value,
      pendOrSigned: "",
      lock: null,
      impactCase: this.patientNotesForm.get("impactCase").value,
      chargeable: this.patientNotesForm.get("chargeable").value,
      signature: this.patientNotesForm.value?.signature
        ? this.patientNotesForm?.get("signature").value
        : "",
      stamp: this.patientNotesForm.value?.stamp
        ? this.patientNotesForm?.get("stamp").value
        : "",
    };
    if (type === "pend") {
      content.pendOrSigned = "pended";
      this.submitNote = {
        content: content,
        isDeleteEnabled:
          this.patientNotesForm.get("isDeleteEnabled").value != null
            ? this.patientNotesForm.get("isDeleteEnabled").value
            : true,
      };
    } else if (type === "sign") {
      content.pendOrSigned = "signed";
      this.submitNote = {
        content: content,
        isDeleteEnabled: false,
        cosign,
      };
    }

    if (this.patientNotesForm.get("noteReferenceId").value === "new") {
      this.store.dispatch(
        new fromStore.CreateNote({
          CPMRN: this.CPMRN,
          encounters: this.encounters,
          notes: this.submitNote,
        })
      );
    } else {
      const refId = this.patientNotesForm.get("noteReferenceId").value;
      this.store.dispatch(
        new fromStore.UpdateNote({
          CPMRN: this.CPMRN,
          encounters: this.encounters,
          notes: this.submitNote,
          refId,
        })
      );
    }
  }

  get displayForAttestion() {
    let notes: Notes;
    combineLatest([
      this.store.select(fromStore.getAllSignedNotes),
      this.store.select(fromStore.getAllPendedNotes),
    ])
      .pipe(take(1))
      .subscribe(([...finalNotes]) => {
        notes = flatten(finalNotes).find((notes: Notes) => {
          return (
            notes["_id"] === this.patientNotesForm.get("noteReferenceId").value
          );
        });
      });
    if (notes?.content["cosign"]) {
      const cosignDetails: Cosign = notes.content["cosign"];
      if (["pending", "optional", "accepted"].includes(cosignDetails.status)) {
        if (
          cosignDetails.requestedBy?.uniqueID.email != this.currentUser.email &&
          cosignDetails.requestedTo?.uniqueID.email === this.currentUser.email
        ) {
          return true;
        } else if (
          !cosignDetails.requestedTo &&
          cosignDetails.requestedBy?.uniqueID.email != this.currentUser.email
        ) {
          return true;
        }
        return false;
      }
      return false;
    }
  }

  requestAttest() {
    let dialogRef = this.dialog.open(RequestAttestComponent, {
      height: "53vh",
      width: "36vw",
    });
    dialogRef.componentInstance.patientInfo = {
      commandCenterID: this.commandCenterID,
      hospitalID: this.hospitalID,
    };
    ((dialogRef.componentInstance.userInfo as any) = this.currentUser),
      ((dialogRef.componentInstance.noteReferenceID as string) =
        this.patientNotesForm.get("noteReferenceId").value);
    dialogRef.afterClosed().subscribe((result: Intensivist) => {
      if (result) {
        const requstedtoInfo = {
          author: result.author,
          uniqueID: {
            name: result.name,
            email: result.email,
          },
        };
        this.onSubmit("sign", requstedtoInfo);
      }
    });
  }

  submitAttest(attest: "accepted" | "rejected") {
    this.loaderActive = attest;
    let addendum: Addendum = <Addendum>{};

    let addendumArray: Addendum[] = this.patientNotesForm.get(
      "formNoteAddendumArray"
    ).value.length
      ? this.patientNotesForm.get("formNoteAddendumArray").value
      : [];

    if (this.patientNotesForm.get("formNoteAddendum").get("note").value) {
      addendum["name"] = this._noteService.author;
      addendum["note"] = this.patientNotesForm
        .get("formNoteAddendum")
        .get("note").value;
      addendumArray.push(addendum);
    }
    let note = {
      CPMRN: this.CPMRN,
      refId: this.patientNotesForm.get("noteReferenceId").value,
      encounters: this.encounters,
      impactCase: this.patientNotesForm.get("impactCase").value,
      chargeable: this.patientNotesForm.get("chargeable").value,
      addendumArray,
      cosign: null,
    };
    if (!this.asyncPipe.transform(this.showAddenum$)) {
      note["cosign"] = {
        status: attest,
        message: this.attestationStatusMsg.value,
      };
    }
    this.store.dispatch(new fromStore.AttestNote(note));
  }

  autoSave({
    noteReferenceId,
    lock,
    formNoteActionList,
    formNoteAddendumArray,
    formNoteToDoList,
    formNoteDiagnosis,
    formNoteSummary,
    formNoteMedication,
    formNoteReports,
    impactCase,
    chargeable,
    noteTypeForm,
    formNoteAddendum,
  }: NoteForm): Observable<NotesDraft> {
    let addendum = formNoteAddendumArray;
    if (formNoteAddendum["note"]) {
      formNoteAddendum["name"] = this._noteService.author;
      addendum = [...addendum, formNoteAddendum];
    }
    let draftedNote: NotesDraft = {
      refId: noteReferenceId,
      noteType: noteTypeForm.formNoteType,
      noteSubType: noteTypeForm.formNoteSubType,
      primaryText: formNoteSummary,
      secondaryText: formNoteActionList,
      tertiaryText: formNoteToDoList,
      diagnosisText: formNoteDiagnosis,
      reportText: formNoteReports,
      medicationText: formNoteMedication,
      impactCase,
      chargeable,
      addendum,
      lock,
      cosign: !!this.attestionStatus
        ? {
            physicianMessage: this.attestationStatusMsg.value,
            status: this.attestionStatus,
          }
        : null,
      isAddendum: this.asyncPipe.transform(this.showAddenum$),
    };
    return of(draftedNote);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  disclaimer() {
    if (this.patientNotesForm.get("formNoteSummary").value) {
      if (
        !this.patientNotesForm
          .get("formNoteSummary")
          .value.includes(notesTemplate.template.get("disclaimer"))
      ) {
        let disclaimer =
          this.patientNotesForm.get("formNoteSummary").value +
          notesTemplate.template.get("disclaimer");
        this.patientNotesForm.get("formNoteSummary").setValue(disclaimer);
      }
    } else if (
      !this.patientNotesForm
        .get("formNoteDiagnosis")
        .value.includes(notesTemplate.template.get("disclaimer"))
    ) {
      let disclaimer =
        this.patientNotesForm.get("formNoteDiagnosis").value +
        notesTemplate.template.get("disclaimer");
      this.patientNotesForm.get("formNoteDiagnosis").setValue(disclaimer);
    }
  }

  disableBtn() {
    //Filtering out form template objects with null values
    let templateDataReference = { ...this.templateData };
    Object.keys(templateDataReference).forEach(
      (formType) =>
        templateDataReference[formType] == null &&
        delete templateDataReference[formType]
    );
    const primeTemplate = Object.keys(templateDataReference)[0];
    const template = {
      summaryTemplate: "formNoteSummary",
      diagnosisTemplate: "formNoteDiagnosis",
      secondaryTemplate: "formNoteActionList",
      tertiaryTemplate: "formNoteToDoList",
    };
    if (
      this.patientNotesForm.get(template[primeTemplate]).value ===
      this.templateData[primeTemplate]
    ) {
      return true;
    }
    return false;
  }

  // SWARA CODES

  public cursorIndex;
  public contentChangeNotes;

  augnitoText$ = new Subject();
  augnitoBtnActive = false;
  hyperText = {
    show: false,
    val: "",
  };

  onFocus(event, form) {
    if (event.range) {
      this.cursorIndex = { event: event, form: form };
    }
  }
  contentchanged(event) {
    this.contentChangeNotes = event.html;
  }

  augnitoText(event) {
    if (event) this.augnitoText$.next(event);
  }
  micStatus(event) {
    this.augnitoBtnActive = event;
  }
  partialTextAugnitochk(event) {
    this.hyperText = event;
  }
}

@Component({
  template: `<section class="iris-modal-container cc-status-wrapper">
    <div mat-dialog-title class="title">
      <mat-icon>warning</mat-icon>
      Are you sure you want to delete this note ?
    </div>

    <mat-dialog-actions align="end">
      <button mat-flat-button mat-dialog-close class="button-secondary">
        Cancel
      </button>
      <button
        mat-flat-button
        mat-dialog-close
        class="button-primary"
        (click)="deleteStatus()"
        [disabled]="loading$ | async"
      >
        Yes, Submit<app-loader
          [buttonLoader]="true"
          *ngIf="loading$ | async"
        ></app-loader>
      </button>
    </mat-dialog-actions>
  </section> `,
  styleUrls: ["./close-modal.component.scss"],
})
export class CloseModal {
  private unsubscribe$: Subject<any> = new Subject<any>();

  loading$: Observable<boolean> = this.store
    .select(fromStore.loadingStatus)
    .pipe(takeUntil(this.unsubscribe$));
  constructor(
    @Inject(MAT_DIALOG_DATA) public deleteData,
    private store: Store<fromStore.NoteState>,
    private dialogRef: MatDialogRef<CloseModal>
  ) {}

  deleteStatus() {
    this.store.dispatch(
      new fromStore.NoteDelete({
        CPMRN: this.deleteData.CPMRN,
        encounters: this.deleteData.encounters,
        refId: this.deleteData.refId,
      })
    );
    this.dialogRef.close();
  }
}
