export const timeZoneCountryCode = {
  "Asia/Kabul": "+93",
  "Europe/Tirane": "+355",
  "Africa/Algiers": "+213",
  "Pacific/Pago_Pago": "+1684",
  "Europe/Andorra": "+376",
  "Africa/Luanda": "+244",
  "America/Anguilla": "+1264",
  "America/Antigua": "+1268",
  "America/Argentina/Buenos_Aires": "+54",
  "America/Argentina/Catamarca": "+54",
  "America/Argentina/Cordoba": "+54",
  "America/Argentina/Jujuy": "+54",
  "America/Argentina/La_Rioja": "+54",
  "America/Argentina/Mendoza": "+54",
  "America/Argentina/Rio_Gallegos": "+54",
  "America/Argentina/Salta": "+54",
  "America/Argentina/San_Juan": "+54",
  "America/Argentina/San_Luis": "+54",
  "America/Argentina/Tucuman": "+54",
  "America/Argentina/Ushuaia": "+54",
  "Asia/Yerevan": "+374",
  "America/Aruba": "+297",
  "Antarctica/Macquarie": "+61",
  "Australia/Adelaide": "+61",
  "Australia/Brisbane": "+61",
  "Australia/Broken_Hill": "+61",
  "Australia/Darwin": "+61",
  "Australia/Eucla": "+61",
  "Australia/Hobart": "+61",
  "Australia/Lindeman": "+61",
  "Australia/Lord_Howe": "+61",
  "Australia/Melbourne": "+61",
  "Australia/Perth": "+61",
  "Australia/Sydney": "+61",
  "Europe/Vienna": "+43",
  "Asia/Baku": "+994",
  "America/Nassau": "+1242",
  "Asia/Bahrain": "+973",
  "Asia/Dhaka": "+880",
  "America/Barbados": "+1246",
  "Europe/Minsk": "+375",
  "Europe/Brussels": "+32",
  "America/Belize": "+501",
  "Africa/Porto-Novo": "+229",
  "Atlantic/Bermuda": "+1441",
  "Asia/Thimphu": "+975",
  "America/La_Paz": "+591",
  "America/Kralendijk": "+599",
  "Europe/Sarajevo": "+387",
  "Africa/Gaborone": "+267",
  "America/Araguaina": "+55",
  "America/Bahia": "+55",
  "America/Belem": "+55",
  "America/Boa_Vista": "+55",
  "America/Campo_Grande": "+55",
  "America/Cuiaba": "+55",
  "America/Eirunepe": "+55",
  "America/Fortaleza": "+55",
  "America/Maceio": "+55",
  "America/Manaus": "+55",
  "America/Noronha": "+55",
  "America/Porto_Velho": "+55",
  "America/Recife": "+55",
  "America/Rio_Branco": "+55",
  "America/Santarem": "+55",
  "America/Sao_Paulo": "+55",
  "Indian/Chagos": "+246",
  "Asia/Brunei": "+673",
  "Europe/Sofia": "+359",
  "Africa/Ouagadougou": "+226",
  "Africa/Bujumbura": "+257",
  "Asia/Phnom_Penh": "+855",
  "Africa/Douala": "+237",
  "America/Atikokan": "+1",
  "America/Blanc-Sablon": "+1",
  "America/Cambridge_Bay": "+1",
  "America/Creston": "+1",
  "America/Dawson": "+1",
  "America/Dawson_Creek": "+1",
  "America/Edmonton": "+1",
  "America/Fort_Nelson": "+1",
  "America/Glace_Bay": "+1",
  "America/Goose_Bay": "+1",
  "America/Halifax": "+1",
  "America/Inuvik": "+1",
  "America/Iqaluit": "+1",
  "America/Moncton": "+1",
  "America/Rankin_Inlet": "+1",
  "America/Regina": "+1",
  "America/Resolute": "+1",
  "America/St_Johns": "+1",
  "America/Swift_Current": "+1",
  "America/Toronto": "+1",
  "America/Vancouver": "+1",
  "America/Whitehorse": "+1",
  "America/Winnipeg": "+1",
  "Atlantic/Cape_Verde": "+238",
  "America/Cayman": "+1345",
  "Africa/Bangui": "+236",
  "Africa/Ndjamena": "+235",
  "America/Punta_Arenas": "+56",
  "America/Santiago": "+56",
  "Pacific/Easter": "+56",
  "Asia/Shanghai": "+86",
  "Asia/Urumqi": "+86",
  "Indian/Christmas": "+61",
  "Indian/Cocos": "+61",
  "America/Bogota": "+57",
  "Indian/Comoro": "+269",
  "Africa/Brazzaville": "+242",
  "Africa/Kinshasa": "+243",
  "Africa/Lubumbashi": "+243",
  "Pacific/Rarotonga": "+682",
  "America/Costa_Rica": "+506",
  "Europe/Zagreb": "+385",
  "America/Havana": "+53",
  "America/Curacao": "+599",
  "Asia/Famagusta": "+357",
  "Asia/Nicosia": "+357",
  "Europe/Prague": "+420",
  "Africa/Abidjan": "+225",
  "Europe/Copenhagen": "+45",
  "Africa/Djibouti": "+253",
  "America/Dominica": "+1767",
  "America/Santo_Domingo": "+1",
  "America/Guayaquil": "+593",
  "Pacific/Galapagos": "+593",
  "Africa/Cairo": "+20",
  "America/El_Salvador": "+503",
  "Africa/Malabo": "+240",
  "Africa/Asmara": "+291",
  "Europe/Tallinn": "+372",
  "Africa/Addis_Ababa": "+251",
  "Atlantic/Stanley": "+500",
  "Atlantic/Faroe": "+298",
  "Pacific/Fiji": "+679",
  "Europe/Helsinki": "+358",
  "Europe/Paris": "+33",
  "America/Cayenne": "+594",
  "Pacific/Gambier": "+689",
  "Pacific/Marquesas": "+689",
  "Pacific/Tahiti": "+689",
  "Africa/Libreville": "+241",
  "Africa/Banjul": "+220",
  "Asia/Tbilisi": "+995",
  "Europe/Berlin": "+49",
  "Europe/Busingen": "+49",
  "Africa/Accra": "+233",
  "Europe/Gibraltar": "+350",
  "Europe/Athens": "+30",
  "America/Danmarkshavn": "+299",
  "America/Nuuk": "+299",
  "America/Scoresbysund": "+299",
  "America/Thule": "+299",
  "America/Grenada": "+1473",
  "America/Guadeloupe": "+590",
  "Pacific/Guam": "+1671",
  "America/Guatemala": "+502",
  "Europe/Guernsey": "+44",
  "Africa/Conakry": "+224",
  "Africa/Bissau": "+245",
  "America/Guyana": "+592",
  "America/Port-au-Prince": "+509",
  "Europe/Vatican": "+39",
  "America/Tegucigalpa": "+504",
  "Asia/Hong_Kong": "+852",
  "Europe/Budapest": "+36",
  "Atlantic/Reykjavik": "+354",
  "Asia/Calcutta": "+91",
  "Asia/Kolkata": "+91",
  "Asia/Jakarta": "+62",
  "Asia/Jayapura": "+62",
  "Asia/Makassar": "+62",
  "Asia/Pontianak": "+62",
  "Asia/Tehran": "+98",
  "Asia/Baghdad": "+964",
  "Europe/Dublin": "+353",
  "Europe/Isle_of_Man": "+44",
  "Asia/Jerusalem": "+972",
  "Europe/Rome": "+39",
  "America/Jamaica": "+1",
  "Asia/Tokyo": "+81",
  "Europe/Jersey": "+44",
  "Asia/Amman": "+962",
  "Asia/Almaty": "+7",
  "Asia/Aqtau": "+7",
  "Asia/Aqtobe": "+7",
  "Asia/Atyrau": "+7",
  "Asia/Oral": "+7",
  "Asia/Qostanay": "+7",
  "Asia/Qyzylorda": "+7",
  "Africa/Nairobi": "+254",
  "Pacific/Kanton": "+686",
  "Pacific/Kiritimati": "+686",
  "Pacific/Tarawa": "+686",
  "Asia/Pyongyang": "+850",
  "Asia/Seoul": "+82",
  "Asia/Kuwait": "+965",
  "Asia/Bishkek": "+996",
  "Asia/Vientiane": "+856",
  "Europe/Riga": "+371",
  "Asia/Beirut": "+961",
  "Africa/Maseru": "+266",
  "Africa/Monrovia": "+231",
  "Africa/Tripoli": "+218",
  "Europe/Vaduz": "+423",
  "Europe/Vilnius": "+370",
  "Europe/Luxembourg": "+352",
  "Asia/Macau": "+853",
  "Europe/Skopje": "+389",
  "Indian/Antananarivo": "+261",
  "Africa/Blantyre": "+265",
  "Asia/Kuala_Lumpur": "+60",
  "Asia/Kuching": "+60",
  "Indian/Maldives": "+960",
  "Africa/Bamako": "+223",
  "Europe/Malta": "+356",
  "Pacific/Kwajalein": "+692",
  "Pacific/Majuro": "+692",
  "America/Martinique": "+596",
  "Africa/Nouakchott": "+222",
  "Indian/Mauritius": "+230",
  "Indian/Mayotte": "+262",
  "America/Bahia_Banderas": "+52",
  "America/Cancun": "+52",
  "America/Chihuahua": "+52",
  "America/Ciudad_Juarez": "+52",
  "America/Hermosillo": "+52",
  "America/Matamoros": "+52",
  "America/Mazatlan": "+52",
  "America/Merida": "+52",
  "America/Mexico_City": "+52",
  "America/Monterrey": "+52",
  "America/Ojinaga": "+52",
  "America/Tijuana": "+52",
  "Pacific/Chuuk": "+691",
  "Pacific/Kosrae": "+691",
  "Pacific/Pohnpei": "+691",
  "Europe/Chisinau": "+373",
  "Europe/Monaco": "+377",
  "Asia/Choibalsan": "+976",
  "Asia/Hovd": "+976",
  "Asia/Ulaanbaatar": "+976",
  "Europe/Podgorica": "+382",
  "America/Montserrat": "+1664",
  "Africa/Casablanca": "+212",
  "Africa/El_Aaiun": "+212",
  "Africa/Maputo": "+258",
  "Asia/Yangon": "+95",
  "Africa/Windhoek": "+264",
  "Pacific/Nauru": "+674",
  "Asia/Kathmandu": "+977",
  "Europe/Amsterdam": "+31",
  "Pacific/Noumea": "+687",
  "Pacific/Auckland": "+64",
  "Pacific/Chatham": "+64",
  "America/Managua": "+505",
  "Africa/Niamey": "+227",
  "Africa/Lagos": "+234",
  "Pacific/Niue": "+683",
  "Pacific/Norfolk": "+672",
  "Pacific/Saipan": "+1670",
  "Europe/Oslo": "+47",
  "Asia/Muscat": "+968",
  "Asia/Karachi": "+92",
  "Pacific/Palau": "+680",
  "Asia/Gaza": "+970",
  "Asia/Hebron": "+970",
  "America/Panama": "+507",
  "Pacific/Bougainville": "+675",
  "Pacific/Port_Moresby": "+675",
  "America/Asuncion": "+595",
  "America/Lima": "+51",
  "Asia/Manila": "+63",
  "Europe/Warsaw": "+48",
  "Atlantic/Azores": "+351",
  "Atlantic/Madeira": "+351",
  "Europe/Lisbon": "+351",
  "America/Puerto_Rico": "+1",
  "Asia/Qatar": "+974",
  "Europe/Bucharest": "+40",
  "Asia/Anadyr": "+7",
  "Asia/Barnaul": "+7",
  "Asia/Chita": "+7",
  "Asia/Irkutsk": "+7",
  "Asia/Kamchatka": "+7",
  "Asia/Khandyga": "+7",
  "Asia/Krasnoyarsk": "+7",
  "Asia/Magadan": "+7",
  "Asia/Novokuznetsk": "+7",
  "Asia/Novosibirsk": "+7",
  "Asia/Omsk": "+7",
  "Asia/Sakhalin": "+7",
  "Asia/Srednekolymsk": "+7",
  "Asia/Tomsk": "+7",
  "Asia/Ust-Nera": "+7",
  "Asia/Vladivostok": "+7",
  "Asia/Yakutsk": "+7",
  "Asia/Yekaterinburg": "+7",
  "Europe/Astrakhan": "+7",
  "Europe/Kaliningrad": "+7",
  "Europe/Kirov": "+7",
  "Europe/Moscow": "+7",
  "Europe/Samara": "+7",
  "Europe/Saratov": "+7",
  "Europe/Ulyanovsk": "+7",
  "Europe/Volgograd": "+7",
  "Africa/Kigali": "+250",
  "Indian/Reunion": "+262",
  "America/St_Barthelemy": "+590",
  "Atlantic/St_Helena": "+290",
  "America/St_Kitts": "+1869",
  "America/St_Lucia": "+1758",
  "America/Marigot": "+590",
  "America/Miquelon": "+508",
  "America/St_Vincent": "+1784",
  "Pacific/Apia": "+685",
  "Europe/San_Marino": "+378",
  "Africa/Sao_Tome": "+239",
  "Asia/Riyadh": "+966",
  "Africa/Dakar": "+221",
  "Europe/Belgrade": "+381",
  "Indian/Mahe": "+248",
  "Africa/Freetown": "+232",
  "Asia/Singapore": "+65",
  "America/Lower_Princes": "+1721",
  "Europe/Bratislava": "+421",
  "Europe/Ljubljana": "+386",
  "Pacific/Guadalcanal": "+677",
  "Africa/Mogadishu": "+252",
  "Africa/Johannesburg": "+27",
  "Africa/Juba": "+211",
  "Africa/Ceuta": "+34",
  "Atlantic/Canary": "+34",
  "Europe/Madrid": "+34",
  "Asia/Colombo": "+94",
  "Africa/Khartoum": "+249",
  "America/Paramaribo": "+597",
  "Arctic/Longyearbyen": "+47",
  "Africa/Mbabane": "+268",
  "Europe/Stockholm": "+46",
  "Europe/Zurich": "+41",
  "Asia/Damascus": "+963",
  "Asia/Taipei": "+886",
  "Asia/Dushanbe": "+992",
  "Africa/Dar_es_Salaam": "+255",
  "Asia/Bangkok": "+66",
  "Asia/Dili": "+670",
  "Africa/Lome": "+228",
  "Pacific/Fakaofo": "+690",
  "Pacific/Tongatapu": "+676",
  "America/Port_of_Spain": "+1868",
  "Africa/Tunis": "+216",
  "Europe/Istanbul": "+90",
  "Asia/Ashgabat": "+993",
  "America/Grand_Turk": "+1649",
  "Pacific/Funafuti": "+688",
  "Africa/Kampala": "+256",
  "Europe/Kyiv": "+380",
  "Europe/Simferopol": "+380",
  "Asia/Dubai": "+971",
  "Europe/London": "+44",
  "America/Adak": "+1",
  "America/Anchorage": "+1",
  "America/Boise": "+1",
  "America/Chicago": "+1",
  "America/Denver": "+1",
  "America/Detroit": "+1",
  "America/Indiana/Indianapolis": "+1",
  "America/Indiana/Knox": "+1",
  "America/Indiana/Marengo": "+1",
  "America/Indiana/Petersburg": "+1",
  "America/Indiana/Tell_City": "+1",
  "America/Indiana/Vevay": "+1",
  "America/Indiana/Vincennes": "+1",
  "America/Indiana/Winamac": "+1",
  "America/Juneau": "+1",
  "America/Kentucky/Louisville": "+1",
  "America/Kentucky/Monticello": "+1",
  "America/Los_Angeles": "+1",
  "America/Menominee": "+1",
  "America/Metlakatla": "+1",
  "America/New_York": "+1",
  "America/Nome": "+1",
  "America/North_Dakota/Beulah": "+1",
  "America/North_Dakota/Center": "+1",
  "America/North_Dakota/New_Salem": "+1",
  "America/Phoenix": "+1",
  "America/Sitka": "+1",
  "America/Yakutat": "+1",
  "Pacific/Honolulu": "+1",
  "America/Montevideo": "+598",
  "Asia/Samarkand": "+998",
  "Asia/Tashkent": "+998",
  "Pacific/Efate": "+678",
  "America/Caracas": "+58",
  "Asia/Ho_Chi_Minh": "+84",
  "America/Tortola": "+1284",
  "America/St_Thomas": "+1340",
  "Pacific/Wallis": "+681",
  "Asia/Aden": "+967",
  "Africa/Lusaka": "+260",
  "Africa/Harare": "+263",
  "Europe/Mariehamn": "+358",
};

const getDefaultTimeZone = () => {
  let timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  if (timeZone === "Etc/Unknown") {
    timeZone = "Asia/Kolkata"; // Setting default timezone to "Asia/Kolkata"
  }
  return timeZone;
};

export const defaultTimeZone = getDefaultTimeZone();
