import { createSelector } from "@ngrx/store";
import { getDashboardState } from "../reducers";

const getDashboardViewState = createSelector(
  getDashboardState,
  (state) => state.view
);

export const getLoaderStatus = createSelector(
  getDashboardViewState,
  (state) => state.loading
);
