import { EntityState } from "@ngrx/entity";
import {
  WorkspaceInterface,
  SeverityInterface,
  DashboardCountsInterface,
} from "../../model";

export interface DashboardViewReducerState {
  loading: boolean;
}

export interface DashboardDataReducerState
  extends EntityState<WorkspaceInterface>,
    DashboardCountsInterface {}

export interface DashboardActionReducerMapState {
  data: DashboardDataReducerState;
  view: DashboardViewReducerState;
}
