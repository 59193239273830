import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from "@angular/core";
import { Store, select } from "@ngrx/store";
import { Subject } from "rxjs";
import * as fromPatientHeaderReducers from "src/app/store/reducers/patient-chart/patient-header/index";
import { TimeZoneDetails } from "src/app/models/hospital";
import { takeUntil } from "rxjs/operators";
import { TimezoneTextConfig } from "../../models";

@Component({
  selector: "cp-timezone-label",
  templateUrl: "./timezone-label.component.html",
  styleUrls: ["./timezone-label.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimezoneLabelComponent implements OnInit, OnDestroy {
  private unsubscribe$ = new Subject<any>();

  @Input("isLongFormat") isLongFormat: boolean = false;
  @Input() textConfig: TimezoneTextConfig = {
    textColor: "#677797",
  };
  _timeZoneDetail: TimeZoneDetails;
  @Input()
  set timeZoneDetail(timeZoneDetail) {
    this._timeZoneDetail = timeZoneDetail;
  }
  get timeZoneDetail() {
    return this._timeZoneDetail;
  }

  private patientHospitalTimeZone$ = this._store.pipe(
    select(fromPatientHeaderReducers.getPatientHospitalTimeZone),
    takeUntil(this.unsubscribe$)
  );

  constructor(private _store: Store<any>, private _cdrf: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.patientHospitalTimeZone$.subscribe((data) => {
      if (this.timeZoneDetail) return;

      this._timeZoneDetail = data;
      this._cdrf.markForCheck();
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
