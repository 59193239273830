import { Injectable } from "@angular/core";
import { createEffect, Actions, ofType } from "@ngrx/effects";
import { of } from "rxjs";
import { catchError, map, mergeMap, tap } from "rxjs/operators";
import { FileService } from "../../services/file.service";
import * as uploadActions from "../actions/upload-file.action";
import { socket } from "../../../config/socket";
import { LabsScansService } from "../../services/labs-scans.service";
import { AlertService } from "@iris/alert/services/alert.service";

@Injectable()
export class UploadFileEffects {
  constructor(
    private actions$: Actions,
    private _fileService: FileService,
    private _alertService: AlertService,
    private _labService: LabsScansService
  ) {}

  uploadFile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(uploadActions.UPLOAD_FILE),
      map((action: uploadActions.UploadFile) => action.payload),
      mergeMap(({ file }) => {
        return this._fileService.getSignedUrl(file).pipe(
          map((code) => new uploadActions.UploadFileSuccess(code)),
          catchError((error) => {
            this._labService.loadingStatus(false);
            this._alertService.showNotification({
              type: "Error",
              message: "File upload error!",
            });
            return of(new uploadActions.UploadFileFail(error));
          })
        );
      })
    )
  );

  uploadFileGcp$ = createEffect(() =>
    this.actions$.pipe(
      ofType(uploadActions.UPLOAD_FILE_GGP),
      map((action: any) => action.payload),
      mergeMap(({ file, url }) => {
        return this._fileService.uploadFile(file, url, file.type).pipe(
          map((code) => new uploadActions.UploadFileGcpSucsess(code)),
          catchError((error) => {
            this._labService.loadingStatus(false);
            this._alertService.showNotification({
              type: "Error",
              message: "File upload error!",
            });
            return of(new uploadActions.UploadFileFail(error));
          })
        );
      })
    )
  );
}
