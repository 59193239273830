/**
 * @description Checks if the passed value is undefined or null
 * @param {any} val
 * @returns boolean
 * @author Suraj Shenoy
 * @date Jul 16 2021
 */
export const valExists = (val: any): boolean => {
  if (typeof val === "undefined" || val === null) {
    return false;
  }
  return true;
};

/**
 * @description Getting date string
 * @returns string
 * @author Gokul Pratheep
 * @date Mar 13 2024
 */
export const getDateString = (
  year: number,
  month: number,
  date: number
): string => {
  const monthString = ("0" + (month + 1)).slice(-2);
  const yearSting = ("0" + date).slice(-2);
  return `${year}-${monthString}-${yearSting} 00:00`;
};
