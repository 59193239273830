<app-loader *ngIf="dashboardLoading$ | async"></app-loader>

<div class="dashbord-container">
  <mat-grid-list
    class="first-row cp-mat-grid-list"
    cols="8"
    rowHeight="91px"
    gutterSize="16"
  >
    <mat-grid-tile>
      <div
        class="count-cont bg-white w-100 display-flex align-items-center justify-content-between"
        style="padding: 0 0 0 16px; justify-content: center; display: flex"
      >
        <div class="number-count">
          <div class="label">Patients</div>
          <div class="value">{{ patientCount$ | async }}</div>
        </div>
        <cp-donut-chart
          [data]="severity$ | async"
          [width]="100"
          [height]="100"
          [colors]="donutDataColors"
        ></cp-donut-chart>
      </div>
    </mat-grid-tile>
    <mat-grid-tile>
      <div class="count-cont bg-white">
        <div class="number-count">
          <div class="label">Workspace</div>
          <div class="value">{{ workspaceCount$ | async }}</div>
        </div>
      </div>
    </mat-grid-tile>
    <mat-grid-tile colspan="2">
      <div class="count-cont bg-white">
        <div class="number-count">
          <div class="label">Hospitals</div>
          <div class="value">{{ hospitalCount$ | async }}</div>
        </div>
        <div class="number-count">
          <div class="label">Units</div>
          <div class="value">{{ unitCount$ | async }}</div>
        </div>
        <div class="number-count">
          <div class="label">Beds</div>
          <div class="value">{{ bedCount$ | async }}</div>
        </div>
      </div>
    </mat-grid-tile>
    <mat-grid-tile colspan="4">
      <div class="count-cont bg-white">
        <div class="number-count">
          <div class="label">Physicians</div>
          <div class="value">{{ physicianCount$ | async }}</div>
        </div>
        <div class="number-count">
          <div class="label">Specialists</div>
          <div class="value">{{ specialistCount$ | async }}</div>
        </div>
        <div class="number-count">
          <div class="label">CCAs</div>
          <div class="value">{{ ccaCount$ | async }}</div>
        </div>
        <div class="number-count">
          <div class="label">CCRNs</div>
          <div class="value">{{ ccrnCount$ | async }}</div>
        </div>
        <div class="number-count">
          <div class="label">RNs</div>
          <div class="value">{{ rnCount$ | async }}</div>
        </div>
      </div>
    </mat-grid-tile>
  </mat-grid-list>

  <!-- <div class="workspaces display-flex">
  </div> -->
  <mat-grid-list
    class="cp-mat-grid-list workspaces display-flex"
    cols="4"
    rowHeight="590px"
    gutterSize="16"
  >
    <mat-grid-tile
      *ngFor="let workspace of workspaces$ | async; index as workspaceIndex"
    >
      <div class="workspace-container display-flex flex-column bg-white">
        <div class="ws-header display-flex flex-column">
          <div class="ws-header--tittle">
            <span class="ws-header--tittle--name">{{ workspace?.name }}: </span>
            <span class="ws-header--tittle--patients-count">
              {{ workspace?.patientCount || 0 }}</span
            >
            <span class="ws-header--tittle--patients"> patients</span>
          </div>
          <cp-stacked-bar
            class="ws-header--bar"
            [totalCount]="workspace?.patientCount"
            [stackBarData]="workspace?.severity"
          ></cp-stacked-bar>
          <div class="ws-header--body display-flex justify-content-between">
            <div class="ws-header--body--number-count">
              <div class="label">Hospitals</div>
              <div class="value">{{ workspace?.hospitalCount || 0 }}</div>
            </div>
            <div class="ws-header--body--number-count">
              <div class="label">Units</div>
              <div class="value">{{ workspace?.unitCount || 0 }}</div>
            </div>
            <div class="ws-header--body--number-count">
              <div class="label">Clinicians</div>
              <div class="value">{{ workspace?.clinicianCount || 0 }}</div>
            </div>
          </div>
        </div>
        <div class="ws-body display-flex flex-column">
          <mat-tab-group [selectedIndex]="0">
            <mat-tab id="Clinicians" label="Clinicians">
              <ng-template class="iris-tab" mat-tab-label>
                <mat-icon
                  class="cp-icon cp-mat-icon-overflow-initial"
                  aria-hidden=""
                  svgIcon="cp-avatar"
                ></mat-icon>
                <span>Clinicians</span>
              </ng-template>
              <div class="ws-body--cont display-flex flex-column">
                <ng-container
                  *ngFor="
                    let clinician of workspace?.clinicians;
                    index as clinicianIndex
                  "
                >
                  <cp-user-expansion-view
                    [userData]="clinician"
                  ></cp-user-expansion-view>
                  <hr class="divider" />
                </ng-container>
              </div>
            </mat-tab>
            <mat-tab id="Units" label="Units">
              <ng-template class="iris-tab" mat-tab-label>
                <mat-icon
                  class="cp-icon cp-mat-icon-overflow-initial"
                  aria-hidden=""
                  svgIcon="cp-hospital-logo-1"
                ></mat-icon>
                Units
              </ng-template>
              <div class="ws-body--cont">
                <ng-container
                  *ngFor="
                    let hospitalUnitInfo of workspace?.hospitals;
                    index as hospitalUnitIndex
                  "
                >
                  <cp-hospital-unit-expansion-view
                    [hospitalData]="hospitalUnitInfo"
                  ></cp-hospital-unit-expansion-view>
                  <hr class="divider" />
                </ng-container>
              </div>
            </mat-tab>
          </mat-tab-group>
        </div>
      </div>
    </mat-grid-tile>
  </mat-grid-list>
</div>
