import { FocusMonitor } from "@angular/cdk/a11y";
import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import {
  Component,
  ElementRef,
  Inject,
  Input,
  OnDestroy,
  Optional,
  Self,
  ViewChild,
  OnInit,
  ContentChild,
  TemplateRef,
  Output,
  EventEmitter,
  ViewChildren,
  QueryList,
} from "@angular/core";
import {
  ControlValueAccessor,
  FormBuilder,
  FormControl,
  FormGroupDirective,
  NgControl,
} from "@angular/forms";
import {
  MAT_FORM_FIELD,
  MatFormField,
  MatFormFieldControl,
} from "@angular/material/form-field";
import { MatSelect, MatSelectChange } from "@angular/material/select";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import {
  SearchInputInterface,
  optionDataInput,
} from "../../searchable-dropdown.model";

/** Custom `MatFormFieldControl` for telephone number input. */
@Component({
  selector: "cp-searchable-dropdown",
  templateUrl: "searchable-dropdown.component.html",
  styleUrls: ["./searchable-dropdown.component.scss"],
  providers: [
    { provide: MatFormFieldControl, useExisting: SearchableDropdownComponent },
  ],
  host: {
    "[class.example-floating]": "shouldLabelFloat",
    "[id]": "id",
  },
})
export class SearchableDropdownComponent
  implements OnInit, ControlValueAccessor, MatFormFieldControl<any>, OnDestroy
{
  static nextId = 0;
  @ViewChild("searchableDropdown") searchableDropdown: ElementRef;
  @ViewChildren("matOptionSelect", { read: ElementRef })
  matOptionSelect: QueryList<ElementRef>;
  get matOptionList() {
    return this.matOptionSelect.toArray();
  }
  @ViewChild("matSelect") matSelect: MatSelect;
  @ViewChild("search") searchElement: ElementRef;
  @Output("selectionChange") selectionChange: EventEmitter<MatSelectChange> =
    new EventEmitter<MatSelectChange>();

  @ContentChild("optionTemplate", { static: false })
  optionTemplateRef: TemplateRef<any>;

  @ContentChild("topOptionTemplate", { static: false })
  topOptionTemplate: TemplateRef<any>;

  public multiSelectInput: FormControl<any[]> | undefined;
  stateChanges = new Subject<void>();
  focused = false;
  touched = false;
  controlType = "cp-mat-search-dropdown";
  id = `cp-mat-search-dropdown-${SearchableDropdownComponent.nextId++}`;
  onChange = (_: any) => {};
  onTouched = () => {};
  searchInput: FormControl<string>;
  isNoResults: boolean = false;

  private unsubscribe$: Subject<any> = new Subject<any>();

  get empty() {
    return !this.multiSelectInput.value?.length;
  }

  get shouldLabelFloat() {
    return this.focused || !this.empty;
  }

  @Input("aria-describedby") userAriaDescribedBy: string;

  @Input("multiple") multiple: boolean = false;

  @Input("dataKeySearch") dataKeySearch: string = null;

  @Input("searchPlaceholder") searchPlaceholder = "";
  @Input("isMatSelectTrigger") isMatSelectTrigger: boolean = false;

  @Input("inputClass") inputClass: string = "";

  @Input("data") data: optionDataInput = [];
  @Input("formClass") formClass: string = "";
  @Input("previousSelectedValues") previousSelectedValues: [] = [];
  @Input("panelClass") panelClass: string = "";

  @Input("topList") topList: optionDataInput = [];

  @Input("isOther") isOther = "";
  @Input() matSelectClass: string = "";

  @Input()
  get placeholder(): string {
    return this._placeholder;
  }
  set placeholder(value: string) {
    this._placeholder = value;
    this.stateChanges.next();
  }
  private _placeholder: string;

  @Input()
  get required(): boolean {
    return this._required;
  }
  set required(value: BooleanInput) {
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next();
  }
  private _required = false;

  @Input()
  get disabled(): boolean {
    return this._disabled;
  }
  set disabled(value: BooleanInput) {
    this._disabled = coerceBooleanProperty(value);
    this._disabled
      ? this.multiSelectInput.disable()
      : this.multiSelectInput.enable();
    this.stateChanges.next();
  }
  private _disabled = false;

  @Input("errorStateMatcher") errorStateMatcher: any;

  @Input()
  get value(): any[] | null {
    return this.multiSelectInput.value;
  }
  set value(tel: any[] | null) {
    this.multiSelectInput.setValue(tel);
    this.stateChanges.next();
  }

  get errorState(): boolean {
    const parentErrors = this.errorStateMatcher?.isErrorState(
      this.ngControl?.control,
      this._parentFormGroup
    );
    return (this.multiSelectInput.invalid && this.touched) || parentErrors;
  }

  constructor(
    _fb: FormBuilder,
    private _focusMonitor: FocusMonitor,
    private _elementRef: ElementRef<HTMLElement>,
    @Optional() @Inject(MAT_FORM_FIELD) public _formField: MatFormField,
    @Optional() @Self() public ngControl: NgControl,
    private _parentFormGroup: FormGroupDirective
  ) {
    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }

    this.multiSelectInput = _fb.control([]);
    this.searchInput = _fb.control("");
  }
  ngOnInit(): void {
    this.initializeEvents();
  }

  initializeEvents() {
    this.searchInput.valueChanges
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => {
        this.setNoResults();
      });
  }

  setNoResults() {
    if (!this.data?.length) {
      this.isNoResults = false;
      return;
    }
    this.isNoResults = !this.data.some((value) => {
      if (typeof value === "string") return this.isMatching(value);
      return this.searchCheck(value);
    });
  }

  ngOnDestroy() {
    this.stateChanges.complete();
    this._focusMonitor.stopMonitoring(this._elementRef);
  }

  onFocusIn() {
    if (!this.focused) {
      this.focused = true;
      this.stateChanges.next();
    }
  }

  onFocusOut(event: FocusEvent) {
    if (
      !this._elementRef.nativeElement.contains(event.relatedTarget as Element)
    ) {
      this.touched = true;
      this.focused = false;
      this.onTouched();
      this.stateChanges.next();
    }
  }

  openDropdown(isOpen): void {
    if (isOpen) {
      this.searchElement.nativeElement.focus();
    } else {
      this.searchInput.setValue("", { emitEvent: false });
    }
  }

  isOptionHidden(option: SearchInputInterface | string) {
    if (!this.searchInput.value) return false;
    if (typeof option === "string") {
      return !this.isMatching(option);
    }
    return !this.searchCheck(option);
  }

  searchCheck(optionData: SearchInputInterface): boolean {
    const { data, value, label } = optionData;
    const valueSearch = value || label;
    if (!valueSearch) return false;
    return label
      ? this.isMatching(label)
      : this.isMatching(value) ||
          (this.dataKeySearch &&
            typeof data === "object" &&
            this.isMatching(data[this.dataKeySearch]));
  }

  public isMatching(value) {
    return value.toLowerCase().includes(this.searchInput.value.toLowerCase());
  }

  public checkFilterValue(optionData: SearchInputInterface) {
    return optionData.label || optionData.value;
  }

  public checkData(optionData: SearchInputInterface) {
    return optionData.data[this.dataKeySearch];
  }

  setDescribedByIds(ids: string[]) {
    const controlElement = this._elementRef.nativeElement.querySelector(
      ".cp-mat-search-dropdown-container"
    )!;
    controlElement.setAttribute("aria-describedby", ids.join(" "));
  }

  onContainerClick() {
    this.matSelect.open();
  }

  writeValue(tel: any[] | null): void {
    this.value = tel;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  handleInput(e: MatSelectChange): void {
    this.onChange(this.value);
    this.selectionChange.emit(e);
  }

  isOptionString(option: any): boolean {
    return typeof option === "string";
  }
}
