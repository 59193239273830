import { Component, Input, OnInit } from "@angular/core";
import { STACKED_CLASS_SEVERITY_MAP } from "@iris/stacked-bar/model";

@Component({
  selector: "cp-stacked-bar",
  templateUrl: "./stacked-bar.component.html",
  styleUrls: ["./stacked-bar.component.scss"],
})
export class StackedBarComponent implements OnInit {
  constructor() {}

  @Input() totalCount: number = 0;
  @Input() stackBarData;

  stackBarClassMap = STACKED_CLASS_SEVERITY_MAP;

  ngOnInit(): void {}

  returnZero = () => {
    return 0;
  };
}
