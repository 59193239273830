// src/index.tsx
import React from 'react';
import { Provider } from 'react-redux';
import store from '../reactStore/refreshStore';
import RefreshModal from './refresh-modal';

const RefreshWrapper: React.FC = () => (
  <Provider store={store}>
    <RefreshModal />
  </Provider>
);

export default RefreshWrapper