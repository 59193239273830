import {
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  ElementRef,
  ViewEncapsulation,
} from "@angular/core";
import * as d3 from "d3";
import { ChartConstants, ColorScheme } from "../donut-chart.constants";
import { ChartData } from "../donut-chart.modal";
import { DataTransformService } from "../donut-service/data-transform.service";
import { TooltipService } from "../donut-service/tooltip.service";

@Component({
  selector: "cp-donut-chart",
  template: `<div class="donut-chart-container">
    <svg [attr.width]="width" [attr.height]="height"></svg>
    <div class="tooltip" #tooltipElement style="opacity: 0;"></div>
  </div>`,
  styleUrls: ["./donut-chart.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class DonutChartComponent implements OnChanges {
  @Input() data: { [key: string]: number };
  @Input() colors = ChartConstants.DefaultColors;
  @Input() width: number = ChartConstants.DefaultWidth;
  @Input() height: number = ChartConstants.DefaultHeight;
  private svg: any;
  private radius: number;
  private innerRadius: number;
  private transformedData: ChartData[];
  private pieGenerator: any;
  private arcGenerator: any;
  private colorScale: any;

  constructor(
    private elRef: ElementRef,
    private dataTransformService: DataTransformService,
    private tooltipService: TooltipService
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.data || changes.colors) {
      if (!this.data) return;

      this.transformedData = this.dataTransformService.transformData(this.data);
      this.clearExistingChart();
      this.initChart();
    }
  }

  private clearExistingChart() {
    d3.select(this.elRef.nativeElement)
      .select(".donut-chart-container")
      .html("");
  }

  private initChart() {
    this.createSVG();
    this.createTooltip();
    this.calculateDimensions();
    this.createPieGenerator();
    this.createArcGenerator();
    this.createColorScale();
    this.drawChart();
  }

  private createSVG() {
    this.svg = d3
      .select(this.elRef.nativeElement)
      .select(".donut-chart-container")
      .append("svg")
      .attr("width", this.width)
      .attr("height", this.height);
  }
  tooltip: any;
  private createTooltip() {
    this.tooltip = d3
      .select(this.elRef.nativeElement)
      .select(".donut-chart-container")
      .append("div")
      .classed("tooltip", true)
      .style("opacity", 0);
  }

  private calculateDimensions() {
    this.radius = Math.min(this.width, this.height) / 2;
    this.innerRadius = this.radius * 0.6;
  }

  private createPieGenerator() {
    this.pieGenerator = d3
      .pie<ChartData>()
      .sort(null)
      .value((d) => d.value);
  }

  private createArcGenerator() {
    this.arcGenerator = d3
      .arc<any, d3.PieArcDatum<ChartData>>()
      .outerRadius(this.radius - 10)
      .innerRadius(this.innerRadius);
  }

  private createColorScale() {
    this.colorScale = this.colors
      ? d3
          .scaleOrdinal<string, string>()
          .domain(Object.keys(this.colors))
          .range(Object.values(this.colors))
      : d3.scaleOrdinal<string>(d3[ColorScheme.SchemeCategory10]);
  }

  private drawChart() {
    const g = this.svg
      .append("g")
      .attr("transform", `translate(${this.width / 2},${this.height / 2})`);

    const arcs = g
      .selectAll(".arc")
      .data(this.pieGenerator(this.transformedData))
      .enter()
      .append("g")
      .attr("class", "arc");

    arcs
      .append("path")
      .attr("d", (d: any) => this.arcGenerator(d))
      .style("fill", (d: any) => this.colorScale(d.data.label))
      .on("mouseover", (event: MouseEvent, d: any) =>
        this.handleMouseOver(event, d, this.arcGenerator)
      )
      .on("mouseout", (event: MouseEvent, d: any) =>
        this.handleMouseOut(event, d, this.arcGenerator)
      );
  }

  private handleMouseOver(event: MouseEvent, d, arc) {
    // Enlarge the selected slice
    this.enlargeSlice(event.target as SVGPathElement, arc);
    const tooltipPosition = this.calculateTooltipPosition(event);
    this.tooltipService.showTooltip(
      d.data.label,
      d.value,
      tooltipPosition.x,
      tooltipPosition.y,
      this.colorScale(d.data.label)
    );
  }

  private handleMouseOut(event: MouseEvent, d, arc) {
    this.resetSlice(event.target as SVGPathElement, arc);
    this.tooltipService.hideTooltip();
  }

  private enlargeSlice(slice: SVGPathElement, arc) {
    d3.select(slice)
      .transition()
      .duration(200)
      .attr(
        "d",
        arc.outerRadius(this.radius - 5).innerRadius(this.innerRadius - 5)
      );
  }

  private resetSlice(slice: SVGPathElement, arc) {
    d3.select(slice)
      .transition()
      .duration(200)
      .attr(
        "d",
        arc.outerRadius(this.radius - 10).innerRadius(this.innerRadius)
      );
  }

  private calculateTooltipPosition(event: MouseEvent): {
    x: number;
    y: number;
  } {
    const svgContainer = this.elRef.nativeElement.querySelector(
      ".donut-chart-container"
    );
    const containerRect = svgContainer.getBoundingClientRect();

    const mouseX = event.clientX - containerRect.left;
    const mouseY = event.clientY - containerRect.top;

    const centerX = this.width / 2;
    const centerY = this.height / 2;
    const tooltipRadius = Math.sqrt(
      (mouseX - centerX) ** 2 + (mouseY - centerY) ** 2
    );

    const tooltipX =
      centerX + ((mouseX - centerX) / tooltipRadius) * this.radius * 0.8;
    const tooltipY =
      centerY + ((mouseY - centerY) / tooltipRadius) * this.radius * 0.8;

    return { x: tooltipX, y: tooltipY + 80 };
  }
}
