import {
  AfterViewChecked,
  Component,
  Input,
  OnChanges,
  OnInit,
  QueryList,
  SimpleChanges,
  TemplateRef,
  ViewChild,
  ViewChildren,
  inject,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { from } from "rxjs";
import { patientListAccess } from "src/app/shared/accessControl/roles";
import { MatSort } from "@angular/material/sort";
import { MatTabGroup } from "@angular/material/tabs";
import { select, Store } from "@ngrx/store";
import { ShiftsEnum } from "src/app/models/patient";
import { ShiftSortParam } from "src/app/models/patient-list";
import { AppState } from "src/app/reducers";
import { UserRolesMap } from "src/app/shared/accessControl/roleInterface";
import { patientAccess } from "src/app/shared/accessControl/roles";
import * as PatientListActions from "src/app/store/actions/patients/patient-list.action";
import * as fromHospitalView from "src/app/store/reducers/hospitals/index";
import { convertToHTTPS } from "src/app/support-functions/linkMods";
import { usersCount } from "../../models/user";
import {
  HIDDEN_WORKSPACE,
  OTHER_WORKSPACE,
} from "../patients-list/patients-list.support";
import { PatientListService } from "src/app/services/patient-list.service";
import { WorkSpace } from "src/app/models/workspace.model";
import * as _ from "lodash-es";
@Component({
  selector: "app-shift",
  templateUrl: "./shift.component.html",
  styleUrls: ["./shift.component.scss"],
})
export class ShiftComponent
  extends UserRolesMap
  implements OnInit, OnChanges, AfterViewChecked
{
  @ViewChild("assignedPatList", { static: false })
  assignedPatListToggler: MatTabGroup;
  @ViewChildren(MatSort) sorts: QueryList<MatSort>;

  @Input() currentUser;
  sortInAsc = true;
  sortCol = "unit";
  @Input() patientCount: {
    np?: usersCount[];
    rn?: usersCount[];
    docs?: usersCount[];
    registrar?: usersCount[];
    ccrn?: usersCount[];
    specialist?: usersCount[];
  } = {};
  @Input() totalPatientsCount;

  /**
   * @description - holds info about workspace assign/unassign all button
   * prototype: assignAllButtonDisabled['workspace_1'] == true  -> assignAll button will be shown
   *            assignAllButtonDisabled['workspace_1'] == false  -> unassignAll button will be shown
   */
  public assignAllButtonDisabled = {};
  public currUserIsNurse: boolean = false;
  public unitAssignLoaderList$ = this.store.pipe(
    select(fromHospitalView.getUnitAssignLoaderList)
  );

  public assignAllUnitsLoading$ = this.store.pipe(
    select(fromHospitalView.getAssignAllLoading)
  );

  public workspaceAssignLoaderList$ = this.store.pipe(
    select(fromHospitalView.getWorkspaceLoaderList)
  );

  public assignAllFlag: boolean = true;

  @Input() workSpaceInfo = [];

  shiftAssignTableColumns: string[] = [
    "hospital",
    "physician",
    "specialist",
    "registrar",
    "nurseNP",
    "nurse",
    "nurseCCRN",
    "phone",
    "speedDial",
    "anydeskId",
    "meetLink",
    "pacs",
    "lis",
    "assign",
  ];

  patientsCountColumns = ["physician", "count"];
  public searchText: string = "";
  public shift: ShiftsEnum = ShiftsEnum.DAY;
  public shiftEnum = ShiftsEnum;
  assignedPatientsCount = 0;
  showFilters: boolean = true;
  roles = patientListAccess;

  @Input() showShiftToggle = true;

  @Input() unitIdMapping = {};

  workspaceDataSource: any = {};
  dataSource(workSpaceKey: string) {
    return this.workspaceDataSource && this.workspaceDataSource[workSpaceKey];
  }

  public hiddenWorkspaceName = HIDDEN_WORKSPACE;
  public otherWorkspaceName = OTHER_WORKSPACE;
  private _patientListService = inject(PatientListService);
  public shift$ = this._patientListService.shift$;

  constructor(
    private appStore: Store<AppState>,
    private store: Store<any>,
    public dialog: MatDialog
  ) {
    super();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.unitIdMapping && !_.isEmpty(this.unitIdMapping)) {
      this.setUnitsData();
    } else {
      this.setAssignedPatientsCount();
    }
  }

  setUnitsData() {
    this.assignAllButtonDisabled = this.workSpaceInfo.reduce(
      (acc, workspace) => {
        acc[workspace.name] = true;
        return acc;
      },
      {}
    );
    this.checkIfAssignAllDisabled();
  }

  ngOnInit() {
    this.roles = this.roles.filter((role) => {
      if (
        role !== this.PHYSICIAN &&
        role !== this.CCN &&
        role !== this.NURSE &&
        role !== this.CCA &&
        role !== this.SPECIALIST &&
        role != this.REGISTRAR
      )
        return true;
      return false;
    });
  }

  ngAfterViewChecked() {
    this.assignedPatListToggler.realignInkBar();
  }

  checkIfAssignAllDisabled() {
    if (!this.workSpaceInfo.length) return;
    const isDoc = this.currentUser?.role === this.PHYSICIAN;
    const isNp = this.currentUser?.role === this.CCA;
    const isCcrn = this.currentUser?.role === this.CCN;
    const isRn = this.currentUser?.role === this.NURSE;
    const isCCAPhy = this.currentUser?.role === this.REGISTRAR;

    let assignedUnit = "rnAssigned";
    if (isDoc) assignedUnit = "docAssigned";
    else if (isNp) assignedUnit = "npAssigned";
    else if (isCcrn) assignedUnit = "ccrnAssigned";
    else if (isRn) assignedUnit = "rnAssigned";
    else if (isCCAPhy) assignedUnit = "ccaPhysicianAssigned";
    else if (this.currentUser?.role === "Dietitian")
      assignedUnit = "dietitianAssigned";
    else if (this.currentUser?.role === "Specialist")
      assignedUnit = "specialistAssigned";
    else if (this.currentUser?.role === "Lab Technician")
      assignedUnit = "labTechnicianAssigned";
    else if (this.currentUser?.role === "Pharmacologist")
      assignedUnit = "pharmacologistAssigned";
    else if (this.currentUser?.role === "Documentation Specialist")
      assignedUnit = "documentationSpecialistAssigned";
    else if (this.currentUser?.role === "Physical Therapist")
      assignedUnit = "physicalTherapistAssigned";
    else if (this.currentUser?.role === "Physician Associate")
      assignedUnit = "physicianAssociateAssigned";
    else if (this.currentUser?.role === "Occupational Therapist")
      assignedUnit = "occupationalTherapistAssigned";
    else if (this.currentUser?.role === "Respiratory Therapist")
      assignedUnit = "respiratoryTherapistAssigned";
    else if (this.currentUser?.role === "Data Entry Associate")
      assignedUnit = "dataEntryAssociateAssigned";

    this.setAssignButtonText();
    this.setAssignedPatientsCount();

    this.setAssignAll(assignedUnit);
  }

  /**
   *
   * @param assignedUnit - current role obj
   * @description - if find any unit assigned to me make assignAllButtonDisabled['workspace_1'] = false => making it 'unassign all'
   *              - else assignAllButtonDisabled['workspace_2'] = true => making it 'assign all'
   */
  setAssignAll(assignedUnit) {
    this.workSpaceInfo.forEach((workspace: WorkSpace) => {
      const { name, unitIds } = workspace;
      this.assignAllButtonDisabled[name] =
        unitIds?.length &&
        !unitIds.some((unitId) => {
          const unit = this.unitIdMapping[unitId];
          if (!unit) return;
          let aUnit = unit[assignedUnit];

          return !!unit.active && aUnit?.email === this.currentUser?.email;
        });
    });
  }

  onAssign(unit, isAssign) {
    let data = {
      unit: unit._id,
      currentUser: this.currentUser.name,
      role: this.currentUser.role,
      timestamp: new Date(),
      email: this.currentUser.email,
      isAssign,
    };

    this.appStore.dispatch(PatientListActions.assignShift({ shift: data }));
  }

  assignAllUnits(isAssign) {
    const req = {
      name: this.currentUser.name,
      role: this.currentUser.role,
      email: this.currentUser.email,
      isAssign,
    };
    this.appStore.dispatch(PatientListActions.assignAllShift(req));
  }

  assignAllWorkspace(isAssign, workspaceId) {
    const workspace = {
      workspaceId,
      isAssign,
    };
    this.appStore.dispatch(
      PatientListActions.assignAllWorkspace({ workspace })
    );
  }

  callToPhone(val) {
    let el = document.getElementsByClassName(val)[0];
    let range = document.createRange();
    range.selectNodeContents(el);
    let sel = window.getSelection();
    sel.removeAllRanges();
    sel.addRange(range);
  }

  openAssignAllConfirmationModal(
    content: TemplateRef<any>,
    workSpaceId,
    workspaceName
  ) {
    const dialogRef = this.dialog.open(content, {
      height: "188px",
      width: "325px",
      autoFocus: false,
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result == "yes") {
        const setWorksSpaceAll =
          this.assignAllButtonDisabled[this.worksSpaceName];
        workspaceName == this.hiddenWorkspaceName
          ? this.assignAllUnits(this.assignAllFlag)
          : this.assignAllWorkspace(setWorksSpaceAll, workSpaceId);
      }
    });
  }

  private _workSpace: string;

  set worksSpaceName(name: string) {
    this._workSpace = name;
  }

  get worksSpaceName() {
    return this._workSpace;
  }

  get contentToDisplay() {
    if (this.worksSpaceName === this.hiddenWorkspaceName)
      return "Assign to all units?";

    return !!this.assignAllButtonDisabled[this.worksSpaceName]
      ? `Are you sure you want to assign all the units of ${this.worksSpaceName}?`
      : `Are you sure you want to unassign all the units of ${this.worksSpaceName}?`;
  }

  get actionToDo() {
    return !!this.assignAllButtonDisabled[this.worksSpaceName]
      ? `Assign`
      : `Unassign`;
  }

  setAssignedPatientsCount() {
    let role = "";
    switch (this.currentUser.role) {
      case this.PHYSICIAN:
        role = "docs";
        break;

      case this.CCA:
        this.currUserIsNurse = true;
        role = "np";
        break;

      case this.CCN:
        this.currUserIsNurse = true;
        role = "ccrn";
        break;

      case this.NURSE:
        this.currUserIsNurse = true;
        role = "rn";
        break;

      case this.DIETITIAN:
        role = "dietitian";
        break;

      case this.SPECIALIST:
        role = "specialist";
        break;

      case this.PHARMACOLOGIST:
        role = "pharmacologist";
        break;

      case this.LT:
        role = "labTechnician";
        break;

      case this.DS:
        role = "documentationSpecialist";
        break;

      case this.PT:
        role = "physicalTherapist";
        break;

      case this.PA:
        role = "physicianAssociate";
        break;

      case this.OT:
        role = "occupationalTherapist";
        break;

      case this.RT:
        role = "respiratoryTherapist";
        break;

      case this.DEA:
        role = "dataEntryAssociate";
        break;

      case this.REGISTRAR:
        role = "registrar";
        break;
    }

    this.assignedPatientsCount =
      this.patientCount[role]?.find(
        (user) => user.email === this.currentUser.email
      )?.count || 0;
  }

  setAssignButtonText() {
    this.workSpaceInfo.forEach((workspace: WorkSpace) => {
      const { unitIds } = workspace;
      unitIds.forEach((unitId) => {
        const unit = this.unitIdMapping[unitId];
        if (!unit) return;
        switch (this.currentUser.email + this.currentUser.role) {
          case unit?.rnAssigned?.email + this.NURSE:
          case unit?.ccrnAssigned?.email + this.CCN:
          case unit?.npAssigned?.email + this.CCA:
          case unit?.docAssigned?.email + this.PHYSICIAN:
          case unit?.dietitianAssigned?.email + this.DIETITIAN:
          case unit?.specialistAssigned?.email + this.SPECIALIST:
          case unit?.pharmacologistAssigned?.email + this.PHARMACOLOGIST:
          case unit?.labTechnicianAssigned?.email + this.LT:
          case unit?.documentationSpecialistAssigned?.email + this.DS:
          case unit?.physicalTherapistAssigned?.email + this.PT:
          case unit?.physicianAssociateAssigned?.email + this.PA:
          case unit?.occupationalTherapistAssigned?.email + this.OT:
          case unit?.respiratoryTherapistAssigned?.email + this.RT:
          case unit?.dataEntryAssociateAssigned?.email + this.DEA:
          case unit?.ccaPhysicianAssigned?.email + this.REGISTRAR:
            unit["assignButtonText"] = "Unassign";
            break;

          default:
            unit["assignButtonText"] = "Assign";
            break;
        }
      });
    });
  }

  updateLink(link: string): string {
    return convertToHTTPS(link);
  }

  // old - code
  sortParam: ShiftSortParam;
  onSort(sortEvent: any, workspaceName) {
    this.sortParam = {
      type: sortEvent.active,
      direction: sortEvent.direction,
      workspace: workspaceName,
    };
  }

  identify(_, workspace: WorkSpace) {
    return workspace.name;
  }
}
