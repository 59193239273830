import { createAction, props } from "@ngrx/store";

const SHOW_ERR = "[DASHBOARD VIEW] show error";
const SHOW_SUCC = "[DASHBOARD VIEW] show success";
const UPDATE_LOADER = "[DASHBOARD VIEW] update loader status";

export const showError = createAction(SHOW_ERR, props<{ err: string }>());
export const showSuccess = createAction(SHOW_SUCC, props<{ msg: string }>());
export const updateLoaderStatus = createAction(
  UPDATE_LOADER,
  props<{ status: boolean }>()
);
