<div class="date-time-picker-comp" #datePickerRef>
  <ng-container [ngSwitch]="designTemplate">
    <ng-container *ngSwitchDefault>
      <div class="date-range-picker-cont" *ngIf="dateRange">
        <mat-form-field class="">
          <div class="date-range-label"><b>Date Range : </b></div>
          <mat-date-range-input
            [formGroup]="range"
            [rangePicker]="rangePicker"
            [min]="minValidDate"
            [max]="maxValidDate"
          >
            <mat-label></mat-label>
            <input
              (dateChange)="onDateRangeChange()"
              matStartDate
              placeholder="Start date"
              formControlName="start"
              class="date-range-input"
            />
            <mat-label></mat-label>
            <input
              (dateChange)="onDateRangeChange()"
              matEndDate
              placeholder="End date"
              formControlName="end"
              class="date-range-input"
            />
          </mat-date-range-input>
          <mat-datepicker-toggle matPrefix [for]="rangePicker">
            <!-- <mat-icon
              matDatepickerToggleIcon
              svgIcon="cp-calender"
              aria-hidden="false"
              aria-label="calender icon"
            >
            </mat-icon> -->
            <mat-icon aria-label="calender icon">date_range</mat-icon>
          </mat-datepicker-toggle>
          <mat-date-range-picker #rangePicker></mat-date-range-picker>
        </mat-form-field>
      </div>

      <div class="date-picker-cont" *ngIf="!dateRange">
        <div class="date-label" [ngClass]="!isPadding ? 'date-label' : ''">
          <b>{{ dateLabel || "Date:" }} </b>
          <cp-timezone-label
            *ngIf="timeZoneDetail?.name"
            [timeZoneDetail]="timeZoneDetail"
          ></cp-timezone-label>
        </div>
        <ng-container *ngTemplateOutlet="datePickerBlock"></ng-container>
      </div>

      <div class="time-picker-cont" *ngIf="showTime">
        <div class="time-label" [ngClass]="!isPadding ? 'time-label' : ''">
          <b>{{ timeLabel || "Time:" }}</b>
          <cp-timezone-label
            *ngIf="timeZoneDetail?.name"
            [timeZoneDetail]="timeZoneDetail"
          ></cp-timezone-label>
        </div>

        <ng-container *ngTemplateOutlet="timePickerBlock"></ng-container>
      </div>

      <div class="now-button" *ngIf="showNowButton">
        <button
          type="button"
          mat-flat-button
          class="button-primary"
          (click)="onNowClick()"
          [disabled]="disabled"
        >
          Now
        </button>
      </div>
    </ng-container>

    <!-- DATE PICKER DESIGN FOR INFECTION BUNDLE - CAUTI -->
    <ng-container *ngSwitchCase="designTemplates.INFECTION_BUNDLE">
      <div class="infection-bundle-design">
        <div class="date-picker-cont">
          <ng-container *ngTemplateOutlet="datePickerBlock"></ng-container>
        </div>
        <mat-divider [vertical]="true" class="divider"></mat-divider>
        <ng-container *ngTemplateOutlet="timePickerBlock"></ng-container>
      </div>
    </ng-container>
  </ng-container>
</div>

<ng-template #datePickerBlock>
  <div class="date-picker">
    <mat-form-field floatLabel="always">
      <mat-label></mat-label>
      <input
        matInput
        [disabled]="disabled"
        class="date-input"
        [min]="minValidDate"
        [max]="maxValidDate"
        placeholder="yyyy/mm/dd"
        #dateField="ngModel"
        [(ngModel)]="date"
        [matDatepicker]="picker"
        (dateChange)="onDateChange($event)"
      />
      <mat-datepicker-toggle matPrefix [for]="picker">
        <!-- <mat-icon
            matDatepickerToggleIcon
            svgIcon="cp-calender"
            aria-hidden="false"
            aria-label="calender icon"
          >
          </mat-icon> -->
        <mat-icon aria-label="calender icon">date_range</mat-icon>
      </mat-datepicker-toggle>
      <mat-datepicker
        #picker
        (opened)="openedStrem()"
        (closed)="closedStream()"
      ></mat-datepicker>
    </mat-form-field>
  </div>
</ng-template>

<ng-template #timePickerBlock>
  <div class="time-picker">
    <div class="hour-picker">
      <mat-form-field>
        <!-- <img matPrefix class="time-icon" src="assets/icons/time.svg" /> -->
        <mat-label></mat-label>

        <input
          type="text"
          pattern="\d*"
          maxlength="2"
          [(ngModel)]="hour"
          (change)="onHourChange()"
          matInput
          [disabled]="disabled"
          placeholder="HR"
          class="hour-input"
        />
      </mat-form-field>

      <!-- <div class="hour-label">HR</div> -->
    </div>
    <div class="separator">:</div>
    <div class="minute-picker">
      <mat-form-field>
        <!-- <img matPrefix class="time-icon" src="assets/icons/time.svg" /> -->
        <mat-label></mat-label>
        <input
          type="text"
          pattern="\d*"
          maxlength="2"
          [(ngModel)]="minute"
          (change)="onMinuteChange()"
          matInput
          [disabled]="disabled"
          placeholder="MM"
          class="minute-input"
        />
      </mat-form-field>

      <!-- <div class="min-label">MIN</div> -->
    </div>
  </div>
</ng-template>
