import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
  ViewEncapsulation,
} from "@angular/core";
import { ClinicianInterface } from "@shared-modules/features/shift-assign-dashboard/model";

@Component({
  selector: "cp-user-expansion-view",
  templateUrl: "./user-expansion-view.component.html",
  styleUrls: ["./user-expansion-view.component.scss"],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserExpansionViewComponent implements OnInit {
  @Input() userData: ClinicianInterface;

  constructor() {}

  ngOnInit(): void {}
}
