import {
  ActionReducerMap,
  createFeatureSelector,
  createSelector,
} from "@ngrx/store";
import * as fromLabsForm from "./lab-forms.reducer";
import * as fromLabDocs from "./lab-documents.reducer";
import * as fromUploadFiles from "./upload-file.reducer";
import * as fromLabRequest from "./lab-request.reducer";
import * as fromLabScanDocuments from "./lab-scan-documents.reducer";

export interface LabState {
  labForms: fromLabsForm.LabFormState;
  labDocuments: fromLabDocs.LabDocumentState;
  uploadFiles: fromUploadFiles.UploadFileState;
  labRequest: fromLabRequest.LabRequestState;
  labScanDocuments: fromLabScanDocuments.DocumentsState;
}

export const reducers: ActionReducerMap<LabState> = {
  labForms: fromLabsForm.reducer,
  labDocuments: fromLabDocs.reducer,
  uploadFiles: fromUploadFiles.reducer,
  labRequest: fromLabRequest.reducer,
  labScanDocuments: fromLabScanDocuments.reducer,
};

export const getLabState = createFeatureSelector<LabState>("labs");
export const getLabsForms = createSelector(
  getLabState,
  (state: LabState) => state.labForms
);
export const getDocs = createSelector(
  getLabState,
  (state: LabState) => state.labDocuments
);

export const getFile = createSelector(
  getLabState,
  (state: LabState) => state.uploadFiles
);

export const getLabRequest = createSelector(
  getLabState,
  (state: LabState) => state.labRequest
);

export const getAllLabForm = createSelector(
  getLabsForms,
  fromLabsForm.getLabFormEntities
);
export const getLabFormLoaded = createSelector(
  getLabsForms,
  fromLabsForm.getLabFormLoaded
);
export const getLabsformLoading = createSelector(
  getLabsForms,
  fromLabsForm.getLabFormLoading
);

export const getAllLabs = createSelector(getDocs, fromLabDocs.getLabEntities);

export const getAllScans = createSelector(getDocs, fromLabDocs.getScanEntities);

export const getAllLabDocument = createSelector(
  getDocs,
  fromLabDocs.getLabDocumentEntities
);

export const getAllDocuments = createSelector(
  getDocs,
  fromLabDocs.getDocumentEntities
);
export const getLabDocumentLoaded = createSelector(
  getDocs,
  fromLabDocs.getLabDocumentLoaded
);
export const getLabsDocumentLoading = createSelector(
  getDocs,
  fromLabDocs.getLabDocumentLoading
);

export const getAllUploadFile = createSelector(
  getFile,
  fromUploadFiles.getUploadFileEntities
);
export const getUploadFileLoaded = createSelector(
  getFile,
  fromUploadFiles.getUploadFileLoaded
);
export const getUploadFileLoading = createSelector(
  getFile,
  fromUploadFiles.getUploadFileLoading
);

export const getUploadFileGcpUploads = createSelector(
  getFile,
  fromUploadFiles.getUploadFileGcpUploads
);

export const getAllLabRequest = createSelector(
  getLabRequest,
  fromLabRequest.getLabRequestEntities
);
export const getAllLabRequestLoaded = createSelector(
  getLabRequest,
  fromLabRequest.getLabRequestLoaded
);
export const getAllLabRequestLoading = createSelector(
  getLabRequest,
  fromLabRequest.getLabRequestLoading
);

export const getAllLabRequestErrorMsg = createSelector(
  getLabRequest,
  fromLabRequest.getLabRequestErrorMsg
);

export const getLabsState = createFeatureSelector<LabState>("lab");
