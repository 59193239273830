export enum GlobalRoutingConfig {
  LOGIN = "login",
  PATIENT_CHART = "patient/:patientCPMRN/:patientEncounters",
  VITALS = "vitals",
  ASSESSMENT = "assessment",
  ORDERS = "orders",
  MAR = "mar",
  IO = "intake-output",
  NOTES = "notes",
  LABS = "labs",
  SUMMARY = "summary",
  PATIENT_LIST = "patientlist",
  SHIFT_ASSIGN = "siftAssign",
  ADMIT_PATIENT = "admitPatient",
  AUDIT = "audit",
  IRIS_DESIGN_SYSTEM = "iris-design-system",
  MANAGEMENT = "management",
  BILLING = "billing",
  TV = "tv",
  MED_ADMIN = "med-admin",
}
