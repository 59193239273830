<div class="stack-bar-container display-flex">
  <ng-container *ngIf="stackBarData; else noStackedBarBlock">
    <div
      class="stack-bar"
      [ngClass]="[stackBarClassMap[stackObj?.key] || 'stack-bar--default']"
      [ngStyle]="{
        width: ((stackObj?.value / totalCount) * 100 | number : '1.0-0') + '%'
      }"
      *ngFor="let stackObj of stackBarData | keyvalue : returnZero; index as i"
      popoverTrigger="hover"
      cpPopover="{{ stackObj?.key }}: {{ stackObj?.value }}"
      position="top-center"
    ></div>
  </ng-container>
  <ng-template #noStackedBarBlock>
    <div class="stack-bar stack-bar--default"></div>
  </ng-template>
</div>
