import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
  inject,
} from "@angular/core";
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";
import { Subject } from "rxjs";
import * as fromDashboardDataStore from "../../store";
import { Store, select } from "@ngrx/store";
import { debounceTime, skip, takeUntil } from "rxjs/operators";
import * as fromShiftAssignDashboardStore from "../../store";
import { socket } from "../../../../../config/socket";

@Component({
  selector: "app-shift-assign-dashboard",
  templateUrl: "./shift-assign-dashboard.component.html",
  styleUrls: ["./shift-assign-dashboard.component.scss"],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShiftAssignDashboardComponent implements OnInit, OnDestroy {
  private _unsubscribe$ = new Subject();

  public dashboardLoading$ = this._shiftDashboardStore.pipe(
    select(fromDashboardDataStore.getLoaderStatus),
    takeUntil(this._unsubscribe$)
  );

  public patientCount$ = this._shiftDashboardStore.pipe(
    select(fromDashboardDataStore.getPatientCountG),
    takeUntil(this._unsubscribe$)
  );

  public severity$ = this._shiftDashboardStore.pipe(
    select(fromDashboardDataStore.getSeverityG),
    takeUntil(this._unsubscribe$)
  );

  public workspaceCount$ = this._shiftDashboardStore.pipe(
    select(fromDashboardDataStore.getWorkspaceCountG),
    takeUntil(this._unsubscribe$)
  );

  public hospitalCount$ = this._shiftDashboardStore.pipe(
    select(fromDashboardDataStore.getHospitalCountG),
    takeUntil(this._unsubscribe$)
  );

  public unitCount$ = this._shiftDashboardStore.pipe(
    select(fromDashboardDataStore.getUnitCountG),
    takeUntil(this._unsubscribe$)
  );

  public bedCount$ = this._shiftDashboardStore.pipe(
    select(fromDashboardDataStore.getBedCountG),
    takeUntil(this._unsubscribe$)
  );

  public physicianCount$ = this._shiftDashboardStore.pipe(
    select(fromDashboardDataStore.getPhysicianCountG),
    takeUntil(this._unsubscribe$)
  );

  public specialistCount$ = this._shiftDashboardStore.pipe(
    select(fromDashboardDataStore.getSpecialistCount),
    takeUntil(this._unsubscribe$)
  );

  public ccaCount$ = this._shiftDashboardStore.pipe(
    select(fromDashboardDataStore.getCCACountG),
    takeUntil(this._unsubscribe$)
  );

  public ccrnCount$ = this._shiftDashboardStore.pipe(
    select(fromDashboardDataStore.getCCRNCountG),
    takeUntil(this._unsubscribe$)
  );

  public rnCount$ = this._shiftDashboardStore.pipe(
    select(fromDashboardDataStore.getRNCountG),
    takeUntil(this._unsubscribe$)
  );

  public workspaces$ = this._shiftDashboardStore.pipe(
    select(fromDashboardDataStore.getAllWorkspaces),
    takeUntil(this._unsubscribe$)
  );

  // donutData = {
  //   NA: 125,
  //   "Comfort Care": 125,
  //   Fair: 150,
  //   Unstable: 100,
  //   Watcher: 25,
  // };

  donutDataColors = {
    NA: "#8B94B3",
    "Comfort Care": "#8EE6C0",
    Fair: "#FAC02E",
    Unstable: "#EB4049",
    Watcher: "#7FCECE",
  };

  triggerApiFromSocket$: Subject<any> = new Subject();

  constructor(
    private _matIconRegistry: MatIconRegistry,
    private _domSanitizer: DomSanitizer,
    private _shiftDashboardStore: Store<fromDashboardDataStore.DashboardActionReducerMapState>
  ) {
    this._matIconRegistry.addSvgIcon(
      "cp-avatar",
      this._domSanitizer.bypassSecurityTrustResourceUrl(
        "assets/icons/avatar.svg"
      )
    );
    this._matIconRegistry.addSvgIcon(
      "cp-hospital-logo-1",
      this._domSanitizer.bypassSecurityTrustResourceUrl(
        "assets/icons/hospital_logo_1.svg"
      )
    );
  }

  ngOnInit(): void {
    this._shiftDashboardStore.dispatch(
      fromShiftAssignDashboardStore.getDashboardData({})
    );

    this.setSockets();

    this.triggerApiFromSocket$
      .pipe(debounceTime(1000), takeUntil(this._unsubscribe$))
      .subscribe((_) => {
        this._shiftDashboardStore.dispatch(
          fromShiftAssignDashboardStore.getDashboardData({
            isShowLoader: false,
          })
        );
      });
  }

  setSockets() {
    socket.on("shift-assignment-dashboard", () => {
      this.triggerApiFromSocket$.next();
    });
  }

  ngOnDestroy(): void {
    this._unsubscribe$.next();
    this._unsubscribe$.complete();
  }
}
