import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { AvatarModule } from "./avatar";
import { BreadcrumbModule } from "./breadcrumb";
import { BtnTagModule } from "./btn-tag";
import {
  ZoomPanContainerModule,
  ZoomTransformService,
} from "./zoom-pan-container";
import { CarouselModule } from "./carousel";
import { ChoiceListModule } from "./choice-list";
import { ConfimDialogModule, DialogService } from "./confim-dialog";
import { CounterModule } from "./counter";
import { LabelBadgeModule } from "./label-badge";
import { OverlayModule } from "./overlay";
import { PopoverModule } from "./popover";
import { ProgressBarModule } from "./progress-bar";
import { AlertModule } from "./alert";
import { DateTimePickerModule } from "./date-time-picker";
import { AlertInlineModule } from "./alert-inline";

import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatSelectModule } from "@angular/material/select";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatButtonModule } from "@angular/material/button";
import { MatTabsModule } from "@angular/material/tabs";
import { MatSortModule } from "@angular/material/sort";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatPaginatorModule } from "@angular/material/paginator";
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MatRippleModule,
} from "@angular/material/core";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatMenuModule } from "@angular/material/menu";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatExpansionModule } from "@angular/material/expansion";
import { IconModule } from "../icon/icon.module";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { MatTableModule } from "@angular/material/table";
import { MatDialogModule } from "@angular/material/dialog";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatChipsModule } from "@angular/material/chips";
import { PortalModule } from "@angular/cdk/portal";
import { TimezoneLabelModule } from "@shared-modules/components/timezone-label/timezone-label.module";
import { MatListModule } from "@angular/material/list";
import { HammerModule } from "@angular/platform-browser";
import { LoaderModule } from "./loader/loader.module";
import { MatMomentDateModule } from "@angular/material-moment-adapter";
import { PatientCardModule } from "@shared-modules/components/patient-card/patient-card.module";
import { MatDividerModule } from "@angular/material/divider";
import { MatSliderModule } from "@angular/material/slider";

const CustomComponents = [
  AlertModule,
  AlertInlineModule,
  AvatarModule,
  BreadcrumbModule,
  BtnTagModule,
  CarouselModule,
  ZoomPanContainerModule,
  ChoiceListModule,
  ConfimDialogModule,
  CounterModule,
  LabelBadgeModule,
  LoaderModule,
  OverlayModule,
  PopoverModule,
  ProgressBarModule,
  DateTimePickerModule,
];

const MaterialComponents = [
  MatIconModule,
  MatInputModule,
  ReactiveFormsModule,
  MatSelectModule,
  MatListModule,
  MatFormFieldModule,
  MatDividerModule,
  MatDialogModule,
  MatButtonModule,
  MatTabsModule,
  MatSortModule,
  MatAutocompleteModule,
  MatPaginatorModule,
  MatRippleModule,
  MatProgressSpinnerModule,
  MatTooltipModule,
  MatDatepickerModule,
  MatButtonToggleModule,
  MatTableModule,
  MatDividerModule,
  MatDialogModule,
  MatCheckboxModule,
  MatExpansionModule,
  MatChipsModule,
  PortalModule,
  MatSlideToggleModule,
  MatMenuModule,
  MatMomentDateModule,
  MatExpansionModule,
  PortalModule,
  MatSliderModule,
];

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    ...CustomComponents,
    ...MaterialComponents,
    HammerModule,
    PatientCardModule,
    ReactiveFormsModule,
    TimezoneLabelModule,
  ],
  exports: [CommonModule, ...MaterialComponents, ...CustomComponents],
  providers: [
    {
      provide: MAT_DATE_FORMATS,
      useValue: {
        parse: {
          dateInput: "YYYY/MM/DD",
        },
        display: {
          dateInput: "YYYY/MM/DD",
          monthYearLabel: "MMM YYYY",
          dateA11yLabel: "LL",
          monthYearA11yLabel: "MMMM YYYY",
        },
      },
    },
  ],
})
export class IrisComponentsModule {}
