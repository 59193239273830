import { ActionReducerMap, createFeatureSelector } from "@ngrx/store";
import { DashboardActionReducerMapState } from "../state";
import { reducer as dashboardDataReducer } from "./dashboard-data.reducer";
import { reducer as dashboardViewReducer } from "./dashboard-view.reducer";

export const reducer: ActionReducerMap<DashboardActionReducerMapState> = {
  data: dashboardDataReducer,
  view: dashboardViewReducer,
};

export const getDashboardState =
  createFeatureSelector<DashboardActionReducerMapState>("dashboards");
