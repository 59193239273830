import { Injectable } from "@angular/core";
import { Actions, ofType, Effect } from "@ngrx/effects";
import { EMPTY, of } from "rxjs";
import { catchError, map, switchMap } from "rxjs/operators";
import * as finalNotesActions from "../actions";
import * as fromloaderStateReducer from "@shared-modules/store";
import { Store } from "@ngrx/store";
import { NoteService } from "@notes-view/services/note.service";
import { AlertService } from "@iris/alert";

@Injectable()
export class FinalNoteEffects {
  constructor(
    private actions$: Actions,
    private _alertService: AlertService,
    private _noteService: NoteService,
    private _loaderStore: Store<fromloaderStateReducer.ShareState>
  ) {}

  @Effect()
  deleteFinalNote$ = this.actions$.pipe(
    ofType(finalNotesActions.deleteFinalNote),
    switchMap(({ refId }) => {
      return this._noteService.deletePendedNote(refId).pipe(
        map((code: any) => {
          this._alertService.showNotification({
            type: "Success",
            message: "Notes deleted successfully!",
          });
          return fromloaderStateReducer.setLoadingSpinner({ status: false });
        }),
        catchError((error) => {
          this._loaderStore.dispatch(
            fromloaderStateReducer.setLoadingSpinner({ status: false })
          );
          this._alertService.showNotification({
            type: "Error",
            message: error.error.message,
          });
          return EMPTY;
        })
      );
    })
  );
}
