export interface DashboardCountsInterface {
  patientCount: number;
  severity: SeverityInterface;
  workspaceCount: number;
  hospitalCount: number;
  unitCount: number;
  bedCount: number;
  physicianCount: number;
  specialistCount: number;
  ccaCount: number;
  ccrnCount: number;
  rnCount: number;
}

export interface WorkspaceInterface {
  workspaceId: string;
  name: string;
  patientCount: number;
  severity: SeverityInterface;
  hospitalCount: number;
  unitCount: number;
  clinicianCount: number;
  clinicians: ClinicianInterface[];
  hospitals: WorkspaceHospitalInterface[];
}

export interface DashboardInfoInterface extends DashboardCountsInterface {
  workspaces: WorkspaceInterface[];
}

export interface SeverityInterface {
  [SEVERITY_TYPES.NA]: number;
  [SEVERITY_TYPES.Fair]: number;
  [SEVERITY_TYPES.Unstable]: number;
  [SEVERITY_TYPES.Watcher]: number;
  [SEVERITY_TYPES.ComfortCare]: number;
}

export enum SEVERITY_TYPES {
  NA = "NA",
  Fair = "Fair",
  Unstable = "Unstable",
  Watcher = "Watcher",
  ComfortCare = "Comfort Care",
}

export interface ClinicianInterface {
  name: string;
  assignedRole: string;
  multipleWorkspace: boolean;
  patientCount: number;
  hospitals: WorkspaceClinicianHospital[];
}

export interface WorkspaceClinicianHospital {
  name: string;
  units: ClinicianUnit[];
}

export interface ClinicianUnit {
  name: string;
  patientCount: number;
  beds: string[];
}

export interface WorkspaceHospitalInterface {
  name: string;
  patientCount: number;
  units: Unit[];
  clinicians: HospitalClinicianInterface[];
}

export interface Unit {
  name: string;
  patientCount: number;
}

export interface HospitalClinicianInterface {
  name: string;
  assignedRole: string;
  units: string[];
}
