import { Inject, Injectable, Optional } from "@angular/core";
import { MomentDateAdapter } from "@angular/material-moment-adapter";
import { MAT_DATE_LOCALE } from "@angular/material/core";
import { TimezoneService } from "./services/timezone.service";
import moment from "moment";
import { Moment } from "moment-timezone";

import { defaultTimeZone } from "src/app/data/timezone.data";
import { getDateString } from "./support-functions/util";

@Injectable({
  providedIn: "root",
})
export class CustomDateAdapterServiceService extends MomentDateAdapter {
  constructor(
    @Optional() @Inject(MAT_DATE_LOCALE) dateLocale: string,
    @Optional() private _tz: TimezoneService
  ) {
    super(dateLocale);
  }

  createDate(year: number, month: number, date: number): Moment {
    // Moment.js will create an invalid date if any of the components are out of bounds, but we
    // explicitly check each case so we can throw more descriptive errors.

    if (month < 0 || month > 11) {
      throw Error(
        `Invalid month index "${month}". Month index has to be between 0 and 11.`
      );
    }

    if (date < 1) {
      throw Error(`Invalid date "${date}". Date has to be greater than 0.`);
    }

    const dateString = getDateString(year, month, date);

    const result = moment.tz(dateString, this._getTimezone);

    return result;
  }

  today(): Moment {
    return moment().utc().tz(this._getTimezone).local(this.locale);
  }

  parse(value: any, parseFormat: string | string[]): Moment | null {
    if (value && typeof value == "string") {
      return this._createMoment2(value, parseFormat, this.locale);
    }
    return value ? this._createMoment2(value).locale(this.locale) : null;
  }

  /**
   * Returns the given value if given a valid Moment or null. Deserializes valid ISO 8601 strings
   * (https://www.ietf.org/rfc/rfc3339.txt) and valid Date objects into valid Moments and empty
   * string into null. Returns an invalid date for all other values.
   */
  deserialize(value: any): Moment | null {
    let date;
    if (value instanceof Date) {
      date = this._createMoment2(value).locale(this.locale);
    } else if (this.isDateInstance(value)) {
      // Note: assumes that cloning also sets the correct locale.
      return this.clone(value);
    }
    if (typeof value === "string") {
      if (!value) {
        return null;
      }
      date = this._createMoment2(value, moment.ISO_8601).locale(this.locale);
    }
    if (date && this.isValid(date)) {
      return this._createMoment2(date).locale(this.locale);
    }
    return super.deserialize(value);
  }

  /** Creates a Moment instance while respecting the current UTC settings. */
  private _createMoment2(
    date: moment.MomentInput,
    format?: moment.MomentFormatSpecification,
    locale?: string
  ): moment.Moment {
    const date2 = moment(date, format, locale);
    const dateFormatted = moment(date, format, locale).format("YYYY-MM-DD");
    if (dateFormatted === "Invalid date") return date2;
    return moment.tz(date2, this._getTimezone);
  }

  public get _getTimezone() {
    return this._tz?.patientTimeZone() || defaultTimeZone;
  }
}
