<div class="summary-comp">
  <div class="summary-options-select">
    <div class="current-summary">
      <mat-form-field>
        <mat-label> <b> Current summary: </b> </mat-label>

        <mat-select
          #currSummSelect
          [(value)]="selectedCurrSummary"
          class="select-input mr-1"
          (selectionChange)="setSummary($event.value)"
        >
          <mat-option value="0" selected
            >Progress note/Admission note with meds</mat-option
          >
          <mat-option value="1"
            >Progress note/Admission note without meds</mat-option
          >
          <mat-option value="2">Active medications</mat-option>
          <mat-option value="3">Shift summary with meds</mat-option>
          <mat-option value="4">Shift summary without meds</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div *ngxPermissionsOnly="['get_summary']" class="previous-summary">
      <div class="no-prev-summ" *ngIf="!oldSummary?.length">
        No previous summaries available
      </div>
      <mat-form-field *ngIf="oldSummary?.length">
        <mat-label> <b>Previous summaries: </b></mat-label>
        <mat-select
          class="select-input mr-1"
          name="old_pdf"
          id="old_pdf"
          [(ngModel)]="pastSummary"
        >
          <mat-option
            *ngFor="let summary of oldSummary"
            value="{{ summary.file }}"
            (click)="openPdf()"
          >
            {{ summary.timestamp | timezone : "DD-MM-YYYY" }}
            {{ summary.timestamp | timezone : "H-mm" }} hrs
            <cp-timezone-label
              *ngIf="patTimeZoneDetail$ | async; let patTimeZoneDetail"
              [timeZoneDetail]="patTimeZoneDetail"
            ></cp-timezone-label>
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="float-right">
      <span class="text-danger" *ngIf="restError">
        Server down! Please try after some time.
      </span>
    </div>
    <div class="clear-height"></div>
  </div>

  <div class="summary_wrapper" #summaryWrapper id="summaryWrapper">
    <div class="header-section">
      <div class="header">
        <b>CRITICAL CARE PROGRESS NOTE</b>
      </div>

      <div class="hospitalLogo">
        <img
          id="hospital_image"
          [src]="patInfo['hospitalLogo']"
          alt="Hospital Logo"
          onerror="this.onerror = null; this.src='assets/icons/hospital_logo.svg'"
        />
      </div>
    </div>

    <div class="detailed-summary">
      <div class="patient-info bg-light" #patInfoTable>
        <div class="name-image-cont">
          <div class="image-cont">
            <app-avatar [avatarParams]="patientAvatar"></app-avatar>
          </div>
          <div class="name-cont">
            <div class="patient-name">
              {{ patInfo.name + " " }} {{ patInfo?.lastName || "" }}
            </div>
            <div>
              <span>{{ getPatientAge() }}</span>
              <span *ngIf="patInfo.sex === 'O'">
                <fa-icon
                  [icon]="['fas', 'transgender']"
                  title="Other"
                ></fa-icon>
              </span>
              <span *ngIf="patInfo.sex !== 'O'">
                {{
                  patInfo.sex === "M"
                    ? "Male"
                    : patInfo.sex === "F"
                    ? "Female"
                    : ""
                }}</span
              >
            </div>
            <div class="discharge-label" *ngIf="isPatientDischarged">
              Discharged
            </div>
          </div>
        </div>

        <mat-divider [vertical]="true" class="divider"></mat-divider>

        <div class="hospital-info">
          <div class="logo">
            <img
              class="hosp-logo"
              [src]="patInfo.hospitalLogo"
              alt="Hospital Logo"
              onerror="this.onerror = null; this.src='assets/icons/hospital_logo.svg'"
            />
          </div>
          <div class="hosp-address">{{ patInfo.hospitalName }}</div>
        </div>

        <mat-divider [vertical]="true" class="divider"></mat-divider>
        <div class="id-info">
          <div class="labels">
            <span>Bed no: </span>
            <span>CPMRN: </span>
            <span>MRN: </span>
            <span>Admit date:</span>
          </div>
          <div class="values">
            <span>{{ patInfo.bedNo || "-" }}</span>
            <span>{{ patInfo.CPMRN || "-" }}</span>
            <span>{{ patInfo.MRN || "-" }}</span>
            <span
              >{{ patInfo.ICUAdmitDate | timezone : "MMM D Y, HH:mm" || "-" }}
              <cp-timezone-label
                *ngIf="patTimeZoneDetail$ | async; let patTimeZoneDetail"
                [timeZoneDetail]="patTimeZoneDetail"
              ></cp-timezone-label>
            </span>
          </div>
        </div>

        <mat-divider [vertical]="true" class="divider"></mat-divider>

        <div class="badges">
          <span
            class="qSofa cp-badge"
            [ngClass]="{
              'cp-clr-misc-p': patInfo.qsofa == 1,
              'cp-clr-danger': patInfo.qsofa >= 2,
              'cp-clr-misc-s': patInfo.qsofa < 1
            }"
          >
            <strong>qSOFA: {{ patInfo.qsofa ? patInfo.qsofa : 0 }}</strong>
          </span>
          <span
            *ngIf="patInfo.rox?.value"
            class="cp-badge cp-inline"
            [ngClass]="{
              'bg-red': patInfo.rox.color === 'red',
              'bg-green': patInfo.rox.color === 'green',
              'bg-yellow': patInfo.rox.color === 'yellow'
            }"
          >
            <strong>ROX: {{ patInfo.rox.value }}</strong>
          </span>
        </div>

        <div class="badges">
          <span
            class="patient_niv cp-badge primary-bg-light"
            *ngIf="patInfo.isNIV?.value"
          >
            <strong>NIV</strong>
          </span>

          <span
            class="patient_intubated cp-badge primary-bg-light"
            *ngIf="patInfo.isIntubated?.value"
          >
            <strong>INTUBATED</strong>
          </span>
          <span
            class="patient_trach cp-badge primary-bg-light mr-1"
            *ngIf="patInfo.isTrach?.value"
          >
            <strong>Trach</strong>
          </span>
        </div>

        <div class="badges">
          <span
            class="patient_niv cp-badge primary-bg-light mr-1"
            *ngIf="patInfo.isHFNC?.value"
          >
            <strong>HFNC</strong>
          </span>
          <div
            class="pressor_container mr-1"
            *ngIf="patInfo.isPressor"
            (mouseover)="showPressorInfo = true"
            (mouseout)="showPressorInfo = false"
          >
            <span class="patient_press cp-badge cp-clr-danger">
              <strong>Pressor</strong>
            </span>
          </div>
        </div>
      </div>
      <ng-container *ngxPermissionsOnly="['read_note']">
        <ng-container *ngIf="summaryType.docNote.display">
          <ng-container
            *ngIf="patNotes && patNotes['mainNote']; else noNotesFound"
          >
            <div class="header">
              <b>{{
                patNotes.mainNote.noteType == "Discharge"
                  ? "Discharge Note"
                  : patNotes.mainNote.noteType == "Progress"
                  ? "Progress Note"
                  : "Admission Note"
              }}</b>
              <span>
                <b
                  >{{
                    patNotes.mainNote.timestamp | timezone : "DD-MM-YYYY H:mm"
                  }}
                </b>
                <cp-timezone-label
                  *ngIf="patTimeZoneDetail$ | async; let patTimeZoneDetail"
                  [timeZoneDetail]="patTimeZoneDetail"
                ></cp-timezone-label>
              </span>
            </div>
            <div class="notes-table">
              <ng-container
                *ngFor="let components of patNotes.mainNote.components"
              >
                <table id="summaryTable" class="summ_section summary_holder">
                  <thead>
                    <tr>
                      <td>
                        <ng-template
                          [ngTemplateOutlet]="editorHeading"
                          [ngTemplateOutletContext]="{
                            displayName: components.displayName
                          }"
                        ></ng-template>
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td class="ql-editor" [innerHtml]="components.value"></td>
                    </tr>
                  </tbody>
                </table>
              </ng-container>
              <table
                *ngIf="patNotes.mainNote?.cosign?.physicianMessage"
                id="addendumTable"
                class="summ_section addendum_medication_holder"
              >
                <thead>
                  <tr>
                    <td>Physician Note</td>
                  </tr>
                </thead>
                <tbody>
                  <tr class="addendum-notes">
                    <td>
                      <div class="name-time">
                        {{ patNotes.mainNote?.cosign?.requestedTo.author }}
                        <span class="time"
                          >{{
                            patNotes.mainNote?.cosign?.attestedOn
                              | timezone : "DD-MM-YYYY HH:mm"
                          }}
                          <cp-timezone-label
                            *ngIf="
                              patTimeZoneDetail$ | async;
                              let patTimeZoneDetail
                            "
                            [timeZoneDetail]="patTimeZoneDetail"
                          ></cp-timezone-label>
                        </span>
                      </div>
                      <div
                        class="ql-editor"
                        [innerHtml]="
                          patNotes.mainNote?.cosign?.physicianMessage
                        "
                      ></div>
                    </td>
                  </tr>
                </tbody>
              </table>
              <table
                *ngIf="patNotes.mainNote?.addendum?.length"
                id="addendumTable"
                class="summ_section addendum_medication_holder"
              >
                <thead>
                  <tr>
                    <td>Addendum Notes</td>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    class="addendum-notes"
                    *ngFor="let addendum of patNotes.mainNote.addendum"
                  >
                    <td>
                      <div class="name-time">
                        {{ addendum.name }}
                        <span class="time"
                          >{{
                            addendum.timestamp | timezone : "DD-MM-YYYY HH:mm"
                          }}
                          <cp-timezone-label
                            *ngIf="
                              patTimeZoneDetail$ | async;
                              let patTimeZoneDetail
                            "
                            [timeZoneDetail]="patTimeZoneDetail"
                          ></cp-timezone-label>
                        </span>
                      </div>
                      <div class="ql-editor" [innerHtml]="addendum.note"></div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </ng-container>
          <ng-template #noNotesFound id="noNewNotesFound">
            <table id="noNotesFoundTable" class="no_new_note">
              <tbody>
                <tr>
                  <td>
                    <div class="no_data_found_block">No new note for today</div>
                  </td>
                </tr>
              </tbody>
            </table>
          </ng-template>
        </ng-container>

        <ng-container *ngIf="summaryType.nurseNote.display">
          <ng-container *ngIf="patNotes?.nurseNote; else noNurseNotesFound">
            <div class="notes-table">
              <table id="nurseTable" class="summ_section">
                <thead>
                  <tr>
                    <th>
                      Nursing Notes: {{ patNotes.nurseNote?.author?.name }}
                    </th>
                    <th class="text-right">
                      {{
                        patNotes.nurseNote.timestamp
                          | timezone : "DD-MM-YYYY HH:mm"
                      }}
                      <cp-timezone-label
                        *ngIf="
                          patTimeZoneDetail$ | async;
                          let patTimeZoneDetail
                        "
                        [timeZoneDetail]="patTimeZoneDetail"
                      ></cp-timezone-label>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <ng-container
                    *ngFor="let components of patNotes?.nurseNote?.components"
                  >
                    <tr>
                      <td colspan="2" [innerHtml]="components.value"></td>
                    </tr>
                  </ng-container>
                </tbody>
              </table>
              <table
                *ngIf="patNotes.nurseNote?.addendum?.length"
                id="addendumTable"
                class="summ_section addendum_medication_holder"
              >
                <thead>
                  <tr>
                    <td>Addendum Notes</td>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    class="addendum-notes"
                    *ngFor="let addendum of patNotes.nurseNote.addendum"
                  >
                    <td>
                      <div class="name-time">
                        {{ addendum.name }}
                        <span class="time"
                          >{{
                            addendum.timestamp | timezone : "DD-MM-YYYY HH:mm"
                          }}
                          <cp-timezone-label
                            *ngIf="
                              patTimeZoneDetail$ | async;
                              let patTimeZoneDetail
                            "
                            [timeZoneDetail]="patTimeZoneDetail"
                          ></cp-timezone-label>
                        </span>
                      </div>
                      <div [innerHtml]="addendum.note"></div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </ng-container>
          <ng-template #noNurseNotesFound>
            <table class="no_new_note">
              <tbody>
                <tr>
                  <td>
                    <div class="no_data_found_block">No new note for today</div>
                  </td>
                </tr>
              </tbody>
            </table>
          </ng-template>
        </ng-container>

        <!-- Check if Doc / Nurse Name display is required -->

        <div
          *ngIf="summaryType.docInfo.display"
          class="summ_section doc_nurse_holder"
          #infoTable
        >
          <table id="infoTable">
            <thead>
              <tr>
                <th>
                  <span><b>Doctor's name</b></span>
                </th>
                <th
                  *ngIf="
                    currUser['role'] == NURSE ||
                    currUser['role'] == CCA ||
                    currUser['role'] == CCN
                  "
                >
                  <span><b>Nurse's name</b></span>
                </th>
                <th><b>Printed on</b></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  {{
                    (patNotes &&
                      patNotes["mainNote"] &&
                      patNotes["mainNote"]["author"]["name"]) ||
                      ""
                  }}<ng-container
                    *ngIf="
                      patNotes?.mainNote?.author &&
                      patNotes?.mainNote?.authorSpeciality
                    "
                    >, {{ patNotes?.mainNote?.authorSpeciality }}
                  </ng-container>
                </td>
                <td
                  *ngIf="
                    currUser['role'] == NURSE ||
                    currUser['role'] == CCA ||
                    currUser['role'] == CCN
                  "
                >
                  <span>{{ currUser["name"] }}</span>
                </td>
                <td>
                  {{ currDate | timezone : "DD-MM-YYYY" }}
                  {{ currDate | timezone : "H:mm" }} hrs
                  <cp-timezone-label
                    *ngIf="patTimeZoneDetail$ | async; let patTimeZoneDetail"
                    [timeZoneDetail]="patTimeZoneDetail"
                  ></cp-timezone-label>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </ng-container>

      <div class="table-holder">
        <div
          *ngIf="summaryType.vitals.display"
          #vitalTable
          class="vitals-section"
        >
          <div class="header"><b>Vitals</b></div>
          <div class="vitals-table">
            <table id="vitalTable">
              <thead>
                <tr class="values-cont">
                  <th class="values"></th>
                  <th class="values" *ngFor="let vital of vitalsData">
                    <div>
                      <b>{{ vital.timestamp | timezone : "DD/MM" }}</b
                      >,
                    </div>
                    <div>
                      <b>{{ vital.timestamp | timezone : "H:mm" }} </b>
                      <cp-timezone-label
                        *ngIf="
                          patTimeZoneDetail$ | async;
                          let patTimeZoneDetail
                        "
                        [timeZoneDetail]="patTimeZoneDetail"
                      ></cp-timezone-label>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  class="values-cont"
                  *ngFor="let vitValue of vitalValues; index as i"
                  [ngClass]="{ 'bg-light': i % 2 === 0 }"
                >
                  <th class="values">
                    <strong>{{ vitValue.displayName }}</strong>
                  </th>
                  <ng-container *ngFor="let data of vitalsData">
                    <td
                      *ngIf="
                        vitValue.name !== 'daysTemperature' &&
                          vitValue.name !== 'daysSpO2';
                        else temperatureCol
                      "
                      class="values"
                    >
                      {{ data[vitValue.name] || "-" }}
                    </td>

                    <ng-template #temperatureCol>
                      <td
                        *ngIf="vitValue.name == 'daysTemperature'"
                        class="values"
                      >
                        {{
                          data["daysTemperature"]
                            ? data["daysTemperature"] +
                              " " +
                              data["daysTemperatureUnit"]
                            : "-"
                        }}
                      </td>
                      <td *ngIf="vitValue.name == 'daysSpO2'" class="values">
                        {{
                          data["daysSpO2"]
                            ? data["daysSpO2"] +
                              "(" +
                              (data["daysFiO2"] || " - ") +
                              ")"
                            : data["daysFiO2"]
                            ? "(" + data["daysFiO2"] + ")"
                            : "-"
                        }}
                      </td>
                    </ng-template>
                  </ng-container>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div *ngIf="summaryType.vents.display" class="vent-section" #ventTable>
          <div class="header"><b>Ventilation</b></div>
          <div class="vent-tables">
            <!-- <table id="ventTable">
              <thead>
                <tr>
                  <th>
                    <b>Ventilation</b>
                  </th>
                  <th *ngFor="let vital of vitalsData">
                    {{ vital.hour | number: "2.0-0" }}:{{
                      vital.min.minuteNumber | number: "2.0-0"
                    }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <ng-container *ngFor="let vent of ventValues">
                  <tr *appNgxVentBlank="{ name: vent, values: vitalsData }">
                    <th>{{ vent.displayName }}</th>
                    <td *ngFor="let vv of vitalsData">
                      {{
                        vv.min.vitals[vent.name]
                          ? vv.min.vitals[vent.name]
                          : "-"
                      }}
                    </td>
                  </tr>
                </ng-container>
                <tr>
                  <th>I:E</th>
                  <td *ngFor="let vv of vitalsData">
                    {{
                      vv.min.vitals.daysVentI ? vv.min.vitals.daysVentI : "-"
                    }}:{{
                      vv.min.vitals.daysVentE ? vv.min.vitals.daysVentE : "-"
                    }}
                  </td>
                </tr>
              </tbody>
            </table> -->
            <table id="ventTable">
              <thead>
                <tr class="values-cont">
                  <th></th>
                  <th class="values" *ngFor="let vital of vitalsData">
                    <b
                      >{{ vital.timestamp | timezone : "DD MMM, H-mm" }}
                      <cp-timezone-label
                        *ngIf="
                          patTimeZoneDetail$ | async;
                          let patTimeZoneDetail
                        "
                        [timeZoneDetail]="patTimeZoneDetail"
                      ></cp-timezone-label>
                    </b>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  class="values-cont"
                  *ngFor="let ventValue of ventValues; index as i"
                  [ngClass]="{ 'bg-light': i % 2 === 0 }"
                >
                  <th class="values">
                    <b>{{ ventValue.displayName }}</b>
                  </th>
                  <td class="values" *ngFor="let data of vitalsData">
                    {{ data[ventValue.name] || "-" }}
                  </td>
                </tr>
                <tr>
                  <th>I:E</th>
                  <td *ngFor="let data of vitalsData">
                    {{ data.daysVentI ? data.daysVentI : "-" }}:{{
                      data.daysVentE ? data.daysVentE : "-"
                    }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div *ngIf="summaryType.meds.display" class="med-section" #medicTables>
          <div class="header"><b>Medications</b></div>

          <div class="medic-tables">
            <table id="medicTables">
              <thead>
                <tr>
                  <th>Name</th>
                  <th *ngIf="printValue != 0 && printValue != 1">Days</th>
                  <th>Dose</th>
                  <th>Frequency</th>
                  <th>Route</th>
                </tr>
              </thead>
              <tbody>
                <ng-container
                  *ngIf="activeMeds && activeMeds.length; else noMeds"
                >
                  <ng-container
                    *ngFor="let med of activeMeds; index as i; even as isEven"
                  >
                    <ng-container *ngIf="!med.toBeDiscarded">
                      <tr
                        class="tr tr__primary"
                        [ngClass]="isEven ? 'tr__even' : 'tr__odd'"
                      >
                        <th>
                          {{ med.name }}
                          <div *ngIf="med.combination">
                            <div *ngFor="let comb of med.combination">
                              {{ comb.name | titlecase }}
                            </div>
                          </div>
                        </th>
                        <td *ngIf="printValue != 0 && printValue != 1">
                          <ng-container
                            *ngIf="
                              med
                                | daysPassed
                                  : 'active'
                                  : false
                                  : patInfo.ICUDischargeDate as daysPassed
                            "
                          >
                            {{ daysPassed.day }} days
                            {{
                              daysPassed.hr && daysPassed.hr > 0
                                ? daysPassed.hr + " hr"
                                : ""
                            }}
                          </ng-container>
                        </td>
                        <td>
                          {{ med.quantity }} {{ med.unit }}
                          <div *ngIf="med.combination">
                            <div *ngFor="let comb of med.combination">
                              {{ comb.quantity }} {{ comb.unit }}
                            </div>
                          </div>
                        </td>
                        <ng-container
                          *ngIf="med?.frequency?.fType; else blankTd"
                        >
                          <ng-container [ngSwitch]="med.frequency.fType">
                            <td *ngSwitchCase="'every'">
                              {{ med.frequency.fType }}
                              {{
                                med.frequency.hours
                                  ? med.frequency.hours + "hr"
                                  : ""
                              }}
                              {{
                                med.frequency.mins
                                  ? med.frequency.mins + "mins"
                                  : ""
                              }}
                            </td>
                            <td *ngSwitchCase="'before'">
                              {{ med.frequency.fType }}
                              {{ med.frequency.timeOfDay }}
                            </td>
                            <td *ngSwitchDefault>
                              {{ med.frequency.fType }}
                            </td>
                          </ng-container>
                        </ng-container>
                        <ng-template #blankTd><td></td></ng-template>
                        <td>{{ med.route }}</td>
                      </tr>
                      <tr
                        class="tr tr__secondary"
                        [ngClass]="isEven ? 'tr__even' : 'tr__odd'"
                        *ngIf="med.instructions"
                      >
                        <td
                          [colSpan]="printValue != 0 && printValue != 1 ? 5 : 4"
                        >
                          <i>
                            Instructions:
                            {{ med.instructions }}
                          </i>
                        </td>
                      </tr>
                      <tr
                        class="tr tr__secondary"
                        [ngClass]="isEven ? 'tr__even' : 'tr__odd'"
                        *ngIf="med.additionalInformation"
                      >
                        <td
                          [colSpan]="printValue != 0 && printValue != 1 ? 5 : 4"
                        >
                          <i>
                            Additional Information:
                            {{ med.additionalInformation }}
                          </i>
                        </td>
                      </tr>
                      <tr
                        class="tr tr__secondary"
                        [ngClass]="isEven ? 'tr__even' : 'tr__odd'"
                        *ngIf="med.sos"
                      >
                        <td
                          [colSpan]="printValue != 0 && printValue != 1 ? 5 : 4"
                        >
                          <i>
                            SOS Reason:
                            {{ med.sosReason }}
                          </i>
                        </td>
                      </tr>
                      <tr
                        class="tr tr__secondary"
                        [ngClass]="isEven ? 'tr__even' : 'tr__odd'"
                      >
                        <td
                          [colSpan]="printValue != 0 && printValue != 1 ? 5 : 4"
                        >
                          {{ med.startTime | timezone : "DD-MM-YYYY, HH:mm" }}
                          hrs
                          <cp-timezone-label
                            *ngIf="
                              patTimeZoneDetail$ | async;
                              let patTimeZoneDetail
                            "
                            [timeZoneDetail]="patTimeZoneDetail"
                          ></cp-timezone-label
                          >,
                          <ng-container
                            *ngIf="
                              med
                                | daysPassed
                                  : 'active'
                                  : true
                                  : patInfo.ICUDischargeDate as noOfDays
                            "
                          >
                            {{
                              noOfDays.day && noOfDays.day > 0
                                ? "Day: " + noOfDays.day
                                : "Scheduled"
                            }}
                          </ng-container>
                        </td>
                      </tr>
                    </ng-container>
                  </ng-container>
                </ng-container>
                <ng-template #noMeds>
                  <tr>
                    <td>-</td>
                    <td *ngIf="printValue != 0 && printValue != 1">-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                  </tr>
                </ng-template>
              </tbody>
            </table>
          </div>
        </div>
        <div *ngIf="summaryType.labs.display" class="lab-section" #labTable>
          <div class="header"><b>Labs/Scans</b></div>
          <div class="labs-scans-tables">
            <table id="labTableRFT" *ngIf="labs_scans['RFT']">
              <thead>
                <tr>
                  <th colspan="2">RFT</th>
                </tr>
              </thead>
              <tbody>
                <ng-container
                  *ngFor="
                    let item of labs_scans['RFT']['attributes']
                      | filterLabDatas : 'RFT'
                      | keyvalue : returnZero
                  "
                  ><tr>
                    <th>{{ item.key | regexFilter }}</th>
                    <td
                      [ngClass]="
                        item.value && errorRangeValues(item.value)
                          ? 'abnormal_val'
                          : ''
                      "
                    >
                      {{ item?.value.value || "-"
                      }}<span
                        class="abnormal_val"
                        *ngIf="item.value && errorRangeValues(item.value)"
                        >**</span
                      >
                    </td>
                  </tr>
                </ng-container>
              </tbody>
            </table>

            <table id="labTableCBC" class="table" *ngIf="labs_scans['CBC']">
              <thead>
                <tr>
                  <th colspan="2">CBC</th>
                </tr>
              </thead>
              <tbody>
                <ng-container
                  *ngFor="
                    let item of labs_scans['CBC']['attributes']
                      | filterLabDatas : 'CBC'
                      | keyvalue : returnZero
                  "
                  ><tr>
                    <th>{{ item.key | regexFilter }}</th>
                    <td
                      [ngClass]="
                        item.value && errorRangeValues(item.value)
                          ? 'abnormal_val'
                          : ''
                      "
                    >
                      {{ item?.value.value || "-"
                      }}<span
                        class="abnormal_val"
                        *ngIf="item.value && errorRangeValues(item.value)"
                        >**</span
                      >
                    </td>
                  </tr>
                </ng-container>
              </tbody>
            </table>

            <table id="labTableABG" class="table" *ngIf="labs_scans['ABG']">
              <thead>
                <tr>
                  <th colspan="2">ABG</th>
                </tr>
              </thead>
              <tbody>
                <ng-container
                  *ngFor="
                    let item of labs_scans['ABG']['attributes']
                      | filterLabDatas : 'ABG'
                      | keyvalue : returnZero
                  "
                  ><tr>
                    <th>{{ item.key | regexFilter }}</th>
                    <td
                      [ngClass]="
                        item.value && errorRangeValues(item.value)
                          ? 'abnormal_val'
                          : ''
                      "
                    >
                      {{ item?.value.value || "-"
                      }}<span
                        class="abnormal_val"
                        *ngIf="item.value && errorRangeValues(item.value)"
                        >**</span
                      >
                    </td>
                  </tr>
                </ng-container>
              </tbody>
            </table>

            <table
              id="labTableElect"
              class="table"
              *ngIf="labs_scans['Electrolytes']"
            >
              <thead>
                <tr>
                  <th colspan="2">Electrolytes</th>
                </tr>
              </thead>
              <tbody>
                <ng-container
                  *ngFor="
                    let item of labs_scans['Electrolytes']['attributes']
                      | filterLabDatas : 'Electrolytes'
                      | keyvalue : returnZero
                  "
                  ><tr>
                    <th>{{ item.key | regexFilter }}</th>
                    <td
                      [ngClass]="
                        item.value && errorRangeValues(item.value)
                          ? 'abnormal_val'
                          : ''
                      "
                    >
                      {{ item?.value.value || "-"
                      }}<span
                        class="abnormal_val"
                        *ngIf="item.value && errorRangeValues(item.value)"
                        >**</span
                      >
                    </td>
                  </tr>
                </ng-container>
              </tbody>
            </table>
          </div>
        </div>
        <ng-container *ngxPermissionsOnly="['get_io']">
          <div *ngIf="summaryType.io.display" class="io-section" #ioTable>
            <div class="header"><b>IO</b></div>

            <div class="io-tables">
              <table id="ioTable">
                <thead>
                  <!-- <tr>
                <th colspan="3">
                  IO last 24hrs ({{
                    ioDateToShow['pastDate'] | date: 'dd-MM-yyyy, H:mm'
                  }}hrs -
                  {{ ioDateToShow['nowDate'] | date: 'dd-MM-yyyy, H:mm' }}hrs)
                </th>
              </tr> -->
                  <tr>
                    <th>Intake</th>
                    <th>Output</th>
                    <th>Net</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{{ netIntake }} ml</td>
                    <td>
                      {{ netOutput == 0 ? netOutput : "-" + netOutput }} ml
                    </td>
                    <td>{{ netIo }} ml</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </ng-container>
        <div
          *ngIf="summaryType.lines.display"
          class="lines-section"
          #linesTables
        >
          <div class="header"><b>Lines, drains and tubes</b></div>

          <div class="lines-tables">
            <table id="linesTables">
              <thead>
                <!-- <tr>
                <th colspan="4">Lines, Drains & Tubes</th>
              </tr> -->
                <tr>
                  <th>Name</th>
                  <th>Days</th>
                  <th>Site</th>
                  <th>Laterality</th>
                </tr>
              </thead>
              <tbody>
                <ng-container
                  *ngIf="linesConnected && linesConnected.length; else noLines"
                >
                  <tr *ngFor="let line of linesConnected">
                    <td>{{ line.pType }}</td>
                    <td>{{ line.numDays }}</td>
                    <td>{{ line.site }}</td>
                    <td>{{ line.laterality }}</td>
                  </tr>
                </ng-container>
                <ng-template #noLines>
                  <tr>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                  </tr>
                </ng-template>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <ng-container *ngxPermissionsOnly="['read_note']">
        <!-- Check if Extra notes display is required -->
        <ng-container *ngIf="summaryType.extraNote.display">
          <ng-container *ngIf="patNotes && patNotes['nutritionNote']">
            <table
              id="dietTable"
              class="summ_section diet_medication_holder mb-2 w-100"
            >
              <thead>
                <tr>
                  <th>Nutrition Notes: {{ patNotes.nutritionNote.author }}</th>
                  <th class="text-right">
                    {{
                      patNotes.nutritionNote.timestamp
                        | timezone : "DD-MM-YYYY H:mm"
                    }}
                    <cp-timezone-label
                      *ngIf="patTimeZoneDetail$ | async; let patTimeZoneDetail"
                      [timeZoneDetail]="patTimeZoneDetail"
                    ></cp-timezone-label>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td
                    colspan="2"
                    [innerHtml]="patNotes.nutritionNote.primaryText"
                  ></td>
                </tr>
              </tbody>
            </table>
          </ng-container>
          <ng-container *ngIf="patNotes && patNotes['pharmNote']">
            <table
              id="pharmTable"
              class="summ_section pharm_medication_holder mb-2 w-100"
            >
              <thead>
                <tr>
                  <th>Pharmacology Notes: {{ patNotes.pharmNote.author }}</th>
                  <th class="text-right">
                    {{
                      patNotes.pharmNote.timestamp
                        | timezone : "DD-MM-YYYY H:mm"
                    }}
                    <cp-timezone-label
                      *ngIf="patTimeZoneDetail$ | async; let patTimeZoneDetail"
                      [timeZoneDetail]="patTimeZoneDetail"
                    ></cp-timezone-label>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td
                    colspan="2"
                    [innerHtml]="patNotes.pharmNote.primaryText"
                  ></td>
                </tr>
              </tbody>
            </table>
          </ng-container>
        </ng-container>
      </ng-container>
    </div>

    <ng-template #summaryModal let-modal>
      <div class="modal-header">
        <h4 class="modal-title">Previous Summaries</h4>
        <div
          matRipple
          class="close cp-btn cp-hvr-info-s"
          aria-label="Close"
          matDialogClose
        >
          <span aria-hidden="true">&times;</span>
        </div>
      </div>
      <div class="modal-body">
        <div class="text-danger" *ngIf="restError">
          <p>Server down! Please try after some time.</p>
        </div>

        <!-- <div title="Get pdf" class="cp-btn cp-hvr-secondary" (click)="openPdf()"><i class="far fa-file-pdf"></i> View</div> -->
        <button
          title="Get pdf"
          class="cp-btn cp-hvr-secondary"
          (click)="openPdf()"
          [disabled]="disableBtn || !pastSummary"
        >
          <fa-icon
            [icon]="['fas', 'spinner']"
            pulse="true"
            *ngIf="disableBtn === 'getpdf'"
          ></fa-icon>
          <fa-icon
            [icon]="['far', 'file-pdf']"
            *ngIf="disableBtn !== 'getpdf'"
          ></fa-icon>
          View
        </button>
      </div>
    </ng-template>
  </div>

  <div class="print-section" *ngxPermissionsOnly="['save_summary']">
    <button
      class="button-primary"
      mat-flat-button
      (click)="printJsPdf()"
      [disabled]="disableBtn"
    >
      <mat-icon class="icon-left"> print </mat-icon>
      <app-loader
        [buttonLoader]="true"
        *ngIf="disableBtn === 'print'"
      ></app-loader>
      Print summary
    </button>
  </div>
</div>

<ng-template #editorHeading let-display="displayName" let-position="idx">
  <div>
    {{ display }}
  </div>
</ng-template>
