import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from "@angular/core";
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";
import { HospitalUnitInfoViewInterface } from "@iris/hospital-unit-expansion-view/model";
import { WorkspaceHospitalInterface } from "@shared-modules/features/shift-assign-dashboard/model";

@Component({
  selector: "cp-hospital-unit-expansion-view",
  templateUrl: "./hospital-unit-expansion-view.component.html",
  styleUrls: ["./hospital-unit-expansion-view.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HospitalUnitExpansionViewComponent implements OnInit {
  @Input() hospitalData: WorkspaceHospitalInterface;

  constructor(
    private _matIconRegistry: MatIconRegistry,
    private _domSantizer: DomSanitizer
  ) {
    this._matIconRegistry.addSvgIcon(
      "cp-hospital-logo-1",
      this._domSantizer.bypassSecurityTrustResourceUrl(
        "assets/icons/hospital_logo_1.svg"
      )
    );
  }

  ngOnInit(): void {}
}
