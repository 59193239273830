import { createAction, props } from "@ngrx/store";
import {
  DashboardCountsInterface,
  DashboardInfoInterface,
  WorkspaceInterface,
} from "../../model";

const GET_DASHBOARD_DATA = "[DASHBOARD DATA] get dashboard data";
const GET_DASHBOARD_DATA_SUCC = "[DASHBOARD DATA] get dashboard data success";
const UPDATE_WORKSPACES = "[DASHBOARD DATA] update workspaces data";
const UPDATE_DASHBOARD_COUNTS = "[DASHBOARD DATA] update dashboard data counts";

export const getDashboardData = createAction(
  GET_DASHBOARD_DATA,
  props<{ isShowLoader?: boolean }>()
);
export const getDashboardDataSucc = createAction(
  GET_DASHBOARD_DATA_SUCC,
  props<{ dashboardInfo: DashboardInfoInterface }>()
);
export const updateWorkspaces = createAction(
  UPDATE_WORKSPACES,
  props<{ workspaces: WorkspaceInterface[] }>()
);
export const updateDashboardCounts = createAction(
  UPDATE_DASHBOARD_COUNTS,
  props<{ countsObj: DashboardCountsInterface }>()
);
